import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/modules/user/user.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import {  ApiStatus, ModalType } from 'src/constants/constant';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';


@Component({
  selector: 'app-newsletter-popup',
  templateUrl: './newsletter-popup.component.html',
  styleUrls: ['./newsletter-popup.component.scss']
})
export class NewsletterPopupComponent implements OnInit {
  showpopup= true
  displayBasic: boolean = false
  settingsFlag:boolean
  OptOutFlag:boolean
  NewUserFlag:boolean
  shownewsletterPopup:boolean = false
  closeCount = 0
  LocalstoredcounValue
  Email
  modalType = ModalType;
  constructor(private userService: UserService, private globalUtility: GlobalUtility,) { }
   


  ngOnInit(): void {
    this.getdatafromlocalstorage()  

  }

  getdatafromlocalstorage () {
    this.getnewsletterconsentflagdata()
    this.LocalstoredcounValue = JSON.parse(localStorage.getItem(LocalStorageDataModel.CLOSECOUNT))
    this.closeCount  = this.LocalstoredcounValue.closeCount
    this.Email = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)
    if(this.closeCount >= 3){
      this.NewsletterOptOut()
      this.displayBasic = false
    }
    let getlocaldata = JSON.parse(this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.NEWSLETTER_CONSENT_FLAG))
    let token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
    if(getlocaldata && token){
     if(!getlocaldata.settingsFlag && !getlocaldata.OptOutFlag && !getlocaldata.NewUserFlag){
      this.shownewsletterPopup = true
     }
    }

   
   }

   getnewsletterconsentflagdata () {
    let displayBasicvalue = JSON.parse(localStorage.getItem(LocalStorageDataModel.DISPLAY_CONSENT_FORM))   
    this.userService.getNewsLetterConsentFlag().subscribe((response) => {
      if(response.ResponseType == ApiStatus.SUCCESS){
        if(!response.Data.settingsFlag && !response.Data.OptOutFlag && !response.Data.NewUserFlag){
          this.displayBasic = displayBasicvalue? displayBasicvalue.displayValue : true 
         this.shownewsletterPopup = true
        }        
        let myobject = {
          settingsFlag:response.Data.settingsFlag,
          OptOutFlag:response.Data.OptOutFlag,
          NewUserFlag:response.Data.NewUserFlag
        } 
        this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.NEWSLETTER_CONSENT_FLAG , JSON.stringify(myobject))
      }
    })
   }
   putnewsletterdefaultsetting () {
    let token = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
    if(token){
      let data = {
        TaxmannAuthorization:token
      }
      this.userService.NewsletterDefaultsetting(data).subscribe((response) => {
          if(response.ResponseType == ApiStatus.SUCCESS){
            this.getnewsletterconsentflagdata()
            window.open('/gp/user/my-newsletter')
            this.displayBasic = false
          }
      } )
    }
   }
   NewsletterOptOut() {
    this.userService.postNewsletterOptout().subscribe((response) => {
      if(response.ResponseType == ApiStatus.SUCCESS){
        this.getnewsletterconsentflagdata()
        this.displayBasic = false
        if (this.closeCount >=3){
          let countobject = {
            closeCount: 0 ,
            Email: this.Email
          }
          this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CLOSECOUNT , JSON.stringify(countobject))
        }
      }
    })
	}

  getNewsletterlist() {
    this.userService.getNewsletterList().subscribe((resp)=>{
      if(resp.ResponseType == "SUCCESS"){
        this.getnewsletterconsentflagdata()
      }
    })
  }

  closePopupHeader() {
    this.displayBasic = false
    let email = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)
    if( this.LocalstoredcounValue === null){
      this.closeCount = 1
    }else if(this.LocalstoredcounValue.Email ===  email){
      this.closeCount++
    }
    else{
      this.closeCount = 1
    } 
    if(this.closeCount >= 3){
      this.NewsletterOptOut()
    }
    let countobject = {
      closeCount:this.closeCount,
      Email: email
    }
    this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CLOSECOUNT , JSON.stringify(countobject))
    let displayobject = {
      displayValue:false
    } 
    this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.DISPLAY_CONSENT_FORM , JSON.stringify(displayobject))
	}

}
