import { Component, HostListener, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { HomeService } from 'src/app/service/bookstore-service/home.service';
import { TaxmannHelperService } from 'src/app/service/taxmann-service/taxmann-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import {
	CheckoutFrom,
	PublishEvents,
	ResearchCategoryUrl,
	ModalType,
	constants,
	ExternalWebsiteURL,
	RegisterPlatformType,
	ApiStatus
} from 'src/constants/constant';
import { SessionService } from 'src/app/service/session.service';
import { CommonAlertService } from 'src/app/service/common-alert.service';
import { HeaderMenuList } from './classes/header-menu.class';
import { HeightAdjustElementType, TaxmannProducts } from 'src/enums/enum';
import { JoyrideService } from 'ngx-joyride';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { Subscription } from 'rxjs';
import * as moment from "moment"; 
import * as _ from 'lodash';
import { NETCORE_EVENT_LIST } from 'src/constants/netcore.eventList';

@Component({
	selector: 'app-header-new',
	templateUrl: './header-new.component.html',
	styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit, OnDestroy {

	_hideSidebarToggle: boolean = false;
	@Input() isHomePage: boolean;
	@Input() isBookStoreLayout: boolean;
	@Input() isBudgetLayout: boolean;
	@Input() set hideSidebarToggle(val: boolean) {
		this._hideSidebarToggle = val;
	};
	@Input() showFilterIcon: boolean;
	@Input() showfilterArea: boolean;
	showFilterAreaTemp: boolean
	@Input() productName: number;
	@Input() showmultiMenu: boolean;
	@Input() hideGlobalSearch: boolean;
	@Input() step;
	isExternal = false;
	cartCounter: number = 0;
	isLoggedIn: boolean = false;
	email: string = '';
	isFilterClick: boolean;
	mobNavbarClass: string = 'sidebar-hidden';
	alive = true;
	showDiv = true;
	isMobile: boolean;
	researchCategoryUrl = ResearchCategoryUrl;
	menuList: any[];
	currentOpenParent: string[] = [];
	taxmannProducts = TaxmannProducts;
	modalType = ModalType;
	showPopupHeader: boolean = false;
	FeedbackFormOldSite: FormGroup;
	@Input() isResearchHomePage: boolean;
	showBanner: boolean = false;
	@Output() isShowBanner = new EventEmitter<boolean>(true);
	showBrowserAlert: boolean = false;
	isNoThanks: boolean = false;
	isBrowserAlertActive: boolean = false;
	isMobileDevice: boolean = false;
	OneSignal: any;
	@Input() isvirtualBook: boolean;
	@Input() isBookstore: boolean;
	@Input() isResearch: boolean;
	@Input() isComplaince: boolean;
	@Input() isTaxPractice: boolean = false;
	@Input() isGenericPage: boolean = false;
	@Input() showPricingDropdown?: boolean = true;
	@Input() onlyTaxmannLogo?: boolean = false;
	@Input() isWebinar: boolean = false
	@Input() isPodcast: boolean = false;
	@Input() researchPricing: boolean = false;
	@Input() practicePricing: boolean = false;
	@Input() showFinanceActLogo : boolean = true;
	isVBookReader: boolean = false;
	showNewsletterConsentForm
	userName;
	imageUrl = this.globalUtility.makeWebpImageUrl();
	public isPracticeModuleSubscribeUser = false;
	public inPracticeModuleTotalGlobalTopicsFollowedByUser = 0;

	private _practiceGlobalTopicCountFollowedByUser$: Subscription;


	externalWebsiteURL = ExternalWebsiteURL;
	registerPlatformType = RegisterPlatformType;
	taxmannLogoClick: string;
	showHomePageMenu: boolean = this.globalUtility.isMobileView() ? false : true;
	showResearchRenewalNotificationPopup: boolean = false;
	isShowResearchRenewalStip: boolean = false;
	researchRenewalData:any = [];
     researchRenewalNotificationPopupData = [
		{days:1},
		{days:7},
		{days:15},
	];
	calculatedResearchRenewalNotificationPopupData = [];
	noOfDaysForExpiryLeft:number;
	@Output() researchRenewalStipEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip:any = [];
	selectedExpiredResearchRenewalNotificationPopupDataForStrip:any = [];
	selectedVendorNameForResearchRenewalNotificationPopUpDataForStrip: any;
	netCorePayload = {};
	utmObj = {};
	studentCartCounter: number = 0;
	constructor(
		private _taxmannHelperService: TaxmannHelperService,
		private _homeService: HomeService,
		public globalUtility: GlobalUtility,
		private router: Router,
		private sessionService: SessionService,
		private commonAlertService: CommonAlertService,
		private joyrideService: JoyrideService,
		private fb: FormBuilder,
		private deviceService: DeviceDetectorService
	) {
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.checkForlayout();
	}

	ngOnInit() {
		this.checkforVBookReader();
		this.checkForlayout();
		this.isLoggedIn = this.globalUtility.isLoggedIn();
		if(this.isLoggedIn){
			this.callRenewalAlert();
		}
		this.email = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL);
		let name = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME).split(' ');
		this.userName = name[0];
		this.getCartCounter();
		this.getStudentCartCounter();
		this.showFilterAreaTemp = this.showfilterArea;
		this.initEvents();
		this.initMenu();
		this.initForm();
		this.initOnesignal();

		this._getPracticeGlobalTopicCountFollowedByUser();
		this.initTaxmannLogoRoute();
		this.showNewsletterConsentForm = (window.location.pathname === '/research' && this.isLoggedIn)
		console.log( 'currentrouter' ,this.showNewsletterConsentForm)
	}

	private _getPracticeGlobalTopicCountFollowedByUser() {
		if (!this.router.url.includes("/practice")) {
			return;
		}
		this._practiceGlobalTopicCountFollowedByUser$ = this._homeService.getInPracticeGlobalTopicCountFollowedByUser()
			.subscribe((globalTopicCountFollowedByUserAPIResponse) => {
				const { isSubscribe, totalGlobalTopics } = globalTopicCountFollowedByUserAPIResponse.Data;
				this.isPracticeModuleSubscribeUser = isSubscribe;
				this.inPracticeModuleTotalGlobalTopicsFollowedByUser = totalGlobalTopics
			})
	}

	initMenu() {
		let list = HeaderMenuList.list;
		this.menuList = JSON.parse(JSON.stringify(list))
	}

	ngAfterViewInit() {
		this.resetFilterArea();
	}

	resetFilterArea() {
		if (this.router.url.includes('/cart') ||
			this.router.url.includes('store/checkout') ||
			this.router.url.includes('store/payment') ||
			this.router.url.includes('/trackorder')) {
			this.showFilterAreaTemp = false;
		} else {
			this.showFilterAreaTemp = true
		}
		this.globalUtility.bodyScrollOff.next(false);
	}

	/**
	 * init events
	 */
	initEvents() {

		this.router.events.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			if (res instanceof NavigationEnd) {
				this.resetFilterArea();
			}
		});


		this.globalUtility.checkForLogin.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			console.log("check for login", res)
			if (res) {
				this.isLoggedIn = this.globalUtility.isLoggedIn();
				this.email = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL);
				let name = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME).split(' ');
				this.userName = name[0];
				this.globalUtility.checkForLogin.next(null);
				if (location.pathname === '/' || location.pathname === '/research/home') {
					this.router.navigate(['/research']);
				}
			}
		});

		this.globalUtility.logout.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			this.logout();
		});

		this._taxmannHelperService.showMobileFilterIcon.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			this.showFilterIcon = res;
		});

		this._taxmannHelperService.mobileFilterCloseBtn.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			this.togglefilter();
		})

		this._taxmannHelperService.toggleSidebarLayoutOrAddToCartEvent.pipe(takeWhile(_ => this.alive)).subscribe((response) => {
			if (response == PublishEvents.CART) {
			} else if (response == PublishEvents.ADD_TO_CART || response == PublishEvents.UPADTE_CART) {
				this.getCartCounter();
			} else if (response == 'false') {
				this.globalUtility.bodyScrollOff.next(false);
			}

			else {

			}
		});

		this.globalUtility.updateUsername.pipe(takeWhile(_ => this.alive)).subscribe((res) => {
			if (res) {
				let name = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME).split(' ');
				this.userName = name[0];
			}
		});

	}

	getCartCounter() {

		if (this.isLoggedIn) {
			const data = {
				cartSource: CheckoutFrom.CART,
			}
			//this._homeService.getCartDetails(data).subscribe((response) => {
				this._homeService.getCartCount(data).subscribe((response) => {
				this.cartCounter = 0;
				this.cartCounter = response?.data?.totalCount;
				// if (response.Status != 'DATA_NOT_FOUND' && response.Data && response.Data[0].cartList.length > 0) {
				// 	for (let i = 0; i < response.Data[0].cartList.length; i++) {
				// 		this.cartCounter = this.cartCounter + response.Data[0].cartList[i].qty;
				// 	}
				// }
			});
		}
	}



	toggleLeftsidenav() {
		let action = '';
		if (this.mobNavbarClass != '') {
			this.mobNavbarClass = '';
			action = PublishEvents.MOBILE_TOGGLE_ON;
			this.globalUtility.bodyScrollOff.next(true)
		} else {
			this.mobNavbarClass = 'sidebar-hidden';
			action = PublishEvents.MOBILE_TOGGLE_OFF;
			this.globalUtility.bodyScrollOff.next(false)
		}
		this._taxmannHelperService.publishEvent(action);
	}


	/**
	 * toggle filter area
	*/
	togglefilter() {
		let action = 'filterToggle';
		this.isFilterClick = !this.isFilterClick;
		this.globalUtility.bodyScrollOff.next(this.isFilterClick)
		this._taxmannHelperService.publishEvent(action, this.isFilterClick);
	}

	@HostListener("click", ["$event"])
	onClick(event: any): void {
		if (this.isMobile) {
			event.stopPropagation();
		}
	}


	logout(redirect?: boolean) {
		this.sessionService.logout().subscribe((res) => {
			this.globalUtility.resetSession();
			this.isLoggedIn = false;
			if (redirect) {
				this.commonAlertService.infoToast('Logged Out Successfully');
				//   this.router.navigate(['/bookstore']);
				this.globalUtility.redirectToReactLogout();
				// this.router.navigate(['/auth/logout']);
			}

		}, error => {
			this.commonAlertService.infoToast('Logged Out Successfully');
			this.globalUtility.resetSession();
			this.isLoggedIn = false;
			this.globalUtility.redirectToReactLogout();
			// this.router.navigate(['/auth/logout']);
		})

	}

	/**
   * toggle right sidebar
   */
	@gaEventTrack('Header_Research', '', 'Click', -1, 0)
	toggle(labelIndex: any, isScrollOff: boolean = true) {
		if (this.globalUtility.isMobileView()) {
			this.showDiv = !this.showDiv;
			if (isScrollOff) {
				this.globalUtility.bodyScrollOff.next(this.showDiv);
			}
		}
	}
	// @gaEventTrack('Header_', 'Profile', 'Click',0)
	// dropProfile(categoryIndex){
	// 	return null;
	// }

	toggleHomePageMenu(): void {
		this.showHomePageMenu = !this.showHomePageMenu;
	}

	checkForlayout() {
		this.isMobile = this.globalUtility.isMobileView();
		if (this.isMobile) {
			this.showDiv = false;
		} else {
			this.showDiv = true;
		}
	}
	checkforVBookReader() {
		if (location.pathname.toLowerCase().indexOf('/virtualbooks/reader/') >= 0) {
			this.isVBookReader = true;
		}
		if (location.pathname.toLowerCase().indexOf('/virtualbooks/virtualreader/') >= 0) {
			this.isVBookReader = true;
		}
	}

	ngOnDestroy() {
		this.alive = false;
		this._practiceGlobalTopicCountFollowedByUser$ && this._practiceGlobalTopicCountFollowedByUser$.unsubscribe();
	}

	toggleChild(menu?: any) {
		if (menu) {
			if (this.currentOpenParent.includes(menu.id)) {
				let index = this.currentOpenParent.findIndex(res => res === menu.id)
				this.currentOpenParent.splice(index, 1)
				// this.currentOpenParent = [];
			} else {
				if (menu.parentId && this.currentOpenParent.includes(menu.parentId)) {
					this.currentOpenParent.push(menu.id);
				} else {
					if (this.isMobile) {
						this.currentOpenParent.push(menu.id);
					} else {
						this.currentOpenParent = [menu.id];
					}
				}

			}
		} else {
			this.currentOpenParent = [];
		}
	}

	setPrevUrl(type: string) {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url);
		let referrer = this.router.url;
		if (referrer.indexOf(this.externalWebsiteURL.BOOKSTORE) !== -1) {
			referrer = this.registerPlatformType.BOOKSTORE;
		} else if (referrer.indexOf(this.externalWebsiteURL.RESEARCH) !== -1) {
			referrer = this.registerPlatformType.RESEARCH;
		} else if (referrer.indexOf(this.externalWebsiteURL.COMPLIANCE) !== -1) {
			referrer = this.registerPlatformType.COMPLIANCE;
		} else if (referrer.indexOf(this.externalWebsiteURL.VIRTUAL_BOOKS) !== -1) {
			referrer = this.registerPlatformType.VIRTUAL_BOOKS;
		} else if (referrer.indexOf(this.externalWebsiteURL.PRACTICE) !== -1) {
			referrer = this.registerPlatformType.PRACTICE;
		}
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.INTERNAL_REFRERRER_URL, referrer);
		switch (type) {
			case 'login':
				this.globalUtility.redirectToReactLogin();
			case 'signup':
				this.globalUtility.redirectToReactRegister();
		}

		this._homeService.pushUtmSourceInformation(`${type.charAt(0).toUpperCase() + type.slice(1)} action from header.`);//logging utm info
	}
	onDone() {
		console.log("all steps finished");
		localStorage.setItem(LocalStorageDataModel.FIRST_TIME_VISIT, 'false');
		// this.archiveSharedService.startCaseLawTour = true;
		this.joyrideService.closeTour();
		// this.router.navigate(['/research/direct-tax-laws/caselaws']);
	}
	closePopupHeader() {
		this.showPopupHeader = false;
		this.showBrowserAlert = false;
	}

	showthePopupHeader() {
		let showFeedbackForm = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.FEEDBACK_OLD_SITE);
		if (showFeedbackForm && showFeedbackForm === 'true') {
			// this.commonAlertService.successToast('User already registered once');
			window.open('https://bit.ly/38T4XG4', '_blank');
		} else {
			this.globalUtility.showLoader = true;
			this._homeService.getUserBasicDetails().subscribe((res) => {
				this.globalUtility.showLoader = false;
				this.showPopupHeader = true;
				if (res && res.ResponseType === ApiStatus.SUCCESS) {
					if (res.data) {
						this.FeedbackFormOldSite.patchValue({
							username: res.data.name,
							email: res.data.email,
							mobile: res.data.phone,
						});
					}
				}
			}, (error) => {
				this.globalUtility.showLoader = false;
			}
			);
		}
	}

	initForm() {
		this.FeedbackFormOldSite = this.fb.group({
			username: ['', [Validators.pattern(constants.onlyAlphabets)]],
			email: ['', [Validators.pattern(constants.emailPattern)]],
			mobile: [''],
			feedback: ['', []]
		})
	}

	submitForm() {
		if (this.FeedbackFormOldSite.valid) {
			this.globalUtility.showLoader = true;
			this._homeService.fillfeedbackforOLDsite(this.FeedbackFormOldSite.value).subscribe((res) => {
				this.globalUtility.showLoader = false;
				this.showPopupHeader = false;
				if (res && res.Status === "DATA_SAVED") {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.FEEDBACK_OLD_SITE, 'true')
					this.commonAlertService.successToast('Data Saved Successfully');
					window.open('https://bit.ly/38T4XG4', '_blank');
				} else if (res && res.Status === 'USER_ALREADY_REGISTERED') {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.FEEDBACK_OLD_SITE, 'true')
					//  this.commonAlertService.errorToast(res.StatusMsg);
					window.open('https://bit.ly/38T4XG4', '_blank');
				} else {
					this.commonAlertService.errorToast('Data Not Saved');
				}
			}, (error) => {
				this.globalUtility.showLoader = false;
				this.showPopupHeader = false;
			})
		} else {
			this.globalUtility.validateAllFormFields(this.FeedbackFormOldSite);
		}
	}

	hideBanner() {
		this.showBanner = false;
		this.isShowBanner.emit(false);
	}
	initOnesignal() {
		let uri = ["/research/box", "/dropbox-sync"]
		if (this.deviceService.isMobile()) this.isMobileDevice = true;
		if (this.productName === this.taxmannProducts.RESEARCH && !this.deviceService.isMobile() && !this.isvirtualBook && uri.indexOf(location.pathname.toLowerCase()) < 0) {
			this.OneSignal = window['OneSignal'] || [];

			this.OneSignal.push(["init", {

				/*Live keys starts */
				appId: "1b88ceac-a690-4886-8671-617632ab5870",
				safari_web_id: "web.onesignal.auto.2fc72fe0-a0df-475b-ad9a-b2dac840a493",
				/*Live keys Ends */

				/*Local keys starts */
				// appId: "b7a2bc27-ab46-44d3-a38c-2ed815e4d8dc",
				// safari_web_id:"web.onesignal.auto.511f3fe8-4f38-4cfd-9441-4579acc1dc24",
				// allowLocalhostAsSecureOrigin: true,
				/*Local keys ends */

				autoRegister: false,
				welcomeNotification: {
					disable: false,
				},
				persistNotification: false,
				notifyButton: {
					enable: false,
					prenotify: true,
					position: 'bottom-right',
					size: 'medium',
					showCredit: false
				}
			}]);
			this.showNotificationAlert(true);

		}
	}

	@gaEventTrack('Header_Research', 'Notification', 'Click')
	showNotificationAlert(isFrominit?: boolean) {
		if (this.deviceService.isMobile()) {
			return;
		}
		if (!this.OneSignal.isPushNotificationsSupported()) {
			return;
		}
		this.OneSignal.push(() => {
			/*Check If Subscribed */
			this.OneSignal.isPushNotificationsEnabled((isEnabled) => {
				if (isEnabled) {
					if (isFrominit) {
						this.showBrowserAlert = false;
					}
					this.isBrowserAlertActive = true;
				} else {
					this.isNoThanks = false;
					this.isBrowserAlertActive = false;
					if (!this.checkNotificationCookie() && location.pathname.toLowerCase() === '/research') {
						this.showBrowserAlert = true;
					}
				}
			}, (error) => {
				console.log('Push permission not granted');
			});
			if (!isFrominit) {
				this.showBrowserAlert = true;
			}
		});
	}
	@gaEventTrack('Research-Home_Notification', 'No-Thanks', 'Click')
	noThanksNotification() {
		this.isNoThanks = true;
		this.showBrowserAlert = false;
		let cookieValue = "yes";
		this.globalUtility.setCookie(LocalStorageDataModel.NOTIFICATION_ALERT, cookieValue, 3600)
	}
	manageNotificationClose() {
		this.isNoThanks = false;
		this.showBrowserAlert = false;
	}
	checkNotificationCookie(): boolean {
		let cookieValue = this.globalUtility.getCookie(LocalStorageDataModel.NOTIFICATION_ALERT);
		if (cookieValue !== "") return true;
		else return false;
	}
	@gaEventTrack('Research-Home_Notification', 'Allow', 'Click')
	manageNotificationAlert(isSubscribed?: boolean) {
		if (this.deviceService.isMobile()) {
			return;
		}
		if (!isSubscribed) {
			let oneSignalId;
			this.OneSignal.push(() => {
				this.OneSignal.push(["registerForPushNotifications"]);
			});
			this.OneSignal.getUserId().then((userId) => {
				oneSignalId = userId;
				if (oneSignalId) {
					this.OneSignal.push(() => {
						this.OneSignal.setSubscription(true);
					});
				}

			});
			this.isBrowserAlertActive = true;
		}
		else {
			this.OneSignal.push(() => {
				this.OneSignal.setSubscription(false);
			});
			this.isBrowserAlertActive = false;
		}
		this.showBrowserAlert = false;
	}
	@gaEventTrack('Header_', 'Cart', 'Click', 0, -1)
	eventCart(categoryIndex) {
		return null;
	}
	@gaEventTrack('Header_', 'Dropdown_', 'Click', 0, 1)
	eventDropdown(categoryIndex, labelIndex) {
		if (labelIndex === 'Bookstore') {
			window.open('/bookstore' , '_self')
		}
		return null;
	}

	get pageType() {
		let pathName = window.location.pathname;
		let pathArr = pathName.split("/")
		if (pathArr[1] == '/') { return 'Umberella-Page' }
		else if (pathArr[1] == 'research' || pathArr[1] == 'research-pricing') {
			return 'Research'
		} else if (pathArr[1] == 'virtualbooks') {
			return 'Virtual Books & Journals'
		} else if (pathArr[1] == 'compliance-etds') {
			return 'Compliance'
		} else if (pathArr[1] == 'bookstore') {
			return 'Bookstore'
		} else if (pathArr[1] == 'practice-pricing') {
			return 'Practice'
		}

	}
	@gaEventTrack('Pricing-', 'Pricing-', 'Click', 0, 1)
	eventPricingDropdown(CategoryIndex, labelIndex) {

	}

	@gaEventTrack('Research-Home_Walkthrough', 'Next', 'Click')
	joyrideNextClick() {

	}
	@gaEventTrack('Research-Home_Walkthrough', 'Back', 'Click')
	joyrideprevClick() {

	}

	@gaEventTrack('Header_', 'Logo', 'Click', 0, -1)
	onLogoClick(categoryIndex) {
		if (this.globalUtility.isMobileView()) {
			if (!this.mobNavbarClass) {
				this.toggleLeftsidenav();
			}
		}
	}

	signIn() {
		let urlPath = "/research";
		if (location.pathname.includes('/practice-pricing') || location.pathname.includes('/practice/') || location.pathname == '/practice') {
			this._homeService.getInPracticeGlobalTopicCountFollowedByUser().subscribe((result: any) => {
				let { isSubscribe, totalGlobalTopics } = result.Data;
				if (isSubscribe && totalGlobalTopics >= 5) {
					urlPath = '/practice/my-feed';
				} else {
					urlPath = '/practice';
				}
				this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, urlPath);
			});
		} else if (location.pathname.includes('/bookstore') || location.pathname.includes('/bookstore/')) {
			urlPath = '/bookstore';
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, urlPath);
		} else if (location.pathname.includes('/virtualbooks') || location.pathname.includes('/virtualbooks/')) {
			urlPath = '/virtualbooks/library';
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, urlPath);
		} else if (location.pathname.includes('/compliance-etds') || location.pathname.includes('/compliance-etds/')) {
			urlPath = '/compliance-etds';
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, urlPath);
		}
		this._homeService.pushUtmSourceInformation("Sign In Action from header.");
		this.globalUtility.redirectToReactLogin()
	}

	get taxpracticeStrip() {
		return !!((+this.globalUtility.getDataFromLocalStorageWithExpiry(LocalStorageDataModel.PRACTICE_STRIP_VIEW, true))) && !window.location.pathname.includes('practice') && !window.location.pathname.includes('research') && !window.location.pathname.includes('budget') && !window.location.pathname.includes('compliance-etds');
	}

	setLastAccessBaseURLByUser(url) {
		this.globalUtility.setLastAccessProductBaseUrlByUser(url);
		if (url.includes('advisory')) {
			setTimeout(() => {
				window.location.reload();
			}, 10);

		}
		if (url.includes('academy')) {
			setTimeout(() => {
				window.location.reload();
			}, 10);

		}
	}

	get isPracticePage() {
		let path = window.location.pathname;
		if (path.includes('/practice')) {
			return true;
		}
		return false;
	}

	get filterMenuList() {
		return this.menuList.filter((data) => {
			if (data.url != '/practice' || !this.isPracticePage) {
				return data;
			}
		});
	}

	initTaxmannLogoRoute() {
		if (location.pathname.includes('/practice-pricing') || location.pathname.includes('/practice/') || location.pathname == '/practice') {
			this._homeService.getInPracticeGlobalTopicCountFollowedByUser().subscribe((result: any) => {
				let { isSubscribe, totalGlobalTopics } = result.Data;
				if (isSubscribe && totalGlobalTopics >= 5) {
					this.taxmannLogoClick = '/practice/my-feed';
				} else {
					this.taxmannLogoClick = '/practice';
				}
				if (this.globalUtility.isMobileView()) {
					if (!this.mobNavbarClass) {
						this.toggleLeftsidenav();
					}
				}
			})
		} else if (location.pathname.includes('/bookstore') || location.pathname.includes('/bookstore/')) {
			this.taxmannLogoClick = '/bookstore';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		} else if (location.pathname.includes('/virtualbooks') || location.pathname.includes('/virtualbooks/')) {
			this.taxmannLogoClick = '/virtualbooks/library';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		} else if (location.pathname.includes('/compliance-etds') || location.pathname.includes('/compliance-etds/')) {
			this.taxmannLogoClick = '/compliance-etds';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		} else if (location.pathname.includes('/webinars') || location.pathname.includes('/webinars/')) {
			this.taxmannLogoClick = '/webinars';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		} else if (location.pathname.includes('/adit')) {
			this.isExternal = true;
			this.taxmannLogoClick = '/bookstore';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		} 
		else {
			this.taxmannLogoClick = '/research';
			if (this.globalUtility.isMobileView()) {
				if (!this.mobNavbarClass) {
					this.toggleLeftsidenav();
				}
			}
		}
	}

	navigateToReactUserModuleByURL(url: string) {
		window.open('/gp'+url, '_self');
	}

	// get moduleType(){
	// 	let url = window.location.pathname.split('/');
	// 	if(url[1].includes('research')){
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	onClickCloseResearchRenewalStrip(){
		this.isShowResearchRenewalStip = false;
		if(this.noOfDaysForExpiryLeft > 1 ){
		this.showResearchRenewalNotificationPopup = true;
		this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
		this.adjustHeight();
		}else{
			this.onClickOkayResearchRenewalNotificationPopup(0);
		}
	}

   researchRenewalNotificationPopupReminderCalculation(){
    if(this.researchRenewalData && this.researchRenewalData.renewProductDetails && this.researchRenewalData.renewProductDetails.length > 0){
		this.getResearchRenewalNotificationStripData();
		if(this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip && this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip.length > 0){
					// always take  expiring to be data close the current Date for Strip, out data is already stored in ascending order
					let researchRenewalPlanToConsider = this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip[0];
			console.log("researchRenewalPlanToConsider",researchRenewalPlanToConsider)
			if(researchRenewalPlanToConsider && researchRenewalPlanToConsider.ProdId){
		this.noOfDaysForExpiryLeft = researchRenewalPlanToConsider['daysLeft'];
				//	this.noOfDaysForExpiryLeft = 12;
			if(this.noOfDaysForExpiryLeft > 1 ){
				let researchRenewalNotificationExtractedNumber = 0;
					 if(this.noOfDaysForExpiryLeft > 15){
						researchRenewalNotificationExtractedNumber = 3;
						this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
					 }else if(this.noOfDaysForExpiryLeft > 7){
						researchRenewalNotificationExtractedNumber = 2;
						this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
					 }else if(this.noOfDaysForExpiryLeft > 1){
						researchRenewalNotificationExtractedNumber = 1;
						this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
					 }else{
						researchRenewalNotificationExtractedNumber = 1;
						this.calculatedResearchRenewalNotificationPopupData = this.researchRenewalNotificationPopupData.slice(0,researchRenewalNotificationExtractedNumber)
					 }
					 this.calculatedResearchRenewalNotificationPopupData.push({days:this.noOfDaysForExpiryLeft});
			}else{
	
			}
			}	
		}
	}
   }

   getResearchRenewalNotificationStripData(){
	if(this.researchRenewalData.renewProductDetails && this.researchRenewalData.renewProductDetails.length){
		if(this.researchRenewalData.renewProductDetails.length > 1){
		// if expiring to be plan found, only single plan is considered, if only there are expired products then all are to be considered
	     let  currentDate:any  = moment(new Date().setHours(0, 0, 0, 0));

		let expiringToBeResearchRenewalData:any = [];
		let expiedResearchRenewalData:any = [];
		this.researchRenewalData.renewProductDetails.forEach((element:any) => {
			let expiryDate = moment(element.ExpiryDate).startOf('day');
			console.log("current data", currentDate, "expiry date", expiryDate)
			element['daysLeft'] = this.getDateDiff(currentDate, expiryDate);
			if(element['daysLeft'] >=0){
				expiringToBeResearchRenewalData.push(element);
			}else{
				expiedResearchRenewalData.push(element);
			}
		});
		let Vendor:any = [];
		// sort expiring to be Data based on diff from current date
		if(expiringToBeResearchRenewalData && expiringToBeResearchRenewalData.length > 0){
		expiringToBeResearchRenewalData.sort((firstItem, secondItem) => firstItem.daysLeft - secondItem.daysLeft);
		let firstElement = expiringToBeResearchRenewalData[0]; 
		let expiringToBeResearchRenewalDataSelected = [];
		// check if more producst expiring on same day
		if(expiringToBeResearchRenewalData && expiringToBeResearchRenewalData.length > 1){
		  expiringToBeResearchRenewalDataSelected= expiringToBeResearchRenewalData.filter(x => x['daysLeft'] == firstElement['daysLeft'] );
		}else{
			expiringToBeResearchRenewalDataSelected.push(expiringToBeResearchRenewalData[0]);
		}
		console.log("expiringToBeResearchRenewalDataSelected",expiringToBeResearchRenewalDataSelected)
		this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip = expiringToBeResearchRenewalDataSelected;
		this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip.forEach((element:any) => {
			if( element['VendorName'] ){
				Vendor.push( element );
			}
		});
		}
		// from all expired data, get only those data within 365 days , if data not found, then get latest expired data only
		let expiredDataFoundWithinYear:any = [];
		let expiredDataFoundAfterAYear:any = [];
		if(expiedResearchRenewalData && expiedResearchRenewalData.length > 0){
			expiedResearchRenewalData.forEach((element:any) => {
				if(element['daysLeft'] > -365 && element['daysLeft'] < 0){
					expiredDataFoundWithinYear.push(element);
				}else if(element['daysLeft'] < -365 ){
					if(expiringToBeResearchRenewalData && expiringToBeResearchRenewalData.length > 0){

					}else{
					expiredDataFoundAfterAYear.push(element);
					}
				}
			});
		}
		if(expiredDataFoundWithinYear && expiredDataFoundWithinYear.length > 0){
			this.selectedExpiredResearchRenewalNotificationPopupDataForStrip = expiredDataFoundWithinYear;
			if(Vendor && Vendor.length > 0){}else{
				this.selectedExpiredResearchRenewalNotificationPopupDataForStrip.forEach((element:any) => {
					if( element['VendorName'] ){
						Vendor.push( element );
					}
				});		
			}
		}else if(expiredDataFoundAfterAYear && expiredDataFoundAfterAYear.length > 0){
			// get data of same day
			if(expiredDataFoundAfterAYear && expiredDataFoundAfterAYear.length > 1){
				let firstElement = expiredDataFoundAfterAYear[0];
				let dataSelected= expiredDataFoundAfterAYear.filter(x => x['daysLeft'] == firstElement['daysLeft'] );
				this.selectedExpiredResearchRenewalNotificationPopupDataForStrip = dataSelected;  
			}else{
				this.selectedExpiredResearchRenewalNotificationPopupDataForStrip.push(expiredDataFoundAfterAYear[0]);
			  }
	  
			if( Vendor && Vendor.length > 0 ){}else{
				this.selectedExpiredResearchRenewalNotificationPopupDataForStrip.forEach((element:any) => {
					if( element['VendorName'] ){
						Vendor.push( element );
					}
				});		
			}
		}
		if( Vendor && Vendor.length > 0 ){
			this.selectedVendorNameForResearchRenewalNotificationPopUpDataForStrip = Vendor[0]
		}
		console.log("selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip", this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip);
		console.log("selectedExpiredResearchRenewalNotificationPopupDataForStrip", this.selectedExpiredResearchRenewalNotificationPopupDataForStrip);
		console.log("Vendor", Vendor);
		}else{
			// get if expiring to be or epired Data
			let  currentDate:any  = moment(new Date().setHours(0, 0, 0, 0));

	       let element = this.researchRenewalData.renewProductDetails[0];
            let daysLeft = this.getDateDiff(currentDate, moment(element.ExpiryDate).startOf('day'));
			
			element['daysLeft'] = daysLeft;
			console.log("single element here", element, daysLeft);
			let Vendor:any = [];
			if( element['VendorName'] ){
				Vendor.push( element );
			}
			if( Vendor && Vendor.length > 0 ){
				this.selectedVendorNameForResearchRenewalNotificationPopUpDataForStrip = Vendor[0]
			}	
			if(daysLeft >= 0){
				this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip.push(element);
			}else{ 
			this.selectedExpiredResearchRenewalNotificationPopupDataForStrip.push(element);
			}
			console.log("selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip", this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip);
			console.log("selectedExpiredResearchRenewalNotificationPopupDataForStrip", this.selectedExpiredResearchRenewalNotificationPopupDataForStrip);	
		}
	}
   }

    compareDates = (d1, d2) => {
	let date1 = moment(d1).format('L');    
	let date2 = moment(d2).format('L');    
	if (date1 < date2) {
		return false;
		// get all data also within 1 year of of first product
	} else if (date1 > date2 + 365) {
       return true;
	} else {
		return true;
	}
  };

  getDateDiff = (d1, d2) => {
	// let date1 = moment(d1).format('L');    
	// let date2 = moment(d2).format('L');  
	// return no of days ahead of current days 
	let diffDate = d2.diff(d1,'days');
	return diffDate; 
  };

	callRenewalAlert(){
		this._homeService.getRenewalAlert().subscribe((response: any) => {
                  if(response && response.ResponseType == ApiStatus.SUCCESS){
					if(response.StatusMsg == ApiStatus.DATA_FOUND){
                        this.researchRenewalData = response.data;
						//sample data
						// this.researchRenewalData = 
						// {
						// 	"renewProductDetails": [
						// 		{
						// 			"ProdId": 1953,
						// 			"ProdName": "Income Tax",
						// 			"ExpiryDate": "2024-04-19T13:03:50.000Z"
						// 		}
						// 	],
						// 	"IsPlanExpired": false,
						// 	"IsShow": true
						// }
						this.showResearchRenewalStip();
					}
				  }
		});
	}

	showResearchRenewalStip(){
		if(this.researchRenewalData.IsShow){
		this.isShowResearchRenewalStip = true;
        this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
		this.adjustHeight();
		this.researchRenewalNotificationPopupReminderCalculation();
		}
	}

	@gaEventTrack('', 'research-renewal-notification', 'Click', 0)
	onClickResearchRenewalStrip(){
		let isresearchrenewal:number = 1;
		let ProductList = [];
		let ProductNameList = [];
		this.utmObj = this.globalUtility.createUtmObj();
		if(this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip && this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip.length > 0 ){
			// buy now multiple product
		for (let product of this.selectedExpiringTobeResearchRenewalNotificationPopupDataForStrip) {
				ProductList.push(Number(product.ProdId));
				ProductNameList.push(product.ProdName);
		}
	} 
	if(this.selectedExpiredResearchRenewalNotificationPopupDataForStrip && this.selectedExpiredResearchRenewalNotificationPopupDataForStrip.length >  0){
		// buy now multiple product
	for (let product of this.selectedExpiredResearchRenewalNotificationPopupDataForStrip) {
			ProductList.push(Number(product.ProdId));
			ProductNameList.push(product.ProdName);
	}
} 
let productIds = ProductList.map(e => e.toString()).join(', ');
let productNames = ProductNameList.map(e => e).join(', ');
this.netCorePayload = {
	pageUrl: window.location.href,
	productId: productIds,
	productName: productNames
  }
  this.netCorePayload = { ...this.netCorePayload, ...this.utmObj }  
  this.globalUtility.netCoreEventTrack(NETCORE_EVENT_LIST.BUY_NOW, this.netCorePayload, 'Research Website');    
this._taxmannHelperService.buyNowMultiple(ProductList, RegisterPlatformType.RESEARCH, isresearchrenewal, 'node');
   this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.RENEW_RESEARCH_PLAN, 1, 24* 60 * 60 * 1000 ) //time here in seconds 
	}

	hideResearchRenewalNotificationPopup(event){
		this.onClickOkayResearchRenewalNotificationPopup(event)
	}

	onClickOkayResearchRenewalNotificationPopup(noOfDaysSelected:number) {
		let data = {
		  "ProdId": this.getResearchRenewalProductIds(),
		  "ShowOnDays": noOfDaysSelected
		}
		this._homeService.postRenewalAlert(data).subscribe((response: any) => {
		  if (response && response.ResponseType == ApiStatus.SUCCESS) {
			if (response.StatusMsg == ApiStatus.DATA_SAVED) {
			  this.showResearchRenewalNotificationPopup = false;
			  this.researchRenewalStipEvent.emit(this.isShowResearchRenewalStip);
			  this.adjustHeight();
			}
		  }
		});
	  }
	
	  getResearchRenewalProductIds() {
		let dataArray = this.researchRenewalData.renewProductDetails.map((data) => data.ProdId);
		return dataArray.join(',');
	  }

	  adjustHeight() {
		setTimeout(() => {
			this.globalUtility.adjustViewHeight('preview-section', HeightAdjustElementType.HTML_CLASS);
			this.globalUtility.adjustViewHeight('html-view', HeightAdjustElementType.HTML_CLASS);
			this.globalUtility.adjustViewHeight('resultList', HeightAdjustElementType.HTML_ID)	
		}, 100);
	}
	
	getStudentCartCounter() {

		if (this.isLoggedIn) {
			const data = {
				cartSource: CheckoutFrom.CART,
			}
			//this._homeService.getCartDetails(data).subscribe((response) => {
				this._homeService.getStudentCartCount(data).subscribe((response) => {
				this.studentCartCounter = 0;
				this.studentCartCounter = response?.data?.totalCount;
				// if (response.Status != 'DATA_NOT_FOUND' && response.Data && response.Data[0].cartList.length > 0) {
				// 	for (let i = 0; i < response.Data[0].cartList.length; i++) {
				// 		this.cartCounter = this.cartCounter + response.Data[0].cartList[i].qty;
				// 	}
				// }
			});
		}
	}

}
