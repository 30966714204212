import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderNewComponent } from './header-new.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GlobalSearchModule } from '../global-search/global-search.module';
import { JoyrideModule } from 'ngx-joyride';
import { TaxmannCommonModule } from 'src/app/taxmann-common/taxmann-common.module';
import { ControlMessageModule } from 'src/app/shared/control-message/control-message.module';
import { NewsletterPopupComponent } from 'src/app/library/newsletter-popup/newsletter-popup.component';
import { UserService } from '../user/user.service';
import { ResearchRenewalNotificationPopupComponent } from 'src/app/library/research-renewal-notification-popup/research-renewal-notification-popup.component';

@NgModule({
	declarations: [HeaderNewComponent , NewsletterPopupComponent, ResearchRenewalNotificationPopupComponent],
	providers:[UserService],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		GlobalSearchModule,
		JoyrideModule.forChild(),
		TaxmannCommonModule,
		ControlMessageModule
	],
	exports: [
		HeaderNewComponent
	]
})
export class HeaderNewModule { }
