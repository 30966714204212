import {Injectable} from '@angular/core';
import { map } from 'rxjs/operators';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { GlobalUtility } from 'src/app/utility/global-utility';

import { HttpHelperService } from '../http-helper.service';
import {  Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceType } from 'src/constants/constant';
import { Location } from '@angular/common';

@Injectable()
export class StartupService {

	constructor(
		private router: Router,
		private globalUtility: GlobalUtility,
		private httpHelperService: HttpHelperService,
		private deviceService: DeviceDetectorService,
		private location: Location) {
		this.getRefreshToken()
	}
	/** This is the method you want to call at bootstrap
	* Important: It should return a Promise
	*/

	public async load(): Promise<any> {
		try {
			await this.redirectBookstoreToCACS();
		} catch (e) {

		}
		if (await this.checkLoginSession()) {
			this.getUserIp();
			this.checkActiveUser();
			this.loginWithCookies();
			this.getCountrySTDCodes();
			return Promise.resolve(true);
		}
	}


	redirectBookstoreToCACS():any {
		let url: any = this.globalUtility.makeUrl(TaxmannUrl.GET_CA_CS_REDIRECTION_URLS);

		this.httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse))
			.subscribe((response) => {
				console.log(response)
				if (response.Data) {
					window['BOOKSTORE_CA_CS_ROUTE'] = response?.Data;
					if(response.Data && response.Data.length){
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.BOOKSTORE_CA_CS_ROUTE, JSON.stringify(response?.Data));
					}
				}
			}, error => {
 				console.log(error)
			});
	}

	/**
	 * get user IP
	 */

	getUserIp() {
		let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.GET_USER_IP);
		let doaminName = window.location.hostname;
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_DOMAIN, doaminName);
		let payload = {
			'domainname': doaminName
		}
		this.httpHelperService.post(url, payload, false)
			.pipe(map(this.globalUtility.filterResponse))
			.subscribe((res) => {
				console.log(res)
				if (res.data) {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_IP, res.data[0]);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.IS_PROXY_USER, res.data[1]);
					if (!this.globalUtility.isLoggedIn()) {
						this.loginIpUser(res.data[0]);
					}
				}
			}, error => {
				console.log("error in getting IP Address")
				console.log(error)
			});
		// }
	}


	getRefreshToken() {
		let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.GET_REFRESH_TOKEN_WEB);

		this.httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse))
			.subscribe((res) => {
				console.log(res)
				if (res.data) {
					sessionStorage.setItem('refreshToken', res?.data?.token)
				}
			}, error => {
				console.log("error in getting IP Address")
				console.log(error)
			});
		// }
	}
	/**
	 * login ip user
	 * @param ip_address
	 */

	loginIpUser(ip_address: string) {
		let deviceType = this.deviceService.isDesktop() ? DeviceType.DESKTOP : this.deviceService.isTablet() ? DeviceType.TABLET : this.deviceService.isMobile() ? DeviceType.MOBILE : '';
		let data = {
			ip_address: ip_address, //'121.242.160.162' -sample ip
			'ipAddress': ip_address,
			'userAgent': this.deviceService.getDeviceInfo()['userAgent'],
			'device': this.deviceService.device,
			'os': this.deviceService.os,
			'osVersion': this.deviceService.os_version,
			'browser': this.deviceService.browser,
			'browserVersion': this.deviceService.browser_version,
			'deviceType': deviceType
		};
		let params = new URL(document.location.toString()).searchParams;
		let token = params.get("token");
       if(token){
		data['IpUserToken']= token;
	   }		
		let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.LOGIN_IP_USER);
		this.httpHelperService.postMethodWithoutErrorHandling(url, data, false)
			.pipe(map(this.globalUtility.filterResponse))
			.subscribe((res) => {
				console.log(res);
				if (res && res.data) {
					let data = res.data;
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.EMAIL, data.user_email);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_NAME, data.user_name);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, data.login_token);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.ISIPUSER, 'true');
					this.globalUtility.checkForLogin.next(true);
					this.createIreaderCookies();
					this.createExamModuleCookies();
				}

			}, error => {
				console.log(error)
			});
	}

	loginWithCookies() {
		let ireaderCookie = this.globalUtility.getCookie(LocalStorageDataModel.I_READER_COOKIE);
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			return;
		} else if ((this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') && this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			return;
		}
		else {
			if (ireaderCookie && ireaderCookie.trim()) {
				if (!(ireaderCookie[0] == '?' || ireaderCookie[0] == '&')) {
					ireaderCookie = '?' + ireaderCookie;
				}
				let ipUser = this.globalUtility.getQueryStringDataParameter(LocalStorageDataModel.I_READER_USER_IPUSER_COOKIE, ireaderCookie)
				if (ipUser && ipUser === 'true') {

				} else {
					let userToken = this.globalUtility.getQueryStringDataParameter(LocalStorageDataModel.I_READER_USER_TOKEN_COOKIE, ireaderCookie)
					let userName = this.globalUtility.getQueryStringDataParameter(LocalStorageDataModel.I_READER_USER_NAME_COOKIE, ireaderCookie)
					let userEmail = this.globalUtility.getQueryStringDataParameter(LocalStorageDataModel.I_READER_USER_EMAIL_COOKIE, ireaderCookie)

					this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, userToken, 43200);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, userToken);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_NAME, userName);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.EMAIL, userEmail);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.ISIPUSER, 'false');
					this.createIreaderCookies();
					this.createExamModuleCookies();
				}
			} else {
			}
		}
		let ireader_token = this.globalUtility.getCookie(LocalStorageDataModel.I_READER_TOKEN);
		if (ireader_token && ireader_token.trim()) {
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, ireader_token);
		}
	}

	createExamModuleCookies() {
		let url = this.globalUtility.makeUrl(TaxmannUrl.ENCRYPT_SHA);
		let payload = {
			'plaintext': this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)
		}
		this.httpHelperService.post(url, payload, false)
			.pipe(map(this.globalUtility.filterResponse))
			.subscribe((res) => {
				if (res.Status === 'DATA_FOUND') {
					let cookieValue = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME).trim() + '|' + res.Data;
					this.globalUtility.setCookie(LocalStorageDataModel.EXAM_COOKIE, cookieValue, 30)
				}
			}, error => {
				console.log(error)
			});
	}

	checkActiveUser() {
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			this.createIreaderCookies();
		}
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)) {
			this.createExamModuleCookies();
		}
	}

	createIreaderCookies() {
		let cookieValue = "userToken=" + this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN) +
			"&userEmail=" + encodeURIComponent(this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL)) +
			"&userName=" + this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_NAME).replace(" ", '+') +
			"&isIpUser=" + this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER);
		this.globalUtility.setCookie(LocalStorageDataModel.I_READER_COOKIE_TAXMANN, cookieValue, 1440)
	}

	checkLoginSession() {
		if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			this.isloginSessionValid().subscribe((res: any) => {

				if (res.success == false && res.ResponseType == "SESSION_EXPIRED") {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN);
					this.globalUtility.redirectToReactLogin()
				} else if (res.success == false && res.ResponseType == "TOKEN_INVALID") {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
					this.globalUtility.redirectToReactLogin()
				}
				return Promise.resolve(true);
			}, (error)=>{
				if (error.error.success == false && error.error.ResponseType == "SESSION_EXPIRED") {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN);
					this.globalUtility.redirectToReactLogin()
					return Promise.resolve(true);
				} else if (error.error.success == false && error.error.ResponseType == "TOKEN_INVALID") {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)
					this.globalUtility.redirectToReactLogin()
					return Promise.resolve(true);
				}
			})
		} else {
			let itatArr = ["http://itat.gov.in/","https://itat.gov.in/","http://www.itat.gov.in/","https://www.itat.gov.in/"];

			if (itatArr.includes(document.referrer)) {
				const parameters = new URLSearchParams(window.location.search);
				let email = parameters.get('email');
				let tokenId = atob(parameters.get('tokenid'));
				this.location.replaceState('/');
				if (!(email && tokenId)) {
					return Promise.resolve(true);
				}

				let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.GET_USER_IP);
				let doaminName = window.location.hostname;
				this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_DOMAIN, doaminName);
				let payload = {
					'domainname': doaminName
				};
				this.httpHelperService.post(url, payload, false)
					.pipe(map(this.globalUtility.filterResponse))
					.subscribe((res) => {
						console.log(res)
						if (res.data) {
							this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_IP, res.data[0]);
							this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.IS_PROXY_USER, res.data[1]);
						}
						this.httpHelperService.loginItatUser({ email, userId: tokenId, password: '' }).subscribe((res) => {
							if (res && res.data) {
								let data = res.data;
								this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.EMAIL, data.user_email);
								this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.USER_NAME, data.user_name);
								this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.ISIPUSER, 'false');
								this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, data.login_token, 43200);
								this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, data.login_token);
								this.createIreaderCookies();
								this.createExamModuleCookies();
								this.router.navigate(['/research']);
								this.httpHelperService.logItatUser(email, parameters.get('tokenid'));
							} else {
								return Promise.resolve(true);
							}
						}, error => {
							return Promise.resolve(true);
						});
					}, error => {
						console.log("error in getting IP Address")
						console.log(error)
					});


			} else {
				return Promise.resolve(true);
			}
		}
	}

	isloginSessionValid() {
		let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_LOGIN_SESSION);
		return this.httpHelperService.postwithoutCheckResponseAndErrorHandler(url, {}, true);
	}

	getCountrySTDCodes() {
		let countrylist = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.COUNTRY_LIST);
		if (!countrylist) {
			let countrySTDCodesListDropdown = [];
			let url = this.globalUtility.makeUrl(TaxmannUrl.GET_COUNTRY_CODES);
			this.httpHelperService.get(url, {}, true).subscribe((res) => {
				res.Data.forEach((country) => {
					let data = {
						value: country.dial_code,
						label: country.name
					}
					countrySTDCodesListDropdown.push(data);
				});
				this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.COUNTRY_LIST, JSON.stringify(countrySTDCodesListDropdown))
			})
		}
	}
}


