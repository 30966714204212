export const TaxmannUrl = {
	REGISTER_USER_BY_EMAIL: "registration/registerUserByEmail",
	REGISTER_USER_BY_SOCIAL: "registration/registerUserBySocial",
	GENERATE_OTP: "registration/generateOTP",
	VERIFY_OTP: "registration/verifyOTP",
	GET_STATE_PROFESSION_AOI: "registration/getStateProfessionInterest",
	PUT_STATE_PROFESSION_AOI: "registration/putStateProfessionInterest",
	LOGIN: "auth/login",
	ADD_PHOTO: "myAccount/addPhoto",
	USER_INFO: "myAccount/getuserInfo",
	WISH_LIST: "myAccount/getUserWishList",
	CACS_WISH_LIST: "userAccount/getUserWishList",
	CHANGE_PASSWORD: "password",
	ADD_GSTIN_INFO: "myAccount/addGstinInfo",
	UPLOAD_FILES: "myAccount/uploadFiles",
	REMOVE_GSTIN_INFO: "removeGstinInfo/",
	SUBSCRIPTION: "useraccount/user-subscription",
	SUBSCRIPTION_YEAR:"useraccount/user-subscription-years",
	ORDER: "myAccount/myOrder",
	CA_CS_ORDER:'userAccount/getUserOrderHistory',
	CHANGE_SHIPPING_ADDRESS: "myAccount/changeshippingidinorder",
	REMOVE_WISHLIST: "myAccount/removeFromWishList",
	UPDATE_PERSONAL_INFO: "myAccount/editPersonalInfo",
	EDIT_USER_NAME: "myAccount/editUserName",
	CANCEL_ORDER: "myAccount/cancelOrder",
	PRICING: "pricing/research",
	STUDENT_CANCEL_ORDER: "userAccount/cancelOrder",
	FORGOT_PASSWORD: "login/resetPasswordSendRequest",
	RESET_PASSWORD: "password",
	ADIT_PRICING: "pricing/adit",
	ADD_PRODUCT_TO_REACT_CART: "cart/addProductsToUserCart",
	PRICING_FAQ: "support/faqs",
	CALCULATE_INCOME_TAX: "income_tax",
	AS_DEFRRED_CALCULATE: "deferred_income_tax",
	CHECK_ACTIVE_SESSION: "auth/check_active_session",
	LOGOUT: "logout",
	AUTHLOGOUT: "auth/logout",
	CLAIM_REWARD: "claimReward",
	GET_ACTIVE_LOYALTY_POINTS: "activeLoyaltyPoint",
	GET_LOYALTY_LOGS: "loyaltyPoint/logs",
	// GET_USER_IP:'login/getclientIP',
	GET_USER_IP: "auth/getclientipbydomain",
	GET_REFRESH_TOKEN_WEB: "refreshToken/web",
	LOGIN_IP_USER: "auth/ipUserLogin",
	GET_CA_CS_REDIRECTION_URLS: "taxmann/getcacsurls",
	GET_OTHER_TOOLS: "support/downloadsothertools",
	GET_SETUP_DOWNLOADS: "support/setupdownloads",
	POST_UPDATE_SOFTWARE_DOWNLOAD_iNFO: "support/UpdateSoftwareDownloadInfo",
	POST_ENQUIRY: "support/contactus",
	GET_FAQ_SUBJECTS: "support/faqssubjects",
	GET_FAQS: "support/faqs",
	GET_SERVICE_PACK_CATEGORIES: "support/getservicepackcategories",
	GET_SERVICE_PACK_DOWNLOADS: "support/servicepackdownloads",
	CHECK_ACTIVE_IREADER_SESSION: "auth/check_active_ireader_session",
	TAXMANN_SURVEY: "survey/addTaxmannDailySurvey",
	NEWS_LETTER_REGISTRATION: "newsLetter/newsLetterRegistration",
	GET_INDIAN_STATE: "iipmember/getIndiansate",
	GET_PIN_INFO: "iipmember/getPininfo/",
	SEND_OTP: "iipmember/sendOtp",
	VERIFY_FORM_OTP: "iipmember/verifyOTP",
	ADD_IIP_MEMBERS: "iipmember/addiipMembers",
	GET_STATE_CITY_BY_PINCODE: "offlineresearchlead/getCityStateByPinCode",
	SAVE_OFFLINE_RESEARCH_ENQUIRY: "offlineresearchlead/SaveOfflineResearchEnquiry",
	SEND_OTP_FOR_OFFLINE_SEARCH_LEAD: "offlineresearchlead/SendOTP",
	VERIFY_OTP_FOR_OFFLINE_SEARCH_LEAD: "offlineresearchlead/VerifyOTP",
	GET_USER_INFO_BY_EMAIL: "productEnquiry/research-demo-request/",
	SEND_OTP_FOR_RESEARCH_DEMO_REQUEST: "productEnquiry/send-otp",
	VERIFY_OTP_FOR_RESEARCH_DEMO_REQUEST: "productEnquiry/verify-otp",
	ADD_RESEARCH_DEMO_REQUEST: "productEnquiry/research-demo-request",
	SEND_OTP_FOR_ONE_MONTH_FREE_ONLINE_RESEARCH: "onemonthfreeonlineresearch/SendOTP",
	VERIFY_OTP_FOR_ONE_MONTH_FREE_ONLINE_RESEARCH: "onemonthfreeonlineresearch/VerifyOTP",
	SAVE_ONE_MONTH_FREE_ONLINE_RESEARCH_ENQUIRY: "onemonthfreeonlineresearch/SaveOfflineResearchEnquiry",
	GET_QUESTIONS_WITH_OPTION_FOR_SURVEY: "questionnaire/getQuestionWithOption",
	INSERT_QUESTIONNAIRE: "questionnaire/insertQuestionnaire",
	GET_LISTING_VALUES_FOR_PIN_CONFIRMATION: "researchPinConfirmation/getListingValues",
	ACTIVATE_TAXMANN_KEY: "researchPinConfirmation/activateTaxmannkey",
	// User site tracking API
	USER_SITE_TRACKING: "userSiteTracking",
	// Tax practice API's
	GET_TAX_PRACTICE_INTEREST: "practice/getPracticeInterest",
	POST_TAX_PRACTICE_INTEREST: "practice/postPracticeInterest",
	DELETE_RESEARCH_BOX: "researchBox",
	RESEARCH_AUTO_SAVE: "research/researchAutosave",
	ADD_GSTIN_FILE: "myAccount/uploadFiles",
	GET_COUNTRY_CODES: "research/countrycodes",
	// digital footprint API's
	SAVED_DIGITAL_FOOTPRINT: "/digitalfootprint/register-user",
	VERIFY_OTP_FOR_DIGITAL_FOOTPRINT: "/digitalfootprint/verify-otp",
	SAVED_DIGITAL_FOOTPRINT_PRODUCT: "/digitalfootprint/freebie",
	// journal rquest address
	Sample_Journal_UserAddress: "BookStoreProductDetails/postSampleJournalUserAddress",
	// secure document
	GET_STATE_AND_META_INFO: "downloadDocument/getStateAndMetaInfo",
	SAVE_DOWNLOAD_DOC_USER: "downloadDocument/saveDownloadDocUser",
	// encrypt API
	ENCRYPT_SHA: "research/encryptSHA256",
	// journal sample book
	GET_JOURNAL_LIST: "journals/getjournalslist",
	// product catelogue
	GET_CATALOGUE: "Catalogue/getCatalogue",
	TAXMANN_ALLIED_PAYMENT: "order/alliedOrder",
	SAVE_ERROR_LOG: "research/saveerrorlog",
	CHECK_TAXMANN_ACTIVE_IREADER_SESSION: "check_taxmann_active_ireader_session",
	// Review Bulletein Form
	GET_BULLETIN_HTML: "reviewBulletin/GetBulletinHTML/",
	REGISTER_REVIEW_BULLETIN_USER: "reviewBulletin/RegisterReviewBulletinUser",
	RESEND_REVIEW_BULLETIN_OTP: "reviewBulletin/ReSendOTP",
	DOWNLOAD_REVIEW_BULLETIN: "reviewBulletin/downloadReviewBulletin",
	DEACTIVATE_VIRTUAL_BOOK_FREE_TRIAL: "VirtualBookProductDetails/updateAndGetReadTime/",
	DEACTIVATE_VIRTUAL_JOURNAL_FREE_TRIAL: "",
	// ETDS
	GET_ETDS_PRODUCT_DETAILS: "pricing/etds",
	GET_CITIES: "complianceetds/getcities",
	GET_STATES: "registration/getStateProfessionInterest",
	DOWNLOAD_ETDS_COMPLIANCE: "complianceetds/etdsdownload",
	ETDS_TIME_SLOT: "complianceetds/gettimeslot",
	REQUEST_CALL_BACK: "complianceetds/requestcallback",
	ETDS_DEMO_REQUEST_SEND_OTP: "complianceetds/SendOTP",
	ETDS_DEMO_REQUEST_VERIFY_OTP: "complianceetds/VerifyOTP",
	ETDS_DUE_TIMER: "complianceetds/GeteTDSDueDateTimer",
	ETDS_CALENDAR: "complianceetds/GeteTDSCalendarDetails",
	SEARCH_LOG: "search/log",
	// Tax practice Nofiy Me API's
	GET_TAX_PRACTICE_NOTIFYME: "practice/checkpracticenotify",
	POST_TAX_PRACTICE_NOTIFYME: "practice/postPracticenotify",
	// Primary Flow Login
	MOBILE_LOGIN: "login/loginwithmobile",
	VERIFY_OTP_MOBILE_PRIMARY: "login/verifyotpmobileprimary",
	EMAIL_REQUEST_OTP: "login/emailverifyotprequest",
	VERIFY_OTP_MOBILE_NOT_PRIMARY: "login/verifyotpmobileprimarynotindb",
	EMAIL_VERIFY_OTP: "login/emailverifyotpsubmit",
	EMAIL_VERIFY: "login/verifyemail",
	REGISTER_USER_BY_MOBILE_EMAIL: "login/registeruserbymobileemail",
	//login session
	CHECK_ACTIVE_LOGIN_SESSION: "auth/check_active_login_session",
	DOWNLOAD_ORDER_INVOICE: "myAccount/donloadinvoicepdf",
	GET_ORDER_INVOICE_PDF: "naverp/invoice-pdf",
	RETURN_REASON_OPTION_LIST: "myAccount/getreturnreasonmaster",
	RETURN_ORDER: "myAccount/returnOrder",
	ORDER_TRACKING_DETAILS: "myAccount/trackMyOrder",
	IS_REGISTERED_USER: "login/verifyemail",
	FREE_TRAIL_AFTER_REGISTRATION: "research/postFreeTrialResearch",
	CHECK_ACTIVE_RESEARCH: "research/checkExistingSubscription",
	// TAXMANN_DD_ALLIED_PAYMENT: "payment/getddneftpayment",
    POSTAGE_PAYMENT_DD_NEFT: "payment/postddneftpayment",
	TAXMANN_DD_ALLIED_PAYMENT: "order/getddneftpayment",
	SAVE_UTM_DETAILS: "taxmann/SaveUTMDetails",
	TAXMANN_PUBLICATION_PAYMENT: "order/tpplOrder",
	ACADEMY_REFRRAL_PROCESS: 'academy/referralprocess',
	GET_TDS_DATA: 'tdsData',
	GET_RECIPIENT_TYPE_DATA: 'recipientTypes',
	GET_RECIPIENT_CATEGORIES_DATA: 'recipientCategories',
	GET_SECTIONS: 'sections',
	GET_OPTIONS: 'options',
	POST_CALCULATE_TDS: 'TDS/calculate',
	GET_SURCHARGE_RATES: 'surchargeRateList',
	// referee details api
	REFEREE_DEATAILS: 'myAccount/getRefereeDetails',
	CUSTOMER_SURVEY_FEEDBACK: "Customer/CustomerSurveryFeedback",
	//CASE_CLOSURE_FEEDBACK:'centax/submitfeedback',
	CASE_CLOSURE_FEEDBACK: 'crm/submitfeedback',
	// check for valid email during registration process
	CHECK_EMAIL_EXISTS: 'registration/CheckEmailExists',
	// customer order delivery feedback
	DELIVERY_ORDER_FEEDBACK: 'deliveredOrderFeedback',
	// Newsletter
	NEWSLETTER: 'newsletters',
	// newsletter setting
	NEWSLETTER_SETTING: 'newsletter/settings',
	// newsletter togglepost
	NEWSLETTERTOGGLE:'newsletter',
	//ANALYTICAL_DIGEST_TOGGLE:'newsletterSubscription',
	ANALYTICAL_DIGEST_TOGGLE:'emileProcess',
	ANALYTICAL_DIGEST_TOGGLE_DB:'analyticalDigestUpdateToDb',
	LOGIN_BANNER_ADVERTISEMENT:'banners/getAdvertisements/login_page/topbanner',
	REGISTER_BENNER_ADVERTISEMENT:'banners/getAdvertisements/registeration_page/topbanner',
	// Endmile Event Track
	ENDMILE_EVENT_TRACK:'eventemileProcess',
	PERSONAL_INFO:'userAccount/getPersonalInfo',
	GENERATE_OTP_AUTH: "auth/registration/generateOTP",
	VERIFY_OTP_AUTH: "auth/registration/verifyOTP",
	USER_ADDRESS:'userAccount/getUserAddress',
	LINKED_DEVICES_SESSION:'session/log'
};


export const MANAGE_NEWSLETTER_API = {
	NEWSLETTER_DEFAULT_SETTING :'newsletter/defaultSettings',
	NEWLETTER_CONSENT_FLAG:'newsletterConsentFlags',
	NEWSLETTER_OPT_OUT:'optOutNewsletter',
}

export const STUDENTS_API = {
GET_PAYMENT_DETAILS: "order/getddneftpayment",
SAVE_SUCCESSFUL_ORDER: "order/saveddneftpayment",
GET_RETURN_ORDER_TRACKING: "userAccount/returnCancellationDetails",
GET_CANCEL_REASON_LIST:"userAccount/getCancelReasonLists",
GET_RETURN_REASON_LIST:"userAccount/getReturnReasonLists",
CANCEL_ORDER:"userAccount/cancelOrder",
CHANGE_SHIPPING_ADDRESS: "userAccount/changeShippingAddress",
GET_TRACKING_ORDER_DETAILS: "userAccount/getTrackingOrderDetails",
GET_SHIPROCKET_TRACKING: "userAccount/getShiprocketTracking",
GET_STUDENT_ORDER_DETAILS: "userAccount/getUserOrderHistory",
RETURN_ORDER:"userAccount/returnOrder",

}
