import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaxmannUrl } from '../api-url/taxmann-url.enum';
import { APIResponse } from '../models/interfaces/api-response';
import { GlobalUtility } from '../utility/global-utility';
import { HttpHelperService } from './http-helper.service';

@Injectable()
export class ArchiveSharedService {
    private eventSource = new BehaviorSubject(null);
    currentSoucre = this.eventSource.asObservable();
    private syncSource = new BehaviorSubject(null);
    dropBoxSyncSuccess = this.syncSource.asObservable();
    requestedJournals;
    startCaseLawTour: boolean = false;
    startResearchBoxTour: boolean = false;
    private eventBudgetSource = new BehaviorSubject(null);
    currentBudgetSoucre = this.eventSource.asObservable();
    private eventStorySource = new BehaviorSubject(null);
    currentStorySource = this.eventStorySource.asObservable();

  constructor(private globalUtility: GlobalUtility,
    private _httpHelperService: HttpHelperService) {}

    clickCurrentStorySource(type: string) {
      console.log("set type for story source", type);
      this.eventStorySource.next(type)
    }

  clickCurentSource(type: string) {
    console.log("set type for back", type);
    this.eventBudgetSource.next(type)
  }
  clickBudgetSource(type: string) {
    console.log("set type for back", type);
    this.eventSource.next(type)
  }

  dropboxSyncSuccessSource(type: Object) {
    console.log("set type for dropboxSyncSuccessSource", type);
    this.syncSource.next(type)
  }

  getProductCatalogue(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GET_CATALOGUE);
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }


}
