import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageDataModel } from '../../constants/local-storage-data-model';
import { APIResponse } from '../models/interfaces/api-response';
import { environment } from '../../environments/environment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import {CheckoutInformation, RedisResearchDataStruct} from 'src/interfaces/interface';
import { printPreviewCss, printActsCss } from 'src/constants/print-constants';
import { PrintCommand, HeightAdjustElementType, BookstoreProduct, PaymentModes } from 'src/enums/enum';
import { TaxmannCryptoJS } from '../crypt';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { ACTION_PRACTICE_LAUNCH_MODULE } from '../tax-practice/constants/tax-practice.constants';

declare var smartech: Function;
@Injectable({
	providedIn: 'root'
})
export class GlobalUtility {

	researchSidebarClicked = new Subject<string>();
	researchFooterVisiblity = new BehaviorSubject<boolean>(true);
	researchTabRedisData = new BehaviorSubject<RedisResearchDataStruct>(null);
	forceRedisPush = new Subject<any>();
	env;

	// ========views based events start======
	researchViewOperation = new Subject<{ type: number, data: any }>();
	applyView = new Subject<any>();
	showSaveView = new Subject<boolean>();
	resetView = new Subject<boolean>();
	isUnreportedCaseLaws = new Subject<boolean>();
	// =========view based events end ===========


	_showLoader: EventEmitter<boolean> = new EventEmitter<boolean>(true);
	utm_campaign: any;
	utm_medium: any;
	utm_source: any;
	utm_cid: any;
	utm_statsid: any;
	set showLoader(val: boolean) {
		this._showLoader.emit(val);
	}

	printWindow: any;
	logout = new Subject<boolean>();
	userAuthStatus = new Subject<number>();
	goToPageNo = new Subject<number>();
	researchSidebarHighlight = new Subject<string>();
	reloadResearchBoxFolder = new Subject<boolean>();
	checkForLogin = new BehaviorSubject<boolean>(null);
	viewedDocumentsListSub = new BehaviorSubject<any>([]);
	viewedDocumentsList: any[] = [];

	researchSearchValue = new Subject<string>();
	researchSeeMoreActive = new Subject<{ id: string, flag: boolean }>();
	researchCorrectSearch = new Subject<string>();

	bodyScrollOff = new BehaviorSubject<boolean>(false);
	IPUserSessionsList = new Subject<any>();
	logoutwithWindoowClose = new Subject<boolean>();
	virtualSidebarHighlight = new Subject<string>();
	bookStoreSidebarHighlight = new Subject<string>();
	saveUserVisitedRoutes = new Subject<boolean>();

	private _similarHeadingIndex: number | undefined;

	private _taxPracticeBackButtonVisibleInBaseTabComponent$ = new BehaviorSubject<{ visible: boolean; redirectionPath?: string }>({ visible: false });
	private _isSimilarHeading$ = new BehaviorSubject<boolean>(false);
	private _isSimilarRouteForPractice$ = new BehaviorSubject<boolean>(false);
	updateUsername = new BehaviorSubject<boolean>(null);
	actionPracticeLaunchModule = ACTION_PRACTICE_LAUNCH_MODULE;
	isAdvanceReaderOpen$ = new BehaviorSubject(false);

	private isPodCastsPage$ = new BehaviorSubject<boolean>(false);
	private freeTrialActivated$ = new BehaviorSubject<boolean>(false);
	monthList = [
		{ value: "1", label: "January" }, { value: "2", label: "February" }, { value: "3", label: "March" }, { value: "4", label: "April" },
		{ value: "5", label: "May" }, { value: "6", label: "June" }, { value: "7", label: "July" }, { value: "8", label: "August" }, { value: "9", label: "September" },
		{ value: "10", label: "October" }, { value: "11", label: "November" }, { value: "12", label: "December" }
	];

	constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

	handlePrice = (price: number): string => price?.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
	
	getIsPodcastsPageEnable() {
		return this.isPodCastsPage$.asObservable();
	}

	setIsPodcastsPageEnable(data) {
		return this.isPodCastsPage$.next(data);
	}

	getIsFreeTrialActivated() {
		return this.freeTrialActivated$.asObservable();
	}

	setIsFreeTrialActivated(data) {
		return this.freeTrialActivated$.next(data);
	}
	/**
	* getButtonVisibleInBaseTabComponentObs
	*/
	public getTaxPracticeBackButtonVisibleInBaseTabComponentObs(): Observable<{ visible: boolean; redirectionPath?: string }> {
		return this._taxPracticeBackButtonVisibleInBaseTabComponent$.asObservable();
	}

	/**
	 * setBackButtonVisibleInBaseTabComponentRelData
	 */
	public setTaxPracticeBackButtonVisibleInBaseTabComponentRelData(backButtonFuncRelatedData: { visible: boolean; redirectionPath?: string }): void {
		this._taxPracticeBackButtonVisibleInBaseTabComponent$.next(backButtonFuncRelatedData);
	}


	public getIsSimilarHeadingObs(): Observable<boolean> {
		return this._isSimilarHeading$.asObservable();
	}

	public setIsSimilarHeadingVisible(isSimilarHeading: boolean): void {
		this._isSimilarHeading$.next(isSimilarHeading);
	}

	public getIsSimilarRouteForPracticeObs(): Observable<boolean> {
		return this._isSimilarRouteForPractice$.asObservable();
	}

	public setIsSimilarRouteForPractice(isSimilarRoute: boolean): void {
		this._isSimilarRouteForPractice$.next(isSimilarRoute);
	}

	public getSimilarHeadingIndex(): number | undefined {
		return this._similarHeadingIndex;
	}

	public setSimilarHeadingIndex(similarHeadingIndex: number | undefined) {
		this._similarHeadingIndex = similarHeadingIndex;
	}


	setDataOnLocalStorage(key: string, value: string) {
		localStorage.setItem(key, value);
	}

	getDataFromLocalStorage(key: string): string {
		if (localStorage.getItem(key) != 'undefined' && localStorage.getItem(key) != null) return localStorage.getItem(key);
		return '';
	}

	removeFromLocalStorage(key: string) {
		localStorage.removeItem(key);
	}

	getNsaveCampaignData() {
		this.activatedRoute.queryParams.subscribe((res) => {
			console.log("response of campaign====>", res);
			this.utm_campaign = res.utm_campaign;
			this.utm_medium = res.utm_medium;
			this.utm_source = res.utm_source;
			this.utm_cid = res.utm_cid;
			this.utm_statsid = res.utm_statsid;
			if (this.utm_source) {
				this.setDataOnLocalStorage(LocalStorageDataModel.UTM_CAMPAIGN, this.utm_campaign)
				this.setDataOnLocalStorage(LocalStorageDataModel.UTM_MEDIUM, this.utm_medium)
				this.setDataOnLocalStorage(LocalStorageDataModel.UTM_SORUCE, this.utm_source)
				this.setDataOnLocalStorage(LocalStorageDataModel.UTM_CID, this.utm_cid)
				this.setDataOnLocalStorage(LocalStorageDataModel.UTM_STATSID, this.utm_statsid)
			}
//           if(this.utm_medium && this.utm_medium.toLowerCase().includes('cid')){
// 	            let utm_medium_arr =  this.utm_medium.toLowerCase().split('cid');
// 	           let cid = utm_medium_arr[1];
// 	           this.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER, cid, 24 * 60 * 60 * 100);
// }
		});
	}

	isLoggedIn(): boolean {
		if (this.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			console.log("cookoe got");
			return true
		}
		else if ((this.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') && this.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)) {
			console.log("storage got");
			return true
		}
		else return false;
	}

	isMobileView(): boolean {
		let mediaQueryObj = window.matchMedia("(max-width: 950px)");
		if (mediaQueryObj.matches) return true;
		else return false;
	}

	isMobileViewLowWidth(): boolean {
		let mediaQueryObj = window.matchMedia("(max-width: 600px)");
		if (mediaQueryObj.matches) return true;
		else return false;
	}

	/**
 * to fire all errors before submission
 * @param form FormGroup to be validated.
 */
	public validateAllFormFields(form: FormGroup) {
		const keys = Object.keys(form.controls);
		keys.forEach((field: any) => {
			const control = form.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
				control.markAsDirty({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			} else if (control instanceof FormArray) {
				(<FormArray>control).controls.forEach((element: FormGroup) => {
					if (element instanceof FormGroup)
						this.validateAllFormFields(element);
				});
			}
		});
	}


	/**
	 * filter api response for error handling
	 * @param res
	 * @param index
	 */
	public filterResponse(response: any): APIResponse {
		if (response.ResponseType == 'SUCCESS') {
			return response;

		} else {
			throw new Error(response.ResponseType);
		}
	}

	public CACSCartfilterResponse(response: any): APIResponse {
		if (response) {
			return response;

		} else {
			throw new Error(response.ResponseType);
		}
	}

	public filterResponseWithNullData(response: any): APIResponse {
		if (response.ResponseType) {
			if (response.ResponseType == 'SUCCESS') {
				return response;

			} else {
				throw new Error(response.ResponseType);
			}
		} else {
			return response;
		}
	}


	makeUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].BASE_URL + api_url;
	}

	makeNodeDashboardUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].NODE_DASHBOARD_BASE_URL + api_url;
	}

	makeNodeBookstoreUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].NODE_BOOKSTORE_BASE_URL + api_url;
	}
	makeCACSUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].STUDENTS_SERVER + api_url;
	}

	makeDashBoard(api_url) {
		this.env = this.setENV();
		return environment[this.env].DASHBOARD_URL + api_url;
	}
	makeStudentCrmLead(api_url) {
		this.env = this.setENV();
		return environment[this.env].Student_Base_URL + api_url;
	}

	makeRedisUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].REDIS_URL + api_url;
	}
	makeQaRedisUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].QA_NODE_RESEARCH + api_url;
	}

	makeDashboardUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].NEW_DASHBOARD_SERVER + api_url;

	}

	makeCentaxUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].CENTAX_URL + api_url;
	}

	makeVirtualUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].VIRTUAL_BOOK_URL + api_url;
	}

	makeImageUrl() {
		this.env = this.setENV();
		return environment[this.env].IMAGE_URL;
	}

	makeWebpImageUrl() {
		this.env = this.setENV();
		return environment[this.env].WEBP_IMAGE_URL;
	}

	getWebsiteUrl() {
		this.env = this.setENV();
		return environment[this.env].WEBSITE_URL;
	}

	makeSocialKeyUrl() {
		this.env = this.setENV();
		return environment[this.env].SOCIAL_LOGIN_KEY;
	}

	makeMarketingUrl(api_url: string) {
		this.env = this.setENV();
		return environment[this.env].MARKETING_API + api_url;
	}

	makeAcademyUrl(api_url: string) {
		this.env = this.setENV();
		return environment[this.env].ACADEMY_API + api_url;
	}

	makeCrmServerUrl(api_url: string) {
		this.env = this.setENV();
		return environment[this.env].CRM_SERVER_URL + api_url;
	}

	makeCalenderUrl(api_url: string) {
		this.env = this.setENV();
		return environment[this.env].CALENDER_SERVER + api_url;
	}

	makeEndmileAPItrackUrl(api_url?: string) {
		this.env = this.setENV();
		if(api_url){
			return environment[this.env].ENDMILE_API_SERVER  + api_url;
		}
	    else{
		return environment[this.env].ENDMILE_API_SERVER;
        }
	}

	makeNewBookStore(api_url:string) {
		this.env = this.setENV();
		return environment[this.env].NEW_BOOKSTORE + api_url;
	}
	makeStudentsServer(api_url:string) {
		this.env = this.setENV();
		return environment[this.env].STUDENTS_SERVER + api_url;
	}
	
	makeDownloadPdfUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].DOWNLOAD_PDF_URL + api_url;

	}

	makeAIAdminUrl(api_url) {
		this.env = this.setENV();
		return environment[this.env].AI_ADMIN_URL + api_url;

	}

	

	setENV() {

		if (window.location.hostname.indexOf('localhost') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('taxpractice') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('preprod') !== -1) {
			return 'beta';
		} else if (window.location.hostname.indexOf('mctl') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('lsupport') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('dev') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('vflipbook') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('dev') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('d1586l7wxlo4hi.cloudfront.net') !== -1) {
			return 'local';
		} else if (window.location.hostname.indexOf('qa.taxmann.com') !== -1) {
			return 'local';
		} else {
			return 'prod';
		}
	}

	makeVirtualEbookApiUrl() {
		this.env = this.setENV();
		return environment[this.env].VIRTUAL_EBOOK_API_URL;
	}

	/**
	 * print data list
	 * @param type
	 * @param html
	 */
	printResearchData(type: number, html: string, listFlag: boolean, fileName?: string) {
		html = html.replace(/\[\n\r]/g, '');  //remove escape characters
		html = html.replace(/<!--[\s\S]*?-->/g, "");  // noise removal due to comments
		if (fileName) {
			fileName = fileName.substr(0, 60);
		}
		let finalHtml = '';
		if (listFlag) {
			finalHtml = `<html><head>
			<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
			<style>${printPreviewCss}${printActsCss}</style></head><body>${html}</body></html>`
		} else {
			finalHtml = html;
		}

		if (type === PrintCommand.PRINT) {
			this.printWindow = window.open('www.taxmann.com', 'Print');
			this.printWindow.document.write(finalHtml);
			this.printWindow.document.close();
			setTimeout(() => {
				this.printWindow.focus()
				this.printWindow.print();
				if (!this.isMobileView()) {
					this.printWindow.close();
				}
			}, 200)

		} else {
			let element = document.createElement('a');
			if (type === PrintCommand.SAVE_HTML) {
				element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(finalHtml));
				element.setAttribute('download', `${fileName || 'fileData'}.html`);
			} else if (type === PrintCommand.SAVE_RTF) {
				element.setAttribute('href', 'data:application/rtf;charset=utf-8,' + encodeURIComponent(finalHtml));
				element.setAttribute('download', `${fileName || 'fileData'}.rtf`);
			}
			else if (type === PrintCommand.SAVE_XLS) {
				element.setAttribute('href', 'data:application/vnd.ms-excel;charset=utf-8,' + encodeURIComponent(finalHtml));
				element.setAttribute('download', `${fileName || 'fileData'}.xls`);
			}
			else if (type === PrintCommand.SAVE_DOC) {
				element.setAttribute('href', 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(finalHtml));
				element.setAttribute('download', `${fileName || 'fileData'}.doc`);
			}
			else {
				element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(finalHtml));
				element.setAttribute('download', `${fileName || 'fileData'}.html`);
			}
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}
	}


	/**
	 * get print pagination range
	 * @param totalRecords
	 */
	getPrintPagination(totalRecords: number) {
		let count = 0;
		let pageSize = 50;
		let arr = [];

		while (totalRecords > 0) {
			if (totalRecords / pageSize >= 1) {
				const start = count + 1;
				const end = count + pageSize;
				arr.push({
					label: `${start} - ${end}`,
					value: {
						start: start,
						end: end
					}
				});
				count += pageSize;
				totalRecords -= pageSize;
			} else {
				const start = count + 1;
				const end = count + totalRecords;
				arr.push({
					label: `${start} - ${end}`,
					value: {
						start: start,
						end: end
					}
				});
				count += totalRecords;
				totalRecords = 0;
			}
		}

		return arr;
	}

	/**
	 * getFormattedDate // dd/mm/yyyy
	 * @param date
	 */
	getFormattedDate(date, filterDateType?: number): string {
		let dt = new Date(date);
		let month = this.formatNumber(dt.getMonth() + 1);
		let day = this.formatNumber(dt.getDate());
		let year = this.formatNumber(dt.getFullYear());

		let formattedDate = `${day}/${month}/${year}` // format : DD/MM/YYYY // invalid in new Date('formattedDate')
		if (filterDateType) {
			switch (filterDateType) {
				case 1:
					formattedDate = year + month + day; // format YYYYMMDD
					break;
				case 2:
					formattedDate = `${year}-${month}-${day}`; // format YYYY-MM-DD
					break;
				case 3:
					formattedDate = `${day}-${month}-${year}`; // format YYYY-MM-DD
					break;
				default:
					break;
			}
		}
		return formattedDate;
	}

	// format number
	formatNumber(num) {
		if (num < 10) {
			num = '0' + num
		}
		return num.toString();
	}


	/**
	 * reset session
	 */
	resetSession() {
		this.maintainLastUrlBeforeLogout();
		this.deleteCookieCtime(LocalStorageDataModel.AUTH_TOKEN, 60);
		this.removeFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN);
		this.removeFromLocalStorage(LocalStorageDataModel.CONTENT_TOKEN);
		//  this.removeFromLocalStorage(LocalStorageDataModel.PREVIOUS_URL);
		this.removeFromLocalStorage(LocalStorageDataModel.EMAIL);
		this.removeFromLocalStorage(LocalStorageDataModel.USER_NAME)
		this.removeFromLocalStorage(LocalStorageDataModel.TYPE_FROM);
		this.removeFromLocalStorage(LocalStorageDataModel.LOGIN_TYPE);
		this.removeFromLocalStorage(LocalStorageDataModel.SOCIAL_TYPE);
		this.removeFromLocalStorage(LocalStorageDataModel.PASSWORD);
		this.removeFromLocalStorage(LocalStorageDataModel.CHECKOUT_INFO);
		this.removeFromLocalStorage(LocalStorageDataModel.I_READER_DARK_THEME);
		this.removeFromLocalStorage(LocalStorageDataModel.I_READER_TOKEN);
		this.removeFromLocalStorage(LocalStorageDataModel.ISIPUSER);
		this.removeFromLocalStorage(LocalStorageDataModel.ENDMILE_EVENTS_CID_NUMBER);
		this.removeFromLocalStorage(LocalStorageDataModel.NEWSLETTER_CONSENT_FLAG);
		this.removeFromLocalStorage(LocalStorageDataModel.DISPLAY_CONSENT_FORM);
		this.removeFromLocalStorage(LocalStorageDataModel.RENEW_RESEARCH_PLAN);
		sessionStorage.removeItem(LocalStorageDataModel.CDKey);
		sessionStorage.removeItem(LocalStorageDataModel.DEALER_REFCODE_ADDED_IN_CART);
		this.deleteCookie(LocalStorageDataModel.I_READER_TOKEN);
		this.deleteCookie(LocalStorageDataModel.I_READER_COOKIE);
		this.deleteCookieCtime(LocalStorageDataModel.I_READER_COOKIE_TAXMANN, 60);
		this.deleteCookie(LocalStorageDataModel.EXAM_COOKIE);
		this.resetViewedDocument();
		sessionStorage.removeItem(LocalStorageDataModel.PRACTICE_STRIP_VIEW);
		this.setCookie(LocalStorageDataModel.ACTION_PRACTICE_LAUNCH_MODULE, this.actionPracticeLaunchModule.remindMeLater, 0.001);
		this.removeFromLocalStorage(LocalStorageDataModel.EXTRA_NETCORE_PAYLOAD);
		//this.removeFromLocalStorage(LocalStorageDataModel.PRACTICE_FIRST_TIME_VISIT);
	//	this.saveUserVisitedRoutes.next(true);
	}

	/**
	 * logout user
	 */

	logoutUser() {
		this.logout.next(true);
	}
	logoutUserWindowClose() {
		this.logoutwithWindoowClose.next(true);
	}
	/**
	 * open in new window file open research
	 * @param categoryName
	 * @param fileId
	 * @param subCategory
	 * @param searchText
	 */

	openInNewWindow(categoryName: string, fileId: string, subCategory: string, searchText: string) {
		let url = `${location.origin}/preview-document/?categoryName=${categoryName}&fileId=${fileId}&subCategory=${subCategory}`;
		if (searchText) {
			url += `&searchText=${encodeURIComponent(searchText)}`
		}
		window.open(url, '_blank', "scrollbars=yes,resizable=yes,top=100,left=200,width=900,height=400")
	}

	/**
	 * add viewed document
	 * @param document_id
	 */

	addViewedDocument(document_id: any) {
		if (!this.viewedDocumentsList.includes(document_id) && this.isLoggedIn()) {
			this.viewedDocumentsList.push(document_id);
			this.viewedDocumentsListSub.next(JSON.parse(JSON.stringify(this.viewedDocumentsList)));
			this.setDataOnLocalStorage(LocalStorageDataModel.VIEWED_DOCUMENTS, JSON.stringify(this.viewedDocumentsList));
		}
	}

	/**
	 * reset viewed document
	 */
	resetViewedDocument() {
		this.removeFromLocalStorage(LocalStorageDataModel.VIEWED_DOCUMENTS);
		this.viewedDocumentsList = [];
		this.viewedDocumentsListSub.next([]);
	}

	/**
	 * make story url
	 * @param data
	 */
	makeStoryUrl(data) {
		let str = `/research/${data.CatUrl}/top-story/${data.FileID}/${data.FileURL || data.Heading}`
		str = window.location.origin + str;
		return str;
	}

	adjustViewHeight(key: string, type: Number, forceFlag?: boolean) {
		if (this.isMobileView() && !forceFlag) {
			return;
		}
		try {
			let selector;
			if (type === HeightAdjustElementType.HTML_ELEMENT) {
				selector = key;
			} else if (type === HeightAdjustElementType.HTML_CLASS) {
				selector = `.${key}`;
			} else if (type === HeightAdjustElementType.HTML_ID) {
				selector = `#${key}`;
			} else {
				selector = key;
			}

			let arr = document.querySelectorAll(selector);
			if (selector && arr && arr.length) {
				let windowHeight = window.innerHeight;
				Array.from(arr).forEach((element: HTMLElement) => {
					let elementTop = element.getBoundingClientRect().top;
					if (elementTop > 0) {
						let remainingHeight = +windowHeight - +elementTop;
						if (remainingHeight > 0) {

							element.style.height = `${remainingHeight - this.getOffsetHeightLength()}px`;
                    console.log('height adjeusted', element.style.height)
						}
					}
				})




			}
		} catch (error) {
			console.log(error)
		}

	}

	getOffsetHeightLength() {
		let value = this.getDataFromLocalStorageWithExpiry('sharedToken', true) || '';
		if (value) {
			return 55;
		} else {
			return 15
		}
	}


	handleResearchFilterPrefill(filterArr, flatFilter, key) {
		filterArr.forEach((res) => {
			let optionData = res.value;
			let ind = flatFilter.options.findIndex((filter) => (filter[flatFilter.value_key || 'id'] === optionData[flatFilter.value_key || 'id']));
			if (ind === -1) {
				flatFilter.options.push(optionData);
			}
		});
	}

	handleResearchMoreFilterPrefill(filterArr, flatFilter, key) {
		filterArr.forEach((res) => {
			let optionData = res;
			let ind = flatFilter.options.findIndex((filter) => (filter.value[flatFilter.value_key || 'id'] === optionData[flatFilter.value_key || 'id']));
			if (ind === -1) {
				let data = {
					label: (flatFilter['label_key'] ? optionData[flatFilter['label_key']] : (optionData.shortName || optionData.name)),
					value: optionData
				}

				flatFilter.options.push(data);
			}
		});
	}

	calculateTotalPages(totalRecords, pageSize) {
		return totalRecords % pageSize == 0 ? parseInt((totalRecords / pageSize).toString()) : parseInt((totalRecords / pageSize).toString()) + 1
	}

	/**
	 * disptach rezise event for auto height adjust
	 */
	dispatchResizeEvent() {
		window.dispatchEvent(new Event('resize'));
	}

	getCookie(cname) {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	/**
	 * set cookie
	 * @param cname   cookie name
	 * @param cvalue  cookie value
	 * @param exdays  expiry days
	 */

	setCookie(cname, cvalue, exhour) {
		var d = new Date();
		d.setTime(d.getTime() + (exhour * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		if (environment.production || environment.beta) {
			if (window.location.hostname.indexOf('localhost') !== -1) {
				document.cookie = cname + "=" + cvalue + ";path=/;expires=" + expires;
			} else {
				document.cookie = cname + "=" + cvalue + ";domain=taxmann.com;path=/;expires=" + expires;
			}
		} else {
			document.cookie = cname + "=" + cvalue + ";domain=taxmann.com;path=/;expires=" + expires;
		}
	}

	/**
	 * delete cookie by cookie name
	 * @param cname
	 */

	deleteCookie(cname) {
		var d = new Date();
		d.setTime(d.getTime() - (1 * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		if (environment.production || environment.beta) {
			if (window.location.hostname.indexOf('localhost') !== -1) {
				document.cookie = cname + "=" + '' + ";" + expires + ";path=/";
			} else {
				document.cookie = cname + "=" + '' + ";" + expires + ";path=/;domain=taxmann.com";
			}
		} else {
			document.cookie = cname + "=" + '' + ";" + expires + ";path=/";
		}
	}

	deleteCookieCtime(cname, ctime) {
		var d = new Date();
		d.setTime(d.getTime() - (ctime * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		if (environment.production || environment.beta) {
			if (window.location.hostname.indexOf('localhost') !== -1) {
				document.cookie = cname + "=" + '' + ";" + expires + ";path=/";
			} else {
				document.cookie = cname + "=" + '' + ";" + expires + ";path=/;domain=taxmann.com";
			}
		} else {
			document.cookie = cname + "=" + '' + ";" + expires + ";path=/";
		}
	}

	/**
	 * get params from query param string
	 * @param name
	 * @param paramString
	 */

	getQueryStringDataParameter(name: string, paramString: string) {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		var results = regex.exec(paramString);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};



	/**
	 * get query params object
	 */
	getQueryStrings() {
		const assoc = {};
		const decode = function (s) { return decodeURIComponent(s.replace(/\+/g, ' ')); };
		const queryString = location.search.substring(1);
		const keyValues = queryString.split('&');

		for (const i in keyValues) {
			if (keyValues[i]) {
				const key = keyValues[i].split('=');
				if (key.length > 1) {
					assoc[decode(key[0])] = decode(key[1]);
				}
			}
		}

		return assoc;
	}
	generateTimeStampToken() {

		let data = new Date();
		return data.getTime()
	}
	getEncryptionKey() {
		this.env = this.setENV();
		return LocalStorageDataModel.EMAIL + environment[this.env].ENCRYPTION_DETAILS.KEY
	}
	convertText(conversion: string, text: string) {
		let key = this.getEncryptionKey()
		const crypto = new TaxmannCryptoJS()
		crypto.setPrivateKey(key);
		if (conversion == "encrypt") {
			return crypto.encrypt(text.trim())
		} else if (conversion == "decrypt"){
			return crypto.decrypt(text.trim())

		} else {
			return text;
		}
	}
	isIPUserLogin(): boolean {
		let isIpUserLoggedin = this.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER);
		let alreadyLoggedIn = this.getDataFromLocalStorage(LocalStorageDataModel.IPUSERLOGIN);
		if (isIpUserLoggedin && isIpUserLoggedin === 'true') {
			if (alreadyLoggedIn && alreadyLoggedIn === 'true') {
				return false;
			}
			else {
				this.setDataOnLocalStorage(LocalStorageDataModel.IPUSERLOGIN, 'true');
				return true;
			}
		} else {
			this.setDataOnLocalStorage(LocalStorageDataModel.IPUSERLOGIN, 'false')
			return false;
		}

	}

	loginAfterMobileVerificationForInActiveUser = new Subject();
	doLogin(flag) {
		this.loginAfterMobileVerificationForInActiveUser.next(flag);
	}
	createTaxPracticeAPIUrl(api_url: string): string {
		this.env = this.setENV();
		return environment[this.env].TAX_PRACTICE_URL + api_url;
	}

	createRegistrationAPIUrl(api_url: string): string {
		this.env = this.setENV();
		return environment[this.env].REGISTER_URL + api_url;
	}

	//setItme to Session Storage
	setItemInSessionStorege(key: string, item: string | object): void {
		if (!_.isEmpty(item) && item instanceof Object) {
			let itemToBeStore = JSON.stringify(item);
			sessionStorage.setItem(key, itemToBeStore);
		} else if (!_.isEmpty(item) && typeof item === 'string') {
			sessionStorage.setItem(key, String(item));
		}
	}

	setItemInSessionStoregeEmpty(key: string, item: string | object): void {
		if (_.isEmpty(item) && item instanceof Object) {
			let itemToBeStore = JSON.stringify(item);
			sessionStorage.setItem(key, itemToBeStore);
		} else if (_.isEmpty(item) && typeof item === 'string') {
			sessionStorage.setItem(key, String(item));
		}
	}

	//get item from Session Storage
	getItemFromSessionStorege(key: string) {
		return sessionStorage.getItem(key);
	}

	//Remove from Session Storage
	removeItemFromSessionStorege(key: string): void {
		sessionStorage.removeItem(key);
	}

	setLastAccessProductBaseUrlByUser(URL: string): void {
		if (this.isLoggedIn()) {
			this.setDataOnLocalStorage(LocalStorageDataModel.LAST_ACCESS_BASE_URL_BY_USER, URL);
		}
	}


	setDataOnLocalStorageWithExpiry(key, value, ttl) {
		const now = new Date()
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
			date: now.toLocaleDateString()
		}
		localStorage.setItem(key, JSON.stringify(item))
	}

	getDataFromLocalStorageWithExpiry(key, expireDaily: boolean = false) {
		const itemStr = localStorage.getItem(key)
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr)
		const now = new Date()
		if (expireDaily) {
			let cdate = now.toLocaleDateString()
			if (cdate != item.date) {
				localStorage.removeItem(key);
				return null;
			} else {
				return item.value;
			}
		} else {
			if (now.getTime() > item.expiry) {
				localStorage.removeItem(key)
				return null
			}
			return item.value
		}
	}

	maintainLastUrlBeforeLogout() {
		if (this.router.url == '/gp/auth/logout' || this.router.url == '/gp/auth/login') {
		} else {
			this.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		}

	}

	/**
	 * initialize script via script name and id
	 * @param scriptId
	 * @param srciptUrl
	 */

	intializeScript(scriptId, srciptUrl) {
		let js;
		const fjs = document.getElementsByTagName('script')[0];
		if (document.getElementById(scriptId)) {
			return;
		}
		js = document.createElement('script');
		js.id = scriptId;
		js.async = true;
		js.src = srciptUrl;
		fjs.parentNode.insertBefore(js, fjs);
	}

	netCoreEventTrack(eventType, payload, utm_source?:string, Email?:string) {
		let email = this.getDataFromLocalStorage(LocalStorageDataModel.EMAIL) || Email;
		if(utm_source){
		if(payload && payload['utm_source']){
	}else{	
		payload['utm_source'] = utm_source;
}
		}
		console.log("payload here of netcore ===========>", payload, email)
		smartech('identify', email);
		smartech('dispatch', eventType, payload);
	}

	getProductType(itemType) {
		if (itemType == BookstoreProduct.BOOK) {
			return 'Book';
		} else if (itemType == BookstoreProduct.DVD) {
			return 'DVD';
		} else if (itemType == BookstoreProduct.EXAMS) {
			return 'EXAMS';
		} else if (itemType == BookstoreProduct.E_B0OK) {
			return 'E_B0OK';
		} else if (itemType == BookstoreProduct.E_JOURNAL) {
			return 'E_JOURNAL';
		} else if (itemType == BookstoreProduct.E_SERVICES) {
			return 'E_SERVICES';
		} else if (itemType == BookstoreProduct.GSP_SERVICE) {
			return 'GSP_SERVICE';
		} else if (itemType == BookstoreProduct.JOURNAL) {
			return 'JOURNAL';
		} else if (itemType == BookstoreProduct.ONLINE_COURSE) {
			return 'ONLINE_COURSE';
		} else if (itemType == BookstoreProduct.VIRTUAL_BOOK) {
			return 'VIRTUAL_BOOK';
		} else if (itemType == BookstoreProduct.VIRTUAL_JOURNAL) {
			return 'VIRTUAL_JOURNAL';
		} else if (itemType == BookstoreProduct.WEBSITE) {
			return 'WEBSITE';
		}
	}

	createUtmObj() {
		let utmObj = {}
		let utm_campaign = this.getDataFromLocalStorage('activeCampaign');
		if (utm_campaign) {
			let utmDataString = this.getDataFromLocalStorage(utm_campaign);
			if(utmDataString ){
				let utmData = JSON.parse(this.getDataFromLocalStorage(utm_campaign));
			utmObj['utm_campaign'] = utmData.utm_campaign;
			utmObj['utm_source'] = utmData.utm_source;
			utmObj['utm_medium'] = utmData.utm_medium;
			}
		}

		return utmObj;
	}

	redirectToReactLogin() {
		window.open('/gp/auth/login', "_self")
	}
	redirectToReactLogout() {
		window.open('/gp/auth/logout', "_self")
	}
	redirectToReactCheckout() {
		window.open('/gp/checkout/payment', "_self")
	}
	redirectToReactRegister() {
		window.open('/gp/auth/register', "_self")
	}
	redirectToReactCart() {
		window.open('/gp/checkout/information', "_self")
	}
	redirectToReactBookstoreCart() {
		window.open('/bookstore/cart', "_self")
	}
	redirectToReactAuthInterests() {
		window.open('/gp/auth/interests', "_self")
	}

	initializeCheckoutData() {
		let checkoutData: CheckoutInformation = {
			checkoutCart: "",
			cartSource: "C",
			billingId: "",
			shippingId: "",
			type: ''
		};

		this.setDataOnLocalStorage("checkoutData", JSON.stringify(checkoutData));
	}


	actionForUnAuthUser(){
		if (!this.isLoggedIn()) {
			this.setDataOnLocalStorage("returnURL", location.pathname);
			window.open( "/gp/auth/login", "_self");
			return false;
		} else {
			return true;
		}
	};
	createEndmileAPITrackObject(event:string,fields:any , emailid?:string){
		let email = this.getDataFromLocalStorage(LocalStorageDataModel.EMAIL) || emailid;
		let payload =
		{
		// 	"username":"user"
		// ,"usertoken":"2347e3f856f87fc315c108d7c76574e53d056fbb",
		// "requesttype":"subscribers",
		"requestmethod":"AddSubscriberToList",
		"details":
		{"emailaddress":email,"event_name":event,"mailinglist":"1","format":"html","confirmed":"yes","customfields":
		{"items":fields
	}}}
//	let payload = {"username":"user","usertoken":"2347e3f856f87fc315c108d7c76574e53d056fbb","requesttype":"subscribers","requestmethod":"AddSubscriberToList","details":{"emailaddress":"anjangos@gmail.com","event_name":"Product viewed","mailinglist":"1","format":"html","confirmed":"yes","customfields":{"items":[{"fieldid":"174","value":"99"},{"fieldid":"73","value":"Finance Act"},{"fieldid":"175","value":"book"},{"fieldid":"61","value":"cid"}]}}}
	   return payload;
	}

	  createSlugByIdName = (inputString: string): string => {
		return inputString
			.toLowerCase()
			.replace(/[^\w\s-]/g, "")
			.replace(/\s+/g, "-")
			.replace(/-+/g, "-")
			.trim();
	};
	
	getPaymentMode(paymentType) {
		if (paymentType == PaymentModes.CHEQUE_DD) {
			return 'CHEQUE_DD'
		} else if (paymentType == PaymentModes.COD) {
			return 'Cash on Delivery'
		} else if (paymentType == PaymentModes.NEFT) {
			return 'NEFT'
		} else if (paymentType == PaymentModes.RAZORPAY) {
			return 'RAZORPAY'
		} else if (paymentType == PaymentModes.RAZORPAY_RECURRING) {
			return 'RAZORPAY_RECURRING'
		} else if (paymentType == PaymentModes.SHOPSE) {
			return 'SHOPSE'
		} else if (paymentType == PaymentModes.WALLET_NO_PAY) {
			return 'Wallet_No_Pay'
		}
	}

	addValidator(formGroup: FormGroup, controlName: string, validationArrName?: any) {
		formGroup.get(controlName).setValidators([validationArrName]);
		formGroup.get(controlName).updateValueAndValidity();
	}

	removeValidator(formGroup: FormGroup, controlName: string) {
		formGroup.get(controlName).setErrors(null);
		formGroup.get(controlName).clearValidators();
		formGroup.get(controlName).updateValueAndValidity();
	}

	 getYoutubeVideoId = (url:string) => {
		const embedMatch = url.match(/\/embed\/([^?]+)/);
		const standardMatch = !embedMatch && url.match(/[?&]v=([^?]+)/);
	  
		const match:any= embedMatch || standardMatch;
		return match && match[1];
	  };
	
	 getIdFromStarting = (stringWithIdInFront: string): string => {
		if (stringWithIdInFront) {
		  const a: any[] = stringWithIdInFront?.split('-');
		  if (a?.length > 0) {
			return a[0];
		  }
		}
		  return '';
	  }
	  
}


