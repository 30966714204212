import {
  ResearchCategoryUrl,
  AAAAccountingCategory,
  AAAAuditingCategory,
  ResearchGroupName,
  AAAFinancialsCategory,
  AAADisclosuresCategory,
} from "./constant";
export const MetaResearchHome = {
  metaTitle:
    "Exclusive Research Platform for Income Tax, GST, International and Corporate Law",
  metaDescription:
    "Get exclusive access to Income Tax, GST, Company law, International Taxation Case-laws, Acts, Rules, News updates, Notifications & Articles only on Taxmann Research. ",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};

export const MetaSetting = {
  metaTitle:
    "Settings | Taxmann",
  metaDescription:
    "Tailor your Taxmann experience on the Settings page. Personalize preferences to load your previous research upon restarting, optimizing your interaction for a seamless and personalized journey.",
  metaKeywords: "Taxmann, Settings Customization, User Preferences, Research Loading",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
  metaRobots: "noindex,nofollow"
}

export const AuditMetaData = {
  metaTitle:
    "Advanced Diploma in International Taxation (ADIT) | Taxmann",
  metaDescription:
    "Advance your international tax career with the prestigious ADIT (Advanced Diploma in International Taxation) Course. Explore the world of cross-border taxation and prepare for your ADIT Course with Taxmann.",
  metaKeywords:"Taxmann, ADIT, Advanced Diploma in International Taxation, International Taxation Course, Certificate Course on International Taxation",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
  metaRobots: "noindex,nofollow"
}

export const AuditPriceMetaData = {
  metaTitle:
    "Advance Diploma in International Taxation (ADIT) Pricing | Taxmann",
  metaDescription:
    "Embark your journey in International Taxation with Taxmann! Access No-cost EMI options and choose from a diverse range of online and offline payment methods.",
  metaKeywords:"Taxmann, ADIT Pricing, ADIT, International Taxation Course Pricing",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
  metaRobots: "noindex,nofollow"
}

export const BudgetFinanceBill = {
  metaTitle: "The Finance Bill 2022 | As Introduced in the Lok Sabha | Taxmann",
  metaDescription:
    "Download/Read the copy of the Finance Bill 2022, as introduced in the Lok Sabha on 1st  February 2022. The Finance Bill 2022 amends various provisions of the Income-tax Act 1961, Goods and Service Tax, Customs, Customs Tariff, Excise, CGST, IGST, UTGST, RBI Act.",
  metaKeywords: "Finance Bill 2022, Direct Tax Amendments, GST Amendments, Budget Amendments, Budget 2022, Budget Session, Finance Bill, Income Tax Act, Union Budget, Nirmala Sitharaman, Budget Finance",
  ogTitle: "The Finance Bill 2022 | As Introduced in the Lok Sabha | Taxmann",
  ogDescription: "Download/Read the copy of the Finance Bill 2022, as introduced in the Lok Sabha on 1st  February 2022. The Finance Bill 2022 amends various provisions of the Income-tax Act 1961, Goods and Service Tax, Customs, Customs Tariff, Excise, CGST, IGST, UTGST, RBI Act.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const BudgetAtAGlance = {
  metaTitle: "Key Features of Budget 2022-23 | Ministry of Finance | Taxmann",
  metaDescription:
    "This document highlights the key features of the Budget 2022-23 as presented by the finance minister. It includes topics like the goals of Amrit Kaal, charts on the Indian economy, PM GatiShakti, education & health initiatives, tax proposals, budget proposals, etc.",
  metaKeywords: "What is Budget at a Glance, Budget at a Glance 22-23, Budget at a Glance News, Key Features of Budget, Budget 2022, Union Budget, Nirmala Sitharaman, Budget Finance",
  ogTitle: "Key Features of Budget 2022-23 | Ministry of Finance | Taxmann",
  ogDescription: "This document highlights the key features of the Budget 2022-23 as presented by the finance minister. It includes topics like the goals of Amrit Kaal, charts on the Indian economy, PM GatiShakti, education & health initiatives, tax proposals, budget proposals, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const BudgetMemorandum = {
  metaTitle: "Memorandum Explaining the Provisions in the Finance Bill 2022 | Taxmann",
  metaDescription:
    "This document discussed the provisions relating to direct & indirect taxes. It is a part of the Union Budget documents, stipulating all the legal amendments required for the changes in taxation proposed by the finance minister.",
  metaKeywords: "What is Memorandum Explaining the Provisions in the Finance Bill, Memorandum",
  ogTitle: "Memorandum Explaining the Provisions in the Finance Bill 2022 | Taxmann",
  ogDescription: "This document discussed the provisions relating to direct & indirect taxes. It is a part of the Union Budget documents, stipulating all the legal amendments required for the changes in taxation proposed by the finance minister.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const BudgetSpeech = {
  metaTitle: "Finance Minister Budget Speech | Union Budget 2022-23 | Taxmann",
  metaDescription:
    "Download/Read the Finance Minister, Nirmala Sitharaman’s Speech for the Budget 2022-23, presented on 1stFebruary 2022, in the Parliament. The speech is divided into two parts. Part A talks about the PM GatiKShakti, Inclusive Developments, etc. Part B talks about the Direct Tax Proposals, Indirect Tax Proposals, etc.",
  metaKeywords: "Budget speech, Finance Minister Speech, Budget Speech 2022, Budget News, Budget speech highlights",
  ogTitle: "Finance Minister Budget Speech | Union Budget 2022-23 | Taxmann",
  ogDescription: "Download/Read the Finance Minister, Nirmala Sitharaman’s Speech for the Budget 2022-23, presented on 1stFebruary 2022, in the Parliament. The speech is divided into two parts. Part A talks about the PM GatiKShakti, Inclusive Developments, etc. Part B talks about the Direct Tax Proposals, Indirect Tax Proposals, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaHome = {
  metaTitle: "Taxmann - Everything on Tax and Corporate Laws of India",
  metaDescription:
    "Taxmann is the most reliable online source for research on income tax, indirect tax & GST, Company Law, IFRS, Ind AS & International Taxation",
  metaKeywords: "Tax and Corporate Law, Taxmann Research, Taxmann Practice, Taxmann Bookstore, Taxmann Academy, Indian tax assessment, research on income tax, indirect tax & GST, Company Law, IFRS, Ind AS & International Taxation.",
  ogTitle: "Taxmann - Everything on Tax and Corporate Laws of India",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaDtlAllAbout = {
  metaTitle:
    "Taxmann Research | Income Tax | All About™ Income Tax Act",
  metaDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to access multiple documents quickly. The entire purpose of All- About™ is to simplify how you work. In other words, choose a Section of the Income-tax Act, and the All-About™ tool will show you all the connected records in a single window, be it Rule(s), Circular(s), Notification(s), Case Law(s) etc.",
  metaKeywords: "income tax in india, income tax rules, income tax updates, income tax notes, All About Income Tax, Income Tax Act, Income Tax Act 1961, Income Tax Slabs, Taxmann Research",
  ogTitle: "Taxmann Research | Income Tax | All About™ Income Tax Act",
  ogDescription:
  "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to access multiple documents quickly. The entire purpose of All -About™ is to simplify how you work. In other words, choose a Section of the Income-tax Act, and the All-About™ tool will show you all the connected records in a single window, be it Rule(s), Circular(s), Notification(s), Case Law(s) etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaCGSTAllAbout = {
  metaTitle:
    "Taxmann's Research | GST | All About™ Central Goods and Services Tax (CGST) Act",
  metaDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to navigate multiple documents quickly. The entire purpose of All-About™ is to simplify how you work. The user can choose a Section of the CGST Act, and the All-About™ tool will show all the connected & annotated records in a single window, be it Rule(s), Form(s), Circular(s), Notification(s) In-force, Case Law(s), etc.",
  metaKeywords:
    "cgst, cgst act, what is cgst, cgst rules, cgst act 2017, cgst amendments",
  ogTitle:
    "Taxmann's Research | GST | All About™ Central Goods and Services Tax (CGST) Act",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaIGSTAllAbout = {
  metaTitle:
    "Taxmann's Research | GST | All About™ Integrated Goods and Services Tax (IGST) Act",
  metaDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to navigate multiple documents quickly. The entire purpose of All-About™ is to simplify how you work. The user can choose a Section of the IGST Act, and the All-About™ tool will show all the connected & annotated records in a single window, be it Rule(s), Form(s), Circular(s), Notification(s) In-force, Case Law(s), etc.",
  metaKeywords:
    "igst, igst act, igst rate, igst scroll status, features of igst",
  ogTitle:
    "Taxmann's Research | GST | All About™ Integrated Goods and Services Tax (IGST) Act",
  ogDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to navigate multiple documents quickly. The entire purpose of All-About™ is to simplify how you work. The user can choose a Section of the IGST Act, and the All-About™ tool will show all the connected & annotated records in a single window, be it Rule(s), Form(s), Circular(s), Notification(s) In-force, Case Law(s), etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaCompGSTAllAbout = {
  metaTitle: "Taxmann's Research | GST | All About™ GST Compensation Cess",
  metaDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to navigate multiple documents quickly. The entire purpose of All-About™ is to simplify how you work. The user can choose a Section of the GST (Compensation to States) Act, and the All-About™ tool will show all the connected & annotated records in a single window, be it Rule(s), Form(s), Circular(s), Notification(s) In-force, Case Law(s), etc.",
  metaKeywords:
    "compensation cess, compensation cess in gst, gst compensation cess, compensation cess meaning, gst compensation cess rates",
  ogTitle: "Taxmann's Research | GST | All About™ GST Compensation Cess",
  ogDescription:
    "Taxmann's trademarked feature – All-About™ links all the primary & secondary source documents, enabling you to navigate multiple documents quickly. The entire purpose of All-About™ is to simplify how you work. The user can choose a Section of the GST (Compensation to States) Act, and the All-About™ tool will show all the connected & annotated records in a single window, be it Rule(s), Form(s), Circular(s), Notification(s) In-force, Case Law(s), etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaCompanyAllAbout = {
  metaTitle: "Taxmann's Research | All About Companies Act | Sectionws, Schedules, and Appendix",
  metaDescription: " Read all about Schedules, Appendix Rules of Companies Act 2013 and more at a single platform. Subscribe to Taxmnn’s Research Now!",
  metaKeywords: "companies act 2013, companies circulars and notifications, rules of companies act, taxmann research, companies acts.",
  ogTitle: "Taxmann's Research | All About Companies Act | Sectionws, Schedules, and Appendix",
  ogDescription: " Read all about Schedules, Appendix Rules of Companies Act 2013 and more at a single platform. Subscribe to Taxmnn’s Research Now!",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaAAAllAboutIndAs = {
  metaTitle: "All about Indian Accounting Standards (Ind As)",
  metaDescription:
    "Indian Accounting standards (IND AS) are converged standards for International Financial Reporting standards. Read full article to know more.",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaAAAllAboutAs = {
  metaTitle: "Accounting Standards - Taxmann",
  metaDescription: "Accounting standards (AS) are written documents and policies that provides principles for recognition, measurement, treatment, presentation and disclosures of accounting transactions in the financial statements. Read here to know more on accounting stanadards objective, applicability.",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaILTAllAbout = {
  metaTitle: "Taxmann's Research | International Tax | All About™ International Taxation",
  metaDescription: "Choose an Article of the Treaty, and the All-About™ tool will show you all the connected records in a single window. It includes OECD/UN Model Treaty, OECD/UN Model Commentary, etc.",
  metaKeywords: "DTAA, OECD Model, UN Model, Tax Treaty, Indo-US Tax, India-Singapore, India-Mauritius Tax, International tax, taxation and corporate laws, laws of taxation",
  ogTitle: "Taxmann's Research | International Tax | All About™ International Taxation",
  ogDescription: "Choose an Article of the Treaty, and the All-About™ tool will show you all the connected records in a single window. It includes OECD/UN Model Treaty, OECD/UN Model Commentary, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export class MetaUpdatesTopStory {
  static getMetaUpdatesTopStory(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle:
              "Taxmann Research | Income Tax | Latest News & Updates | Outpace the Pace of Change",
            metaDescription:
              "Know about all-important news & updates for Income Tax Law in India in three minutes or less with single-line and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxman reports news & updates from 1,000+ authentic & verified sources. You can also view the 'Most Viewed' stories of the week or go through our' Editors Pick', or lastly, access archival news & updates, where you can sort documents using filters like document type, tags, duration, etc.",
            metaKeywords:
              "Latest Income Tax, Income Tax Updates, Income Tax, Income Tax Case Laws, Direct Tax, Income Tax Act, Income Tax Rules, Income Tax Notifications, CBDT, ITAT, OECD",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | Latest News & Updates | Outpace the Pace of Change",
            metaDescription:
              "Know about all-important updates for Goods and Services Tax (GST) in India in three minutes or less with a single-line summary and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxmann reports news & updates from 1,000+ authentic & verified sources. The 'Archives' feature provides access to all reported updates in date chronology. This feature is embedded with several filters such as document type, date, editors' pick, etc., enabling easy sorting.",
            metaKeywords:
              "gst articles, gst, gst newsletter, gst news, gst updates",
            ogTitle:
              "Taxmann's Research | GST | Latest News & Updates | Outpace the Pace of Change",
            ogDescription:
              "Know about all-important updates for Goods and Services Tax (GST) in India in three minutes or less with a single-line summary and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxmann reports news & updates from 1,000+ authentic & verified sources. The 'Archives' feature provides access to all reported updates in date chronology. This feature is embedded with several filters such as document type, date, editors' pick, etc., enabling easy sorting.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST_NEW:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | Latest News & Updates | Outpace the Pace of Change",
            metaDescription:
              "Know about all-important updates for Goods and Services Tax (GST) in India in three minutes or less with a single-line summary and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxmann reports news & updates from 1,000+ authentic & verified sources. The 'Archives' feature provides access to all reported updates in date chronology. This feature is embedded with several filters such as document type, date, editors' pick, etc., enabling easy sorting.",
            metaKeywords:
              "gst articles, gst, gst newsletter, gst news, gst updates",
            ogTitle:
              "Taxmann's Research | GST | Latest News & Updates | Outpace the Pace of Change",
            ogDescription:
              "Know about all-important updates for Goods and Services Tax (GST) in India in three minutes or less with a single-line summary and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxmann reports news & updates from 1,000+ authentic & verified sources. The 'Archives' feature provides access to all reported updates in date chronology. This feature is embedded with several filters such as document type, date, editors' pick, etc., enabling easy sorting.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Company & SEBI Laws| Latest News & Updates",
            metaDescription: "Get the latest updates and news on Companies Act and Security Exchange Board of India on Taxmann research. Everything about SEBI & Companies Rules.",
            metaKeywords: "companies act 2013 updates, sebi update, sebi news, sebi public issues",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann's Research | International Tax | Latest News & Updates",
            metaDescription: "Know about all-important news & updates for International Tax in three minutes or less with single-line and three-line summaries.",
            metaKeywords: "MLI, BEPS, OECD, Multilateral Instruments, Tax Treaties, India-Singapore, DTAA International taxation, international taxation in India, taxation law, international tax system, global taxation",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | Latest News & Updates",
            metaDescription:
              "Know about all-important news & updates for Transfer Pricing Law in India in three minutes or less with single-line and three-line summaries. Taxmann is known for the accuracy & authenticity of the content.",
            metaKeywords:
              "TPO, AMP, Transfer pricing, Transfer Pricing News, Transfer Pricing Updates, taxation updates, taxation news, BEPS news, transfer pricing audit, transfer pricing report, arms-length price",
            ogTitle: "Taxmann's Research | Transfer Pricing | Latest News & Updates",
            ogDescription: "Know about all-important news & updates for Transfer Pricing Law in India in three minutes or less with single-line and three-line summaries. Taxmann is known for the accuracy & authenticity of the content.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle:"Taxmann's Research | Insolvency & Bankruptcy Code (IBC) | Latest IBC, DRT, IBBI Updates",
            metaDescription:"Know about all-important news & updates for Insolvency & Bankruptcy Code (IBC) in India in three minutes or less with single-line and three-line summaries. Taxmann is known for the accuracy & authenticity of the content. Taxman reports news & updates from 1,000+ authentic & verified sources. You can also view the 'Most Viewed' stories of the week or go through our' Editors Pick', or lastly, access archival news & updates, where you can sort documents using filters like document type, tags, duration, etc.",
            metaKeywords:"insolvency and bankruptcy code bare act, Corporate applicant under the insolvency and bankruptcy code includes, insolvency and bankruptcy code law, insolvency and bankruptcy code notification, section 12a of insolvency and bankruptcy code, IBC code 2016, insolvency and bankruptcy code 2020, insolvency and bankruptcy code bill, insolvency and bankruptcy code 2021, insolvency professional, sarfaesi act, ibbi, IBC",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle:
              "Taxmann’s Research | FEMA Banking & Insurance | Latest News & Updates",
            metaDescription:
              "Know about the latest news on FEMA, FCRA, FDI, PMLA, NBFC, securitisation, and debt recovery laws, plus banking and insurance regulations. Get concise summaries in three minutes or less from Taxmann, your trusted source for accurate and authentic content. Drawing from over 1,000 verified sources, we deliver the essential financial regulatory updates you need.",
            metaKeywords:
              "foreign exchange news, FEMA updates, supreme court judgment, banking law, corporate law, insurance law, FCRA, FDI, PMLA, NBFC, FEMA act, banking regulation act, taxmann research",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle: "Competition law in India | Taxmann",
            metaDescription:
              "Get Online database on competition act, competition law articles, competition act 2002, mrtp act 1969 and much more. ",
            metaKeywords:
              "mrtp act 1969, competition act 2002, competition law, competition act, competition law articles",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle:
              "Accounts & Audit: Online Database on Ind AS, AS & CARO - Taxmann",
            metaDescription:
              "Get online database on Indian Accounting Standards, Accounting Standards, CARO, ICDS, ICAI opinions, industry specific financials, case laws & accounting guidance.",
            metaKeywords:
              "accounting standards, indian accounting standards, accounting and auditing, gaap accounting standards, list of indian accounting standards, 32 accounting standards list, gaap",
          },
        ];
        break;
      case ResearchCategoryUrl.INDIAN_ACTS:
        list = [
          {
            metaTitle:
              "Indian Acts & Rules, Repealed Acts & Rules, Forms & Notifications - Taxmann",
            metaDescription:
              "Get online database on Indian acts & rules including repealed acts & rules, section-wise compare &  check analysis for detailed legal research.",
            metaKeywords:
              "repealed acts, acts of parliament, indian laws and acts, bare acts, indian acts",
          },
        ];
        break;
      default:
        list = [
          {
            metaTitle:
            "Taxmann Research – The Legal Repository on Tax | Corporate Law | Accounting that India Trusts",
          metaDescription:
            "Taxmann's Research, with its AI-powered search engine tailored for legal research in tax, corporate law, and accounting, is highly regarded across India. It simplifies legal research by offering an extensive database that instantly delivers relevant documents. Key features include integrated and annotated texts of laws with their legislative histories, a 'Three-Line-Digest', and 'Head Note'/'Digest' for case laws, enhanced by a backward and forward case linking system for comprehensive judicial analysis. Additionally, its 'Check Viability' tool aids in evaluating the reliability of case laws, setting a new standard for efficiency and reliability in legal research.",
          metaKeywords: "Taxmann Research Module, GST Online, GST Laws, Income Tax Laws, Transfer Pricing, International Tax, Corporate Laws, Companies Act, IBC Code, SEBI, Ind AS, Accounting Standards",
                  },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesCaselaws {
  static getMetaUpdatesCaselaws(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | Case Laws with Integration | Headnotes | Case-Digest",
            metaDescription:
              "Taxmann understands the importance of finding the right Case Law. Therefore, we provide multiple filters & coverage of Case Laws from all Courts. To help you go through multiple cases in a short period, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case, 'headnote', which provides you with a relevant summary of the facts & ratio, and finally 'case-digest' to get more details about the facts & ratio laid down. Apart from the above, Taxmann hosts the largest database of Judgments from courts/tribunals. It also features a 'check-viability tool' to search relevant cases further and a 'see more tool' to search & identify similar cases.",
            metaKeywords: "Taxmann Research, income tax law, Income Tax Case Laws, ITAT Case Laws, ITAT Rulings, ITAT Orders, Taxman, ITD, Income Tax Case Summary",
            ogTitle: "Taxmann Research | Income Tax | Case Laws with Integration | Headnotes | Case-Digest",
            ogDescription:
            "Taxmann understands the importance of finding the right Case Law. Therefore, we provide multiple filters & coverage of Case Laws from all Courts. To help you go through multiple cases in a short period, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case, 'headnote', which provides you with a relevant summary of the facts & ratio, and finally 'case-digest' to get more details about the facts & ratio laid down. Apart from the above, Taxmann hosts the largest database of Judgments from courts/tribunals. It also features a 'check-viability tool' to search relevant cases further and a 'see more tool' to search & identify similar cases.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle: "GST Case Laws Update !",
            metaDescription:
              "This post covers all recent case laws on GST. Download for Free now !",
            metaKeywords: "",
            ogTitle: "GST Case Laws Update !",
            ogDescription:
              "This post covers all recent case laws on GST. Download for Free now !",
          },
        ];
        break;
      case ResearchCategoryUrl.GST_NEW:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | 31,000+ Case Laws with Integration, Headnotes/Case-Digest",
            metaDescription:
              "Systematic research is the key to forming an opinion on complex tax issues. At Taxmann, we understand the importance of finding the right Case Law for such research. For this very reason, we provide multiple filters & coverage of 31,000+ Case Laws from all Authorities/Courts. To help you go through multiple cases quickly, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case.  Our 'headnotes' gives the relevant facts & observations and the conclusion held by the authority/Court. The important cases are also accompanied by 'held part' with relevant para no., which enables you to directly use the held part for your deliverables without reading the entire judgment again and again. Taxmann hosts the largest database of Judgements from Courts/Tribunals. We have provided more than 15 filters which enables you to find the correct Case Law. The 'see more tool' provides referred cases, articles on this case, cases where it referred in other cases, etc., enabling you to judge the status of the case.",
            metaKeywords:
              "gst case laws, case law, gst case laws digest, supreme court case laws on gst, case laws on gst",
            ogTitle:
              "Taxmann's Research | GST | 31,000+ Case Laws with Integration, Headnotes/Case-Digest",
            ogDescription:
              "Systematic research is the key to forming an opinion on complex tax issues. At Taxmann, we understand the importance of finding the right Case Law for such research. For this very reason, we provide multiple filters & coverage of 31,000+ Case Laws from all Authorities/Courts. To help you go through multiple cases quickly, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case.  Our 'headnotes' gives the relevant facts & observations and the conclusion held by the authority/Court. The important cases are also accompanied by 'held part' with relevant para no., which enables you to directly use the held part for your deliverables without reading the entire judgment again and again. Taxmann hosts the largest database of Judgements from Courts/Tribunals. We have provided more than 15 filters which enables you to find the correct Case Law. The 'see more tool' provides referred cases, articles on this case, cases where it referred in other cases, etc., enabling you to judge the status of the case.",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann's Research | Excise | Service Tax | VAT | Customs | CENVAT | 65,000+ Case Laws with Integration, Headnotes/Case-Digest",
            metaDescription:
              "Systematic research is the key to forming an opinion on complex tax issues. At Taxmann, we understand the importance of finding the right Case Law. For this very reason, we provide multiple filters & coverage of 65,000+ Case Laws from all Authorities/Courts. To help you go through multiple cases quickly, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case. Taxmann's 'headnotes' gives the relevant facts & observations and the conclusion held by the authority/Court. The important cases are also accompanied by 'held part' with relevant para no., which enables you to directly use the held part for your deliverables without reading the entire judgment again and again. Taxmann hosts the largest database of Judgements from Courts/Tribunals. We have provided more than 15 filters which enables you to find the correct Case Law. The 'see more tool' provides referred cases, articles on this case, cases where it referred in other cases, etc., enabling you to judge the status of the case.",
            metaKeywords: "cestat, new cestat, cenvat, vat, gstat, gst tribunal, Customs Authority for Advance Rulings, Settlement Commission, Commissioner Appeals, GST",
            ogTitle: "Taxmann's Research | Excise | Service Tax | VAT | Customs | CENVAT | 65,000+ Case Laws with Integration, Headnotes/Case-Digest",
            ogDescription:
              "Systematic research is the key to forming an opinion on complex tax issues. At Taxmann, we understand the importance of finding the right Case Law. For this very reason, we provide multiple filters & coverage of 65,000+ Case Laws from all Authorities/Courts. To help you go through multiple cases quickly, we provide a 'three-line digest' which gives you a glimpse of the ratio of the case. Taxmann's 'headnotes' gives the relevant facts & observations and the conclusion held by the authority/Court. The important cases are also accompanied by 'held part' with relevant para no., which enables you to directly use the held part for your deliverables without reading the entire judgment again and again. Taxmann hosts the largest database of Judgements from Courts/Tribunals. We have provided more than 15 filters which enables you to find the correct Case Law. The 'see more tool' provides referred cases, articles on this case, cases where it referred in other cases, etc., enabling you to judge the status of the case.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Companies & SEBI Laws |11000+ Case Laws",
            metaDescription: "Get the updated information on more than 11000 case laws of SEBI and Companies Act 2013 at Taxmann’s Research platform. Subscribe now!",
            metaKeywords: "sebi case laws, company law cases, companies act & SEBi case laws, case laws on companies act 2013, nclat",
            ogTitle: "Taxmann's Research | Companies & SEBI Laws |11000+ Case Laws",
            ogDescription: "Get the updated information on more than 11000 case laws of SEBI and Companies Act 2013 at Taxmann’s Research platform. Subscribe now!",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann's Research | International Tax | 3,600+ Case Laws including Foreign Cases",
            metaDescription: "Taxmann understands the importance of finding the right Case Law. For this reason, we provide multiple filters & coverage of Case Laws from all Courts, including foreign Cases. ",
            metaKeywords: "ECJ, EU Case Law, Federal Court Tax Cases, US Circuit Court Cases, Tax case laws, International Tax Case Laws",
            ogTitle: "Taxmann's Research | International Tax | 3,600+ Case Laws including Foreign Cases",
            ogDescription: "Taxmann understands the importance of finding the right Case Law. For this reason, we provide multiple filters & coverage of Case Laws from all Courts, including foreign Cases.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 4,200+ Case Laws",
            metaDescription: "Access 4,200+ Case Laws on Transfer Pricing. The Case Laws come with a 'three-line digest' which gives you a glimpse of the ratio of the case, 'headnote', which provides you with a relevant summary of the facts & ratio, and finally, 'case-digest' to get more details about the facts & ratio laid down.",
            metaKeywords: "Transfer pricing, transfer pricing case laws, transfer pricing cases, recent case laws on transfer pricing in India, transfer pricing case study, safe harbour rules, TP Adjustments, Transfer Pricing Comparable, Associate Enterprises",
            ogTitle: "Taxmann's Research | Transfer Pricing | 4,200+ Case Laws",
            ogDescription: "Access 4,200+ Case Laws on Transfer Pricing. The Case Laws come with a 'three-line digest' which gives you a glimpse of the ratio of the case, 'headnote', which provides you with a relevant summary of the facts & ratio, and finally, 'case-digest' to get more details about the facts & ratio laid down.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann's Research | IBC Case Laws | 6,250+ Cases with Integration, Headnotes/Case-Digest",
            metaDescription:"Get comprehensive coverage of Indian insolvency laws with Taxmann's research platform. Access 6,250+ IBC case laws, including landmark rulings, case studies, and judgments, as well as articles and legal content on IBC, the Companies Act, SARFAESI, debt recovery, and bankruptcy case laws. Explore the insolvency resolution process, NCLT and NCLAT cases, and more. Subscribe today to stay up-to-date on IBC case law.",
            metaKeywords: "IBC case laws, IBC cases, insolvency case laws, case laws on IBC 2016, IBC Case Laws Update, section 95 IBC case laws, section 66 IBC case laws, section 7 of IBC case laws, section 9 IBC case laws, section 14 IBC case laws, section 10a IBC case laws",
            ogTitle: "Taxmann's Research | IBC Case Laws | 6,250+ Cases with Integration, Headnotes/Case-Digest",
            ogDescription: "Get comprehensive coverage of Indian insolvency laws with Taxmann's research platform. Access 6,250+ IBC case laws, including landmark rulings, case studies, and judgments, as well as articles and legal content on IBC, the Companies Act, SARFAESI, debt recovery, and bankruptcy case laws. Explore the insolvency resolution process, NCLT and NCLAT cases, and more. Subscribe today to stay up-to-date on IBC case law.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 2600+ Case Laws",
            metaDescription: "Discover 2600+ FEMA Case Laws with Taxmann, featuring in-depth coverage from all courts & tribunals, advanced filters for quick searching, three-line digests for efficient case review, and held part summaries with paragraph numbers for critical cases, saving you time and effort.",
            metaKeywords: "Case study on FEMA Act 1999, FEMA cases, FEMA cases India, FEMA case laws, FEMA case studies India, Banking case Laws in India, landmark cases on life insurance, Insurance case laws",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 2600+ Case Laws",
            ogDescription: "Discover 2600+ FEMA Case Laws with Taxmann, featuring in-depth coverage from all courts & tribunals, advanced filters for quick searching, three-line digests for efficient case review, and held part summaries with paragraph numbers for critical cases, saving you time and effort.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle: "Competition Law - Case Laws - Taxmann",
            metaDescription:
              "Get detailed case laws on Competition law, Competition act 2002, Competition Commission of India, Duties, Powers and Functions of Commission, Competition Advocacy, Duties of director general, Finance, accounts and audit, Competition Appellate tribunal.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Account & Audit ( Industry Guidence ) - Case Laws",
            metaDescription: "Account & Audit Case Laws.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesActs {
  static getMetaUpdatesActs(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax Act | 15+ Allied Acts – Annotated | Integrated | Authentic | Amended | Updated",
            metaDescription:
              "Taxmann's Income Tax & other allied Acts are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Act. It also includes allied Acts referred to in the Income-tax Act. The two unique features of Taxmann's Acts are the 'See More' & 'List of Amendments' features. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles, etc. The 'List of Amendment' feature provides a tabular chart to show the amendments carried out in different years, with an option to compare the previous & updated law. You can also view the Income-tax Act & other allied Acts either Section-wise or Chapter-wise.",
            metaKeywords: "Income Tax Sections, Income Tax Act 1961, Features of Income Tax, Income Tax Act, Income Tax Changes, Income Tax Amendments, Finance Act, Benami Property, Income Tax Act Section-wise, Income Tax Act PDF, section 54 of income tax act, section 24 of income tax act, Taxmann Research",
            ogTitle: "Taxmann Research | Income Tax Act | 15+ Allied Acts – Annotated | Integrated | Authentic | Amended | Updated",
            ogDescription:
            "Taxmann's Income Tax & other allied Acts are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Act. It also includes allied Acts referred to in the Income-tax Act. The two unique features of Taxmann's Acts are the 'See More' & 'List of Amendments' features. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles, etc. The 'List of Amendment' feature provides a tabular chart to show the amendments carried out in different years, with an option to compare the previous & updated law. You can also view the Income-tax Act & other allied Acts either Section-wise or Chapter-wise.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle:
              "Taxmann's Research | 37+ GST Acts | CGST | SGST | IGST | UTGST | GST Compensation Cess | Annotated/Integrated, Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | GST Compensation Cess Acts are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Section provides a link to the effective date, relevant Rules and Forms. You can also view the GST Acts either Section-wise or Chapter-wise.",
            metaKeywords:
              "gst act, gst act 2017, gst amendment act, gst bare act, section 17(5) of gst act, gst act 2019",
            ogTitle:
              "Taxmann's Research | 37+ GST Acts | CGST | SGST | IGST | UTGST | GST Compensation Cess | Annotated/Integrated, Authentic, Amended & Updated",
            ogDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | GST Compensation Cess Acts are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Section provides a link to the effective date, relevant Rules and Forms. You can also view the GST Acts either Section-wise or Chapter-wise.",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann's Research | Excise Act | Service Tax Act | VAT Act | Annotated/Integrated, Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's Excise Acts, Service Tax, VAT Acts, etc., are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Section provides the link to the effective date. The 'see more' feature annexed with Sections provides cases which are referred, Notifications and Circulars on the Section, articles on this Section, etc. This enables you to judge the status of Section. It also contains Customs Act, erstwhile Central Excise Law, Service Tax law, State VAT Act, etc. You can also view the respective Acts either Section-wise or Chapter-wise.",
            metaKeywords: "vat act, Service Tax Act, excise act, central excise act, karnataka excise act",
            ogTitle: "Taxmann's Research | Excise Act | Service Tax Act | VAT Act | Annotated/Integrated, Authentic, Amended & Updated",
            ogDescription: "Taxmann's Excise Acts, Service Tax, VAT Acts, etc., are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Section provides the link to the effective date. The 'see more' feature annexed with Sections provides cases which are referred, Notifications and Circulars on the Section, articles on this Section, etc. This enables you to judge the status of Section. It also contains Customs Act, erstwhile Central Excise Law, Service Tax law, State VAT Act, etc. You can also view the respective Acts either Section-wise or Chapter-wise.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Companies Act 2013  | 530+ Sections",
            metaDescription: "A complete guide on companies act 2013 including more than 530 sections. Subscribe to Taxmann’s Research now and get all updated acts.",
            metaKeywords: "companies act, companies act 2013, section 185 of companies act 2013, schedule iii of companies act 2013, section 186 of companies act 2013.",
            ogTitle: "Taxmann's Research | Companies Act 2013  | 530+ Sections",
            ogDescription: "A complete guide on companies act 2013 including more than 530 sections. Subscribe to Taxmann’s Research now and get all updated acts.",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann’s Research | International Tax | 20+ Acts",
            metaDescription: "This section features 20+ Acts relevant to International Taxation in India, including Black Money, Income Tax Act, Equalisation Levy, ICDS, Benami, STT, etc.",
            metaKeywords: "International tax acts, international tax india, international tax experts, international tax system, Income-tax Act, Finance Act, Benami Property, Income Tax Act Section-wise, Income Tax Act PDF",
            ogTitle: "Taxmann’s Research | International Tax | 20+ Acts",
            ogDescription: "This section features 20+ Acts relevant to International Taxation in India, including Black Money, Income Tax Act, Equalisation Levy, ICDS, Benami, STT, etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 35+ Acts ",
            metaDescription: "This section features 35+ Acts relevant to Transfer Pricing in India, including Black Money, Income Tax Act, Equalisation Levy, ICDS, Benami, STT, etc. These Acts are updated in real-time, meaning you always get the most amended, updated & authentic text of the law.",
            metaKeywords: "Transfer pricing acts, transfer pricing section, Income-tax Act, Finance Act, Benami Property, Income Tax Act Section-wise, Income Tax Act PDF",
            ogTitle: "Taxmann's Research | Transfer Pricing | 35+ Acts ",
            ogDescription: "This section features 35+ Acts relevant to Transfer Pricing in India, including Black Money, Income Tax Act, Equalisation Levy, ICDS, Benami, STT, etc. These Acts are updated in real-time, meaning you always get the most amended, updated & authentic text of the law.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 15+ Acts",
            metaDescription:"Taxmann's IBC & other allied Acts are updated in real-time, meaning you always get the most amended, updated & authentic IBC. It also includes allied Acts referred to in the IBC. The two unique features of Taxmann's Acts are the 'See More' & 'List of Amendments' features. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles, etc. The 'List of Amendment' feature provides a tabular chart to show the amendments carried out in different years, with an option to compare the previous & updated law. You can also view the IBC & other allied Acts either Section-wise or Chapter-wise.",
            metaKeywords: "IBC code 2016, insolvency and bankruptcy code 2016, section 7 of IBC, section 29a of IBC, section 14 of IBC, IBC act 2016, IBC bare act",
            ogTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 15+ Acts",
            ogDescription: "Taxmann's IBC & other allied Acts are updated in real-time, meaning you always get the most amended, updated & authentic IBC. It also includes allied Acts referred to in the IBC. The two unique features of Taxmann's Acts are the 'See More' & 'List of Amendments' features. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles, etc. The 'List of Amendment' feature provides a tabular chart to show the amendments carried out in different years, with an option to compare the previous & updated law. You can also view the IBC & other allied Acts either Section-wise or Chapter-wise.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann Research | FEMA Banking & Insurance | 2500+ Acts",
            metaDescription: "Explore the latest amendments and updates in FEMA, IFSC, PMLA, Banking Laws, Insurance Laws, Securitization Law, FCRA, and more in real-time. Taxmann's Acts section offers unique features like 'See More' and 'List of Amendments' to enhance your understanding. Access related case laws, circulars, notifications, and articles with 'See More,' while 'List of Amendments' offers a year-wise comparison of changes. View Acts by sections or chapters for seamless navigation.",
            metaKeywords: "FEMA Act 1999, provisions of FEMA Act 1999, objective of FEMA Act 1999, Foreign Exchange Management Act, foreign exchange management act 1999, foreign exchange management act 2000, FERA Act, fera act 1973, FERA Act 1974, foreign exchange regulation act, foreign exchange regulation act 1973, foreign exchange management act regulations",
            ogTitle: "Taxmann Research | FEMA Banking & Insurance | 2500+ Acts",
            ogDescription: "Explore the latest amendments and updates in FEMA, IFSC, PMLA, Banking Laws, Insurance Laws, Securitization Law, FCRA, and more in real-time. Taxmann's Acts section offers unique features like 'See More' and 'List of Amendments' to enhance your understanding. Access related case laws, circulars, notifications, and articles with 'See More,' while 'List of Amendments' offers a year-wise comparison of changes. View Acts by sections or chapters for seamless navigation.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle: "Competition Law - Competition Acts",
            metaDescription: "Competition Law, Competition Acts",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Account & Audit - Acts | Taxmann",
            metaDescription: "Account & Audit Acts.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.INDIAN_ACTS:
        list = [
          {
            metaTitle: "Indian Acts - Acts | Taxmann",
            metaDescription: "Account & Audit Acts.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case "repealed":
        list = [
          {
            metaTitle: "Repealed Acts - Indian Acts -Taxmann",
            metaDescription:
              "Get detailed information on list of repealed central acts in India like companies act 1956, central excise act 1944, Customs Act, 1962, sales tax act 1956, arms act.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];

        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesRules {
  static getMetaUpdatesRules(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax Rules | 125+ Allied Rules – Annotated | Integrated | Authentic | Amended | Updated",
            metaDescription:
              "Taxmann's Income Tax Rules & other allied Rules are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Rules. It also includes allied Rules referred to in the Income-tax Act. The unique feature of Taxmann's Rules is the 'See More' feature. The 'See More' feature integrates relevant/related case laws, circulars, notifications, articles, etc. You can also view the Income-tax Rules & other allied Rules, either Section-wise or Chapter-wise.",
            metaKeywords: "Income Tax Rules 1962, Rule and Regulations, Income Tax Rules, ICDS, Income Tax Forms, ITR, Income Tax Returns, Black Money, Faceless Assessment, Taxmann Research",
            ogTitle: "Taxmann Research | Income Tax Rules | 125+ Allied Rules – Annotated | Integrated | Authentic | Amended | Updated",
            ogDescription:
            "Taxmann's Income Tax Rules & other allied Rules are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Rules. It also includes allied Rules referred to in the Income-tax Act. The unique feature of Taxmann's Rules is the 'See More' feature. The 'See More' feature integrates relevant/related case laws, circulars, notifications, articles, etc. You can also view the Income-tax Rules & other allied Rules, either Section-wise or Chapter-wise.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle:
              "Taxmann’s Research | 42+ GST Rules | CGST | SGST | IGST | UTGST | GST Compensation Cess | Annotated/Integrated, Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | GST Compensation Cess Rules are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Rule provides a link to the effective date and related Section(s).",
            metaKeywords:
              "gst rules, cgst rules 2017, gst rules and regulations, gst act and rules, taxmann gst",
            ogTitle:
              "Taxmann’s Research | 42+ GST Rules | CGST | SGST | IGST | UTGST | GST Compensation Cess | Annotated/Integrated, Authentic, Amended & Updated",
            ogDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | GST Compensation Cess Rules are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The heading of each Rule provides a link to the effective date and related Section(s).",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann's Research | Excise Rules | Service Tax Rules | VAT Rules | Annotated/Integrated, Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's Excise, Service Tax, Customs, VAT Rules are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The 'see more' feature annexed with Rules provides cases which referred, Notifications and Circulars on the Rules, articles on this Rule, etc. This enables you to judge the status of Rule. It contains Rules relating to Customs Act, erstwhile Central Excise Law, Service Tax law, State VAT Acts, etc.",
            metaKeywords: "kerala vat rules, service tax rules, vat rule, excise rules, central excise and service tax rules",
            ogTitle: "Taxmann's Research | Excise Rules | Service Tax Rules | VAT Rules | Annotated/Integrated, Authentic, Amended & Updated",
            ogDescription: "Taxmann's Excise, Service Tax, Customs, VAT Rules are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials. The 'see more' feature annexed with Rules provides cases which referred, Notifications and Circulars on the Rules, articles on this Rule, etc. This enables you to judge the status of Rule. It contains Rules relating to Customs Act, erstwhile Central Excise Law, Service Tax law, State VAT Acts, etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Companies Act 2013 | All Rules & Regulation",
            metaDescription: "Get the most authentic, amended & updated rules for companies act 2013 on Taxmann’s Research platform. Get a 7 days free trial now!",
            metaKeywords: "company law rules, companies act 2013 rules, company rules 2013, company court rules, section 143 of companies act 2013, section 134 of companies act 2013",
            ogTitle: "Taxmann's Research | Companies Act 2013 | All Rules & Regulation",
            ogDescription: "Get the most authentic, amended & updated rules for companies act 2013 on Taxmann’s Research platform. Get a 7 days free trial now!",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann’s Research | International Tax | 113+ Rules",
            metaDescription: "This section features 113+ Rules relevant to International Taxation in India, including Black Money, Income Tax Rules, Equalisation Levy, ICDS, Benami, STT, etc.",
            metaKeywords: "International tax rules, international tax laws, Income Tax Rules, ICDS, Income Tax Forms, ITR, Income Tax Returns, Black Money, Faceless Assessment, Income Tax Rule-wise, Income Tax Rules PDF",
            ogTitle: "Taxmann’s Research | International Tax | 113+ Rules",
            ogDescription: "This section features 113+ Rules relevant to International Taxation in India, including Black Money, Income Tax Rules, Equalisation Levy, ICDS, Benami, STT, etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 115+ Rules",
            metaDescription:
              "This section features 115+ Rules relevant to International Taxation in India, including Black Money, Income Tax Rules, Equalisation Levy, ICDS, Benami, STT, etc. These Rules are updated in real-time, meaning you always get the most amended, updated & authentic text of the law.",
            metaKeywords: "transfer pricing rules, general rules for transfer pricing, Income tax rules, rule 2a of income tax act, Transfer Pricing Regulations",
            ogTitle: "Taxmann's Research | Transfer Pricing | 115+ Rules",
            ogDescription: "This section features 115+ Rules relevant to International Taxation in India, including Black Money, Income Tax Rules, Equalisation Levy, ICDS, Benami, STT, etc. These Rules are updated in real-time, meaning you always get the most amended, updated & authentic text of the law.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann’s Research | Insolvency and Bankruptcy Code (IBC) | 45+ Rules & Regulations",
            metaDescription:"Taxmann's IBC Rules & Regulations are updated in real-time, meaning you always get the most amended, updated & authentic IBC Rules & Regulations. The unique feature of Taxmann's IBC Rules & Regulations is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles etc.",
            metaKeywords: "IBC rules 2016, insolvency and bankruptcy code 2016 rules, ibbi regulations, rule 11 of IBC, rule 7 of IBC, rule 4 of IBC, ibbi rules, IBC regulations 2017, regulations under IBC, IBC regulations 2018,  rule 5 of IBC, section 7 rule 4 of IBC",
            ogTitle: "Taxmann’s Research | Insolvency and Bankruptcy Code (IBC) | 45+ Rules & Regulations",
            ogDescription: "Taxmann's IBC Rules & Regulations are updated in real-time, meaning you always get the most amended, updated & authentic IBC Rules & Regulations. The unique feature of Taxmann's IBC Rules & Regulations is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Circulars, Notifications, Articles etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 4700+ Rules/Regulations",
            metaDescription:"Explore the latest Rules & Regulations in Banking, IFSC, NBFC, FEMA, Insurance Laws, PMLA, Securitization & Debt Recovery Laws, and more in real-time. Taxmann's Rules & Regulations section offers unique features like 'See More' to enhance your understanding. Access related case laws, circulars, notifications, and articles with 'See More'.",
            metaKeywords: "banking rules and regulations, banking rules and regulations 2022, banking rules and regulations in India, FEMA Regulations 2000, FEMA regulations, insurance rules 1939, Rule 58 i of Insurance Rules 1939, Insurance Rules and regulations in India, prevention of money laundering rules 2005",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 4700+ Rules/Regulations",
            ogDescription: "Explore the latest Rules & Regulations in Banking, IFSC, NBFC, FEMA, Insurance Laws, PMLA, Securitization & Debt Recovery Laws, and more in real-time. Taxmann's Rules & Regulations section offers unique features like 'See More' to enhance your understanding. Access related case laws, circulars, notifications, and articles with 'See More'.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle:
              "Competition Commission of India Regulations 2009 | Rules",
            metaDescription: "Competition Commission of India Regulations 2009",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Account & Audit - Rules | Taxmann",
            metaDescription: "Account & Audit Rules.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.INDIAN_ACTS:
        list = [
          {
            metaTitle: "Indian Acts - Rules | Taxmann",
            metaDescription: "Indian Acts Rules",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case "repealed":
        list = [
          {
            metaTitle: "Repealed Rules Indian Acts - Taxmann",
            metaDescription:
              "Get detailed information on list of repealed central acts & rules in India like accounting standards, cenvat credit rules 2004, central excise rules 2002, arms rules, VAT rules.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesForms {
  static getMetaUpdatesForms(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | 300+ Forms – Authentic | Amended | Updated",
            metaDescription:
              "This section features 300+ Income-tax Forms, 8 Challans, 3 Income-tax Returns, 45 Models and Drafts and 6 other Forms. Income-tax Forms list out all ITRs & Forms prescribed in the Income-tax Rules. Other Forms list the various Forms referred to in Income-tax Rules & allied Rules mentioned in the Income-tax Rules. Models & Drafts include 45 samples of documents that are relevant to your day-to-day practice.",
            metaKeywords: "ITR Forms, Income Tax Form, Challan Form, Income Tax Challan Form, CBDT, Income Tax Return Forms, Income Tax Challan, ITR 1, ITR 3, Sahaj, Taxmann Research",
            ogTitle: "Taxmann Research | Income Tax | 300+ Forms – Authentic | Amended | Updated",
            ogDescription:
            "This section features 300+ Income-tax Forms, 8 Challans, 3 Income-tax Returns, 45 Models and Drafts and 6 other Forms. Income-tax Forms list out all ITRs & Forms prescribed in the Income-tax Rules. Other Forms list the various Forms referred to in Income-tax Rules & allied Rules mentioned in the Income-tax Rules. Models & Drafts include 45 samples of documents that are relevant to your day-to-day practice.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle:
              "Taxmann’s Research | 330+ GST Forms | CGST | SGST | UTGST | Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | Forms are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials.",
            metaKeywords:
              "gst form, form gst cmp-01, gst reg-05, gst full form",
            ogTitle:
              "Taxmann’s Research | 330+ GST Forms | CGST | SGST | UTGST | Authentic, Amended & Updated",
            ogDescription:
              "Taxmann's CGST | SGST | IGST | UTGST | Forms are updated in real-time, meaning you always get the most amended, updated & authentic statutory materials.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Companies Act 2013 | Forms",
            metaDescription: "Get updated forms “Banning Of Unregulated Deposit Schemes Rules, 2020” prescribed under Companies Act, 2013 and  SEBI Regulations. ",
            metaKeywords: "annual filing forms under companies act 2013, Company Law Forms, banning of unregulated deposit schemes ordinance 2019, has a specific format prescribed by companies act.",
            ogTitle: "Taxmann's Research | Companies Act 2013 | Forms",
            ogDescription: "Get updated forms “Banning Of Unregulated Deposit Schemes Rules, 2020” prescribed under Companies Act, 2013 and  SEBI Regulations.",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann’s Research | International Tax | 350+ Forms",
            metaDescription: "This section features 280+ Income-tax Forms, 5+ other Forms, 5+ Challans, 9+ Income-tax Returns, 45+ Models and Drafts. Income-tax Forms lists out all ITRs & Forms prescribed in the Income-tax Rules.",
            metaKeywords: "International taxation forms, international tax forms, international taxation icai, types of international taxation, Income Tax Forms, Income Tax eForms, CBDT, Income Tax Return Forms, Income Tax Challan, ITR-1, ITR-3, Sahaj, Sugam, Income Tax Forms PDF",
            ogTitle: "Taxmann’s Research | International Tax | 350+ Forms",
            ogDescription: "This section features 280+ Income-tax Forms, 5+ other Forms, 5+ Challans, 9+ Income-tax Returns, 45+ Models and Drafts. Income-tax Forms lists out all ITRs & Forms prescribed in the Income-tax Rules.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 365+ Forms",
            metaDescription: "This section features 295+ Income-tax Forms, 5+ other Forms, 8+ Challans, 10+ Income-tax Returns, and 45+ Models and Drafts. Income-tax Forms list all ITRs & Forms prescribed in the Income-tax Rules.",
            metaKeywords: "transfer pricing forms, it form, ITR-4 SUGAM, ITR-1 SAHAJ, income tax forms",
            ogTitle: "Taxmann's Research | Transfer Pricing | 365+ Forms",
            ogDescription: "This section features 295+ Income-tax Forms, 5+ other Forms, 8+ Challans, 10+ Income-tax Returns, and 45+ Models and Drafts. Income-tax Forms list all ITRs & Forms prescribed in the Income-tax Rules.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 115+ Forms",
            metaDescription:"This section features IBC, IBBI & other related Forms prescribed in the Insolvency & Bankruptcy Code & its Rules & Regulations",
            metaKeywords: "ibbi claim forms, forms under IBC, IBC 2016 forms, forms under IBC 2016, forms under IBC 2016, IBC 2016 forms, Form D IBC, form 6 of IBC,  form 5 IBC, form c ibc, ibc forms, cirp forms ibbi",
            ogTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 115+ Forms",
            ogDescription: "This section features IBC, IBBI & other related Forms prescribed in the Insolvency & Bankruptcy Code & its Rules & Regulations",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 470+ Forms",
            metaDescription: "This section features FEMA Forms. Foreign Exchange Management Act Forms lists the various Forms referred to in FEMA & FERA Rules. Models & Drafts include 10+ samples of documents relevant to your day-to-day practice.",
            metaKeywords: "FEMA declaration form, FEMA declaration form 1, FEMA declaration form under section 10(5), FEMA declaration form, form a2, banking regulation act 1949, features of banking regulation act 1949, provisions of banking regulation act 1949, form fc-1 under section 380, form FC 1, form fc-2, Form FC-3, Form FC-4, form 990 schedule i instructions, Form 990 instructions, Form 990 Schedule B instructions, Form 990 Schedule R instructions, Form 990 Schedule R instructions 2021, form fc-10, form fc-10 fcra",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 470+ Forms",
            ogDescription: "This section features FEMA Forms. Foreign Exchange Management Act Forms lists the various Forms referred to in FEMA & FERA Rules. Models & Drafts include 10+ samples of documents relevant to your day-to-day practice.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle: "Competition Law | Competition Act, 2009 Forms",
            metaDescription:
              "Competition Act 2009 Forms - Get updated Competition Appellate Tribunal Form and Fee for filing an appeal and fee for compensation applications 2009, Competition commission of India form and time of preparation of annual report, form of annual statement of accounts, etc. ",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Account & Audit - Forms | Taxmann",
            metaDescription: "Account & Audit Forms.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.INDIAN_ACTS:
        list = [
          {
            metaTitle: "Indian Acts - Forms | Taxmann",
            metaDescription:
              "Indian tax forms are used to document information in compliance with the Income Tax Act of 1961 and in accordance with the Income Tax Rules, which govern the process of filing income tax returns in India.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesCirNot {
  static getMetaUpdatesCirNot(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | 16,000+ Circulars & Notifications – Annotated | Authentic | Amended | Updated",
            metaDescription:
              "This section includes 4,200+ Circulars & 12,200+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT). They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications. The unique feature of Taxmann Circulars & Notifications is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Sections, Articles, etc. You can use various filters to find the relevant Circular or Notification.",
            metaKeywords: "Income Tax Circulars, Income Tax Notifications, CBDT, CBDT Press Release, Ministry of Finance",
            ogTitle: "Taxmann Research | Income Tax | 16,000+ Circulars & Notifications – Annotated | Authentic | Amended | Updated",
            ogDescription:
            "This section includes 4,200+ Circulars & 12,200+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT). They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications. The unique feature of Taxmann Circulars & Notifications is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Sections, Articles, etc. You can use various filters to find the relevant Circular or Notification.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST:
        list = [
          {
            metaTitle:
              "Taxmann’s Research | GST | 16,000+ Circulars & Notifications | Annotated, Authentic, Amended & Updated",
            metaDescription:
              "Taxmann's Circulars & Notifications provides up to date 12,700+ Notifications, 3,200+ Circulars, Clarifications, Press Releases, Removal of Difficulty Orders, etc., on the CGST, SGST, IGST, UTGST, and Compensation Cess. The unique tool, i.e., 'Notifications in Force', provides you with a list of effective Notifications currently in force, enabling you to do faster research.",
            metaKeywords:
              "gst notification, gst latest notifications, gst updates 2021, gst circular, tds on gst circular",
            ogTitle:
              "Taxmann’s Research | GST | 16,000+ Circulars & Notifications | Annotated, Authentic, Amended & Updated",
            ogDescription:
              "Taxmann's Circulars & Notifications provides up to date 12,700+ Notifications, 3,200+ Circulars, Clarifications, Press Releases, Removal of Difficulty Orders, etc., on the CGST, SGST, IGST, UTGST, and Compensation Cess. The unique tool, i.e., 'Notifications in Force', provides you with a list of effective Notifications currently in force, enabling you to do faster research.",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann’s Research | Excise | Service Tax | VAT | 5,500+ Circulars & Notifications | Annotated, Authentic, Amended & Updated",
            metaDescription:
              "This Section provides all up-to-date Notifications, Clarifications, Press Releases, Public notices, etc., relating to Customs Laws, Erstwhile Excise law, Service Tax laws, Foreign Trade Policy, etc. It also features Tariff and Non-Tariff Notifications. The unique tool, i.e., 'Notifications in Force', provides you with a list of effective Notifications currently in force, enabling you to do faster research.",
            metaKeywords: "vat circular, vat notification, excise duty notification, service tax circulars, excise notifications",
            ogTitle: "Taxmann’s Research | Excise | Service Tax | VAT | 5,500+ Circulars & Notifications | Annotated, Authentic, Amended & Updated",
            ogDescription: "This Section provides all up-to-date Notifications, Clarifications, Press Releases, Public notices, etc., relating to Customs Laws, Erstwhile Excise law, Service Tax laws, Foreign Trade Policy, etc. It also features Tariff and Non-Tariff Notifications. The unique tool, i.e., 'Notifications in Force', provides you with a list of effective Notifications currently in force, enabling you to do faster research.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann's Research | Companies and SEBI | 8500+ Circulars & Notifications",
            metaDescription: "Get detailed information on Corporate law circulars & notifications issued by Ministry of corporate affairs in the official gazette, MCA circulars & notifications on companies act 2013, notified sections of companies amendment act 2017.",
            metaKeywords: "mca notifications on companies act 2013, sebi notification, sebi circular, sebi master circular, sebi guidelines",
            ogTitle: "Taxmann's Research | Companies and SEBI | 8500+ Circulars & Notifications",
            ogDescription: "Get detailed information on Corporate law circulars & notifications issued by Ministry of corporate affairs in the official gazette, MCA circulars & notifications on companies act 2013, notified sections of companies amendment act 2017.",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann’s Research | International Tax | 15,700+ Circulars & Notifications",
            metaDescription: "This section includes 3,800+ Circulars & 11,900+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT).",
            metaKeywords: "International tax notice, international tax notification, international tax news and cases, international tax news today, Income Tax Circulars, Income Tax Notifications, CBDT, CBDT Press Release, Ministry of Finance",
            ogTitle: "Taxmann’s Research | International Tax | 15,700+ Circulars & Notifications",
            ogDescription: "This section includes 3,800+ Circulars & 11,900+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT).",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 16,000+ Circulars & Notifications",
            metaDescription:
              "This section includes 3,900+ Circulars & 12,000+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT). They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications.",
            metaKeywords: "Transfer pricing notifications, CBDT circulars, cbdt press release, Transfer Pricing Circulars",
            ogTitle: "Taxmann's Research | Transfer Pricing | 16,000+ Circulars & Notifications",
            ogDescription: "This section includes 3,900+ Circulars & 12,000+ Notifications referred to in the Income Tax Act/Rules & other allied Acts/Rules or issued by the Central Board of Direct Taxes (CBDT). They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann’s Research | Insolvency and Bankruptcy Code (IBC) | 800+ Circulars & Notifications",
            metaDescription:"This section provides 380+ Circulars & 400+ Notifications on the Insolvency & Bankruptcy Code (IBC). You can view the IBC Circulars & Notifications year-wise. All Circulars & Notifications are hyperlinked and interconnected with the IBC.",
            metaKeywords: "CIRP 7 Form IBBI, IBBI Public Announcement, pib India press release, ibbi notification 2018, IBBI Case Status, IBC updates, IBC notification,  IBC suspension notification",
            ogTitle: "Taxmann’s Research | Insolvency and Bankruptcy Code (IBC) | 800+ Circulars & Notifications",
            ogDescription: "This section provides 380+ Circulars & 400+ Notifications on the Insolvency & Bankruptcy Code (IBC). You can view the IBC Circulars & Notifications year-wise. All Circulars & Notifications are hyperlinked and interconnected with the IBC.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 9500+ Circulars & Notifications",
            metaDescription:"This section includes Circulars & Notifications referred to in the FEMA. They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications. The unique feature of Taxmann's Circulars & Notifications is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Sections, Articles, etc. You can use various filters to find the relevant Circular or Notification.",
            metaKeywords: "FEMA notifications, FEMA notifications, FEMA circulars, ifsca circulars, rbi press release, rbi currency press release, RBI notification, rbi latest news, rbi news latest, rbi news latest, FDI circulars",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 9500+ Circulars & Notifications",
            ogDescription: "This section includes Circulars & Notifications referred to in the FEMA. They are updated in real-time, meaning you always get the most amended, updated & authentic Income-tax Circulars & Notifications. The unique feature of Taxmann's Circulars & Notifications is the 'See More' feature. The 'See More' feature integrates relevant/related Case Laws, Sections, Articles, etc. You can use various filters to find the relevant Circular or Notification.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle:
              "Competition Law | Competition Commission of India Circulars & Notifications",
            metaDescription:
              "Get updated circulars and notifications on Competition commission of India, Section 54 of the competition act 2002, CCI notifications 2017, MCA notifications, Section 5 of the competition act notification.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Circulars & Notifications - Account & Audits - Taxmann",
            metaDescription: "Account & Audit Circulars & Notifications.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesFaq {
  static getMetaUpdatesFaq(categoryId, subgroup) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.GST_NEW:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | GST Council Meetings | CBEC FAQs | CBEC Taxpayers Series | PPTs | Reports and Others",
            metaDescription:
              "This Section systematically covers all meeting-wise GST Council documents, CBEC FAQs, CBEC Taxpayer Series, CBEC PPTs, and Reports & Others. It also covers the GST Council Meeting minutes, Agendas, Agenda Notes, etc. It guides taxpayers' and professionals.",
            metaKeywords:
              "cbic gst, gst cbec, gst council meeting, gst council meeting update",
            ogTitle:
              "Taxmann's Research | GST | GST Council Meetings | CBEC FAQs | CBEC Taxpayers Series | PPTs | Reports and Others",
            ogDescription:
              "This Section systematically covers all meeting-wise GST Council documents, CBEC FAQs, CBEC Taxpayer Series, CBEC PPTs, and Reports & Others. It also covers the GST Council Meeting minutes, Agendas, Agenda Notes, etc. It guides taxpayers' and professionals.",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        switch (subgroup) {
          case ResearchGroupName.BEPS_ILT:
            list = [
              {
                metaTitle: "Taxmann's Research | International Tax | BEPS | 15 Action Points",
                metaDescription: "Get access to the 15 action points of BEPS created by OECD better to manage the issue of base erosion & profit shifting. BEPS indicates the tax avoidance plans used by multinational enterprises to reduce tax bases in a country.",
                metaKeywords: "International tax faqs, international tax explanatory system, Digital economy, CFC rules, interest deduction, Base erosion and profit shifting, BEPS, what is beps, BEPS article 1, BEPS article 2, BEPS article 3, BEPS article 4, BEPS article 5, BEPS article 6, BEPS article 7, BEPS article 8, BEPS article 9, BEPS article 10, BEPS article 11, BEPS article 12, BEPS article 13, BEPS article 14, BEPS article 15, Digital economy, Hybrid mismatch arrangements, CFC Rules, Controlled Foreign Company Rules, interest deduction, treaty abuse, PE status, permanent establishment status, transfer pricing, data analysis, mandatory disclosure, Transfer pricing documentation, dispute resolution, multilateral instruments, OECD BEPS, About BEPS, meaning of BEPS, BEPS Action plan, 15 action points of BEPS, BEPS Action points",
                ogTitle: "Taxmann's Research | International Tax | BEPS | 15 Action Points",
                ogDescription: "Get access to the 15 action points of BEPS created by OECD better to manage the issue of base erosion & profit shifting. BEPS indicates the tax avoidance plans used by multinational enterprises to reduce tax bases in a country.",
              },
            ];
            break;
          case ResearchGroupName.MLI:
            list = [
              {
                metaTitle: "Taxmann’s Research | International Tax | Multilateral Instrument (MLI)",
                metaDescription: "Multilateral Instrument (MLI) helps the fight against Base Erosion and Profit Shifting (BEPS) by implementing tax treaty-related measures. Access all the documents related to MLI",
                metaKeywords: "International tax MLI, Faq's on multilateral instrument, BEPS Action plan 15, Multilateral Instrument, Multilateral Instrument Notified with OECD,  Explanatory Statement to Multilateral Instrument, List of Signatories Parties to MLI, India's Position on MLI, FAQs on MLI, Guidance for Development of Synthesised Texts",
                ogTitle: "Taxmann’s Research | International Tax | Multilateral Instrument (MLI)",
                ogDescription: "Multilateral Instrument (MLI) helps the fight against Base Erosion and Profit Shifting (BEPS) by implementing tax treaty-related measures. Access all the documents related to MLI",
              },
            ];
            break;
          default:
            list = [
              {
                metaDescription: "",
                metaKeywords: "",
              },
            ];
            break;
        }

        break;
      case ResearchCategoryUrl.TP:
        switch (subgroup) {
          case ResearchGroupName.BEPS_ILT:
            list = [
              {
                metaTitle:
                  "Taxmann's Research | Transfer Pricing | BEPS | 15 Action Points",
                metaDescription:
                  "Get access to the 15 action points of BEPS created by OECD better to manage the issue of base erosion & profit shifting. BEPS indicates the tax avoidance plans used by multinational enterprises to reduce tax bases in a country.",
                metaKeywords: "beps transfer pricing, beps, cfc rules, base erosion and profit shifting",
                ogTitle: "Taxmann's Research | Transfer Pricing | BEPS | 15 Action Points",
                ogDescription: "Get access to the 15 action points of BEPS created by OECD better to manage the issue of base erosion & profit shifting. BEPS indicates the tax avoidance plans used by multinational enterprises to reduce tax bases in a country.",
              },
            ];
            break;
          case ResearchGroupName.MLI:
            list = [
              {
                metaTitle:
                  "Taxmann's Research | Transfer Pricing | Multilateral Instrument (MLI)",
                metaDescription:
                  "Multilateral Instrument (MLI) helps the fight against Base Erosion and Profit Shifting (BEPS) by implementing tax treaty-related measures. Access all the documents related to MLI, including MLI as notified by India, List of signatories, India's position on MLI, etc.",
                metaKeywords: "MLI, OECD multilateral instrument, Multilateral Instrument, Signatories Parties to MLI",
                ogTitle: "Taxmann's Research | Transfer Pricing | Multilateral Instrument (MLI)",
                ogDescription: "Multilateral Instrument (MLI) helps the fight against Base Erosion and Profit Shifting (BEPS) by implementing tax treaty-related measures. Access all the documents related to MLI, including MLI as notified by India, List of signatories, India's position on MLI, etc.",
              },
            ];
            break;
          default:
            list = [
              {
                metaDescription: "",
                metaKeywords: "",
              },
            ];
            break;
        }
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle:"Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | FAQs",
            metaDescription:"Explore Taxmann's Research FAQs on Insolvency and Bankruptcy Code (IBC). It provides insights and expert guidance to navigate the complexities of insolvency laws and bankruptcy procedures in India.",
            metaKeywords: "ibc faqs, CIRP process under IBC 2016, liquidation process under ibc, the corporate debtor under ibc, Corporate debtor under IBC 2016, financial creditor under ibc, CIRP under IBC, cirp process under ibc",
            ogTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | FAQs",
            ogDescription: "Explore Taxmann's Research FAQs on Insolvency and Bankruptcy Code (IBC). It provides insights and expert guidance to navigate the complexities of insolvency laws and bankruptcy procedures in India.",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export const MetaDtlFinanceAct = {
  metaTitle: "Taxmann Research | Income Tax | Finance Act – Authentic Documents from the Ministry of Finance",
  metaDescription:
    "The Finance Act includes the financial changes brought by the Central Government, presented by the Finance Minister each year. Taxmann's Finance Acts consist of a repository of all Finance Acts from 1961. It incorporates various amended & annotated sections of different parts of the income tax laws. You can also view the Finance Act either Year-wise or Chapter-wise.",
  metaKeywords: "Finance Act, Income Tax Act, Finance Act Latest, Budget, Ministry of Finance, Finance Minister, Finance Act PDF",
  ogTitle: "Taxmann Research | Income Tax | Finance Act – Authentic Documents from the Ministry of Finance",
  ogDescription:
  "The Finance Act includes the financial changes brought by the Central Government, presented by the Finance Minister each year. Taxmann's Finance Acts consist of a repository of all Finance Acts from 1961. It incorporates various amended & annotated sections of different parts of the income tax laws. You can also view the Finance Act either Year-wise or Chapter-wise.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaDtlFinanceBill = {
  metaTitle: "Taxmann Research | Income Tax | Finance Bill – Authentic Documents from the Ministry of Finance",
  metaDescription:
    "Taxmann's Finance Bill covers everything you need to decipher the changes the various Finance Bills proposed. It includes the budget highlights, the Finance Minister's speech, the actual Finance Bill presented in the Parliament, the Memorandum, which explains the provisions of the Finance Bill, and the Notes on Clauses.",
  metaKeywords: "finance bill 2023, finance bill 2023 pdf, Finance Bill, Parliament, Memorandum, Notes on Clauses, Union Budget, Budget, Taxmann Research, Finance Minister Speech",
  ogTitle: "Taxmann Research | Income Tax | Finance Bill – Authentic Documents from the Ministry of Finance",
  ogDescription:
    "Taxmann's Finance Bill covers everything you need to decipher the changes proposed by the various Finance Bills. It includes the budget highlights, the Finance Minister's speech, the actual Finance Bill presented in the Parliament, the Memorandum, which explains the provisions of the Finance Bill, and lastly, the Notes on Clauses.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaIndianActFinanceBill = {
  metaTitle: "Finance Bill - Indian Acts - Taxmann",
  metaDescription:
    "The Finance Bill is a part of the Union Budget, stipulating all the legal amendments required for the changes in taxation proposed by the Finance Minister.  Get the Budget Speech, Finance Bill, Memorandum, Notes on Clauses for each year in this article. ",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaIndianActOrdinance = {
  metaTitle: "Ordinance - Indian Acts - Taxmann",
  metaDescription:
    "Ordinances are laws that are promulgated by the President of India (Indian Parliament) on the recommendation of the Union Cabinet, which will have the same effect as an Act of Parliament.",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};


export const MetaCompliance = {
  metaTitle: "e-TDS Returns | F.Y. 2023-2024 | Single and Multi-User | Taxmann Compliance",
  metaDescription:
    "Taxmann’s e-TDS Returns is an automated solution for TDS/TCS compliances with ease of import of data from excel, with Unlimited deductors/companies, TRACES interface, TDS Calculation, Challan from OLTAS, etc.",
  metaKeywords: "How to File TDS Return, How to Calculate TDS on Salary, Who is Liable to Deduct TDS, How to Pay TDS Online, TDS Payment, TDS Refund, TDS TRACES, TDS Software, tds return, tds return due date, how to file tds return, tds return filing, Taxmann Software, TDS on Salary, TDS Rate Chart, TDS on Purchase of Goods, TDS Interest, TDS Deduction, TDS on Sale of Property, TDS on Cash Withdrawl, 194J TDS, TDS 281 Challan, 194N TDS, 194C TDS, 194A TDS, Form 16, Form 16 Download, Form 16 Download from TRACES, Revised TDS Returns, TDS Certificate, Section 195 of Income Tax Act, Section 194J, Section 194C, Section 206AB, Form 27Q, Form 16A, 281 Challan, TCS Return Due Date, Generate FVU File for TDS Returns, etds, TDS, etds Software, Tds filing Software, TCS, etds Software by Taxmann, etds Software for 2023-24, etds Software by Vinod Singhania, etds Software by Singhania, etds Returns by Taxmann, etds Returns for Financial year 2023-24, TDS Filing software for old regime, TDS Filing software for new regime, CBDT, TDS Forms, TCS forms, taxmann TDS software, TDS Compliance, TDS Certificates",
  ogTitle: "e-TDS Returns | F.Y. 2023-2024 | Single and Multi-User | Taxmann Compliance",
  ogDescription: "Taxmann’s e-TDS Returns is an automated solution for TDS/TCS compliances with ease of import of data from excel, with Unlimited deductors/companies, TRACES interface, TDS Calculation, Challan from OLTAS, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaIndianActActofParliament = {
  metaTitle: "Parliament Acts - Indian Acts - Taxmann",
  metaDescription:
    "Get a detailed list of all Indian laws and acts passed by Indian Parliament.",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaDtlCBDTonFinanceAct = {
  metaTitle: "Taxmann Research | Income Tax | CBDT Explanatory Notes on Finance Acts",
  metaDescription:
    "The CBDT generally releases a circular to explain the rationale behind an amendment made to the Income-tax Act 1961 by the Finance Acts. This feature of the research platform allows you to access these circulars year-wise, subject-wise, and section-wise. Just select a section to view the reasoning given by the CBDT behind all amendments made to that section since 1961.",
  metaKeywords:
    "CBDT, Income Tax Circular, finance act 2022, cbdt circular, cbdt circular no 7/2022, finance act applicable for which financial year, annual finance act, memorandum explaining finance bill 2023, finance act 2018",
  ogTitle: "Taxmann Research | Income Tax | CBDT Explanatory Notes on Finance Acts",
  ogDescription:
    "The CBDT generally releases a circular to explain the rationale behind an amendment made by the Finance Acts to the Income-tax Act, 1961. This feature of the research platform allows you to access these circulars year-wise, subject-wise, and section-wise. Just select a section to view the reasoning given by the CBDT behind all amendments made to that section since 1961.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaDtlDTC = {
  metaTitle: "Taxmann Research | Income Tax | Direct Tax Code",
  metaDescription:
    "The central government initiated an exercise to replace the Income Tax Act 1961 with an integrated Direct Taxes Code (DTC). Through DTC, the Government aimed to simplify the structure of direct tax laws in India into a single legislation. The first draft of the Direct Taxes Code Bill was released on August 12, 2009, and then a Revised Discussion Paper (RDP) in 2010. DTC 2010 was introduced in Parliament, and the Government formed a Standing Committee of Finance (SCF) to discuss it with various stakeholders. The SCF submitted its report to Parliament in 2012. The Government considered the SCF's recommendations and a 'revised' version of DTC was released in 2014. However, it lapsed when the NDA government came to power that year. This feature of Research gives you access to the various versions of the Direct Tax Code (including DTC 2009, DTC 2010, and DTC Reports), which can be viewed section-wise.",
  metaKeywords: "Direct Tax Code, Income Tax Act, direct tax code upsc, direct tax code 2016, direct tax code 2015, direct tax code 2019 draft, dtc full form in income tax, direct tax pdf, DTC, Direct Tax Code, DTC 2009, DTC 2010, DTC Reports, Discussion Paper DTC",
  ogTitle: "Taxmann Research | Income Tax | Direct Tax Code",
  ogDescription:
    "The Central Government initiated an exercise to replace Income-tax Act, 1961 with an integrated Direct Taxes Code (DTC). Through DTC, the Government aimed to simplify the structure of direct tax laws in India into a single legislation. The first draft of the Direct Taxes Code Bill was released on August 12, 2009, and then a Revised Discussion Paper (RDP) in 2010. DTC 2010 was introduced in Parliament, and the Government formed a Standing Committee of Finance (SCF) to discuss it with various stakeholders. The SCF submitted its report to Parliament in 2012. The Government took into account the recommendations of the SCF, and a 'revised' version of DTC was released in 2014. However, it lapsed when the NDA government came to power that year. This feature of Research gives you access to the various versions of the Direct Tax Code (including DTC 2009, DTC 2010, and DTC Reports), which can be viewed section-wise.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export class MetaUpdatesReports {
  static getMetaUpdatesReports(categoryId, reportType) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | 45+ Reports",
            metaDescription:
              "From time to time, the Government constitutes various committees to suggest the course of action to deal with obsolete provisions and complex situations. These committees submit their recommendations through comprehensive reports. These reports meticulously explain the existing conditions and recommend the changes in taxation laws. This feature of the research platform gives you access to 50+ reports published by various committees.",
            metaKeywords: "guidance note on report under section 115jb of the income-tax act 1961, income tax tax audit report due date, income tax audit report, ais report income tax, remand report in income tax, clause 44 of tax audit report, income tax reports",
            ogTitle: "Taxmann Research | Income Tax | 45+ Reports",
            ogDescription:
              "From time to time, the Government constitutes various committees to suggest the course of action to deal with obsolete provisions and complex situations. These committees submit their recommendations through comprehensive reports. These reports meticulously explain the existing situations and recommend the changes in taxation laws. This feature of the research platform gives you access to 50+ reports published by various committees.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        if (reportType === 1) {
          list = [
            {
              metaTitle: "Taxmann Research | Company Law Committee | 360+ Reports",
              metaDescription: "The Company Law Committee was constituted by the Ministry of Corporate Affairs in September, 2019. Get the latest updates on company law committee reports. ",
              metaKeywords: "sebi annual report, company reports, sebi report, money laundering report",
              ogTitle: "Taxmann Research | Company Law Committee | 360+ Reports",
              ogDescription: "The Company Law Committee was constituted by the Ministry of Corporate Affairs in September, 2019. Get the latest updates on company law committee reports. ",
            },
          ];
        } else {
          list = [
            {
              metaTitle: "Taxmann Research | Company Law | 340+ Informal Guidelines",
              metaDescription: "Get the detailed information on informal guidance in relation to welfare schemes for employees, SEBI informal guidance scheme 2003, SEBI informal guidance on insider training.",
              metaKeywords: "informal guidance sebi, sebi guidelines, sebi informal guidance scheme 2003",
              ogTitle: "Taxmann Research | Company Law | 340+ Informal Guidelines",
              ogDescription: "Get the detailed information on informal guidance in relation to welfare schemes for employees, SEBI informal guidance scheme 2003, SEBI informal guidance on insider training.",
            },
          ];
        }
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "ITaxmann’s Research | International Tax | 50+ Reports",
            metaDescription: "This feature of the research platform gives you access to 50+ reports on Taxing Virtual Currencies, GAAR, Digital Transactions, Employee Transactions, APA, etc.",
            metaKeywords: "International tax reports, international tax reporting requirements, international tax law reports,  Foreign TP Reports, GAAR Report, OECD Reports, Digital Transaction, Employee Benefits, Indian GAAR, Australian GAAR, UK GAAR, UK: Consultation Document on Anti-Avoidance, UK - HMRC'S GAAR Guidance, UN Digital Economy Report 2019, Report on Taxation of Digital Economy, Report on Taxation of E-Commerce, Singapore Tax Guide on E-Commerce Transactions, Electronic Sales Suppression a Threat to Tax Revenues, IRAS Guide on Equity Remuneration Incentive Scheme, IRAS Guide On Tax Treatment of Employee Stock Options, Singapore Tax Guide for Tax on Software Payments, HMRC Guidance on Statutory Residence Test, UN Handbook on selected Issues in Administration of Double Tax Treaties For Developing Countries, Global Anti-Base Erosion Rules for implementation of 15 percent global minimum tax, Taxing Virtual Currencies:, OECD - Prevention of Tax Treaty Abuse – Third Peer Review Report on Treaty Shopping, 2010 Report on the Attribution of Profits to Permanent Establishments, Model Rules for Reporting by Platform Operators with respect to Sellers in the Sharing and Gig Economy, Second peer review report on Treaty Shopping, Guidance on the Implementation of Country-by-Country Reporting: BEPS Action 13, Transfer Pricing, Corresponding Adjustments and the Mutual Agreement Procedure, The Taxation of Income Derived from the Leasing of Industrial, Commercial or Scientific Equipment, The Taxation of Income Derived from the Leasing of Containers, Thin Capitalisation",
            ogTitle: "Taxmann’s Research | International Tax | 50+ Reports",
            ogDescription: "This feature of the research platform gives you access to 50+ reports on Taxing Virtual Currencies, GAAR, Digital Transactions, Employee Transactions, APA, etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 65+ Reports",
            metaDescription:
              "From time to time, the OECD and UN suggest a course of action to deal with obsolete provisions and complex situations. These recommendations are issued in the form of comprehensive reports. These reports meticulously explain the existing situations and recommend changes in taxation laws.",
            metaKeywords: "Transfer pricing reporting, transfer pricing report, transfer pricing documentation, advance pricing agreement",
            ogTitle: "Taxmann's Research | Transfer Pricing | 65+ Reports",
            ogDescription: "From time to time, the OECD and UN suggest a course of action to deal with obsolete provisions and complex situations. These recommendations are issued in the form of comprehensive reports. These reports meticulously explain the existing situations and recommend changes in taxation laws.",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesArticles {
  static getMetaUpdatesArticles(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle:
              "Taxmann Research | Income Tax | 9500+ Articles – Guidance of Experts for the Experts",
            metaDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 9,500+ articles/opinions on the Income Tax Act & other Allied Acts.",
            metaKeywords: "articles on income tax, income tax sections, income tax updates, tax articles, income tax articles, income tax sections list, income tax sections, income tax act sections, income tax updates",
            ogTitle:
              "Taxmann Research | Income Tax | 9500+ Articles – Guidance of Experts for the Experts",
            ogDescription:
            "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 9,500+ articles/opinions on the Income Tax Act & other Allied Acts.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST_NEW:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | 1,200+ Articles | Guidance of Experts for the Experts",
            metaDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,200+ exclusive articles/opinions on GST.",
            metaKeywords:
              "gst article, gst council article, taxmann gst, article on gst bill, gst full form",
            ogTitle:
              "Taxmann's Research | GST | 1,200+ Articles | Guidance of Experts for the Experts",
            ogDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,200+ exclusive articles/opinions on GST.",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann’s Research | Excise | Service Tax | VAT | 1,900+Articles",
            metaDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,900+ exclusive articles/opinions on Indirect-tax laws.",
            metaKeywords: "Articles on vat, service tax notifications, Excise duty articles, excise act",
            ogTitle: "Taxmann’s Research | Excise | Service Tax | VAT | 1,900+Articles",
            ogDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,900+ exclusive articles/opinions on Indirect-tax laws.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann Research | Company Laws| 4300+ Articles",
            metaDescription: "Get a detailed database of companies' acts and read the latest amendments of company laws and rules. Start your 7 days free trial now!",
            metaKeywords: "company articles, articles on corporate law in india, articles on company law",
            ogTitle: "Taxmann Research | Company Laws| 4300+ Articles",
            ogDescription: "Get a detailed database of companies' acts and read the latest amendments of company laws and rules. Start your 7 days free trial now!",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann's Research | International Tax | 1,650+ Articles",
            metaDescription: "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,650+ articles/opinions on concepts/topics of International Tax",
            metaKeywords: "International tax articles, international tax journal articles, international tax information, international tax law information, Income Tax Articles, Tax Articles, Direct Tax Articles, Income Tax Opinions, Income Tax Advice",
            ogTitle: "Taxmann's Research | International Tax | 1,650+ Articles",
            ogDescription: "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,650+ articles/opinions on concepts/topics of International Tax",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 650+ Articles",
            metaDescription:
              "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,650+ articles/opinions on concepts/topics of Transfer Pricing.",
            metaKeywords: "Transfer pricing articles, section 44ab, comparability analysis transfer pricing, Associated Enterprises, Digital Economy",
            ogTitle: "Taxmann's Research | Transfer Pricing | 650+ Articles",
            ogDescription: "Navigate the grey areas of the laws with multiple articles/opinions from industry leaders. Read more than 1,650+ articles/opinions on concepts/topics of Transfer Pricing.",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 575+ Articles",
            metaDescription:"Navigate the grey areas of the Insolvency and Bankruptcy Code (IBC) with multiple articles/opinions from industry leaders. Read over 575 exclusive articles/opinions on the Insolvency and Bankruptcy Code.",
            metaKeywords: "Insolvency and Bankruptcy Code Bare Act, Insolvency and Bankruptcy Code book, Bankruptcy and Insolvency Act India, articles on insolvency and bankruptcy code 2016, insolvency law in India, insolvency resolution process, IBC articles",
            ogTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 575+ Articles",
            ogDescription: "Navigate the grey areas of the Insolvency and Bankruptcy Code (IBC) with multiple articles/opinions from industry leaders. Read over 575 exclusive articles/opinions on the Insolvency and Bankruptcy Code.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 730+ Articles",
            metaDescription:"Unlock insights and guidance from industry leaders on navigating the complex areas of the law with Taxmann's collection of more than 730+ FEMA articles and opinions. Start your free trial now to access this valuable resource.",
            metaKeywords: "FEMA, FEMA articles, FEMA India, overseas direct investment,  section 138 of negotiable instrument act, rbi news, rbi news today",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 730+ Articles",
            ogDescription: "Unlock insights and guidance from industry leaders on navigating the complex areas of the law with Taxmann's collection of more than 730+ FEMA articles and opinions. Start your free trial now to access this valuable resource.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle:
              "Articles on Competition Law in India | Competition Act, 2002",
            metaDescription:
              "Get Competition law updates through articles on competition law in india, research topics in competition law, current issues in competition law, research paper on competition law, articles on competition law & issues.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Accounts & Audit - articles - Taxmann",
            metaDescription:
              "Get articles written by renowned authors on various latest topics of GST- GST Returns, Composition levy,  E-way bill, Controversial issues arising under GST.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaUpdatesTreaties {
  static getMetaUpdatesTreaties(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | Tax Treaties",
            metaDescription:
              "India has signed 99+ Double Taxation Avoidance Agreements (DTAAs) and 20+ Tax Information Exchange Agreements (TIEA) with several countries. Taxmann's Research provides access to the DTAA & TIEA, among others. You can access the relevant Article of any tax treaty by choosing a subject or Article no.",
            metaKeywords: "Tax Treaties, Tax Treaty India, Double Taxation, DTAA, OECD, BEPS, MLI, Synthesised Text, Tax Comprehensive Agreements, Tax Information Exchange, TIEA, India Mauritius Tax Treaty",
            ogTitle: "Taxmann Research | Income Tax | Tax Treaties",
            ogDescription: "India has signed 99+ Double Taxation Avoidance Agreements (DTAAs) and 20+ Tax Information Exchange Agreement (TIEA) with several countries. Taxmann's Research provides you with access to the DTAA & TIEA. You can access the relevant Article of any tax treaty by choosing a subject or Article no.",
          },
        ];
        break;
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann's Research | International Tax | 218+ Updated Tax Treaties",
            metaDescription: "Get access to 218+ Tax Treaties between India & several other countries. This section includes the various 164+ country treaties, 5+ model treaties (including OECD Model Treaty, OECD Model TIEA – Multilateral, UN Model Treaty, US Model Treaty) & 36+ repealed or old treaties.",
            metaKeywords: "OECD Model, BEPS, TIEA, UN Model, FATCA, Multilateral Agreements, international tax treaties, tax treaties",
            ogTitle: "Taxmann's Research | International Tax | 218+ Updated Tax Treaties",
            ogDescription: "Get access to 218+ Tax Treaties between India & several other countries. This section includes the various 164+ country treaties, 5+ model treaties (including OECD Model Treaty, OECD Model TIEA – Multilateral, UN Model Treaty, US Model Treaty) & 36+ repealed or old treaties.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 220+ Updated Tax Treaties",
            metaDescription: "Get access to 220+ Tax Treaties between India & several other countries. This section includes the various 167+ country treaties, 5+ model treaties (including OECD Model Treaty, OECD Model TIEA – Multilateral, UN Model Treaty, US Model Treaty) & 36+ repealed or old treaties.",
            metaKeywords: "OECD Model, BEPS, TIEA, UN Model, FATCA, Multilateral Agreements, international tax treaties, tax treaties",
            ogTitle: "Taxmann's Research | Transfer Pricing | 220+ Updated Tax Treaties",
            ogDescription: "Get access to 220+ Tax Treaties between India & several other countries. This section includes the various 167+ country treaties, 5+ model treaties (including OECD Model Treaty, OECD Model TIEA – Multilateral, UN Model Treaty, US Model Treaty) & 36+ repealed or old treaties.",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export const MetaDtlPractice = {
  metaTitle: "Taxmann Research | Income Tax | Ready Referencer – Information for Day-to-Day Tax Work",
  metaDescription:
    "Taxmann Research gives you access to various tax information required daily. Taxmann's Ready Referencer includes Tax Rates, Depreciation Rates, TDS and TCS Rates, Interest on Small Savings Schemes, and the Cost Inflation Index. It also includes charts and tables relating to deductions, limitation periods, etc., and income tax procedures pertaining to income tax returns, TDS returns, TCS returns, etc. Lastly, it includes Small Saving Schemes (PPF, NCS, Senior Citizen Schemes, etc.) and Shares Quotations as of 01-04-1981, 01-04-2001, and 31-01-2018.",
  metaKeywords: "income tax rate, income tax slab rate, income tax rate in india, depreciation rate as per income tax act, tax slab, gold rate, silver rate, gold and silver rate, Foreign Exchange Rates, foreign exchange rates in india, rates of tds for fy 2016 17, SBI Rate of Interest, Income Tax Index, ITR Verification, Faceless Assessment, Income Tax Ready Reckoner, Ready Reckoner Income Tax, Income Tax Rate, Equity Share Quotations",
  ogTitle: "TaxTaxmann Research | Income Tax | Ready Referencer – Information for Day-to-Day Tax Work",
  ogDescription:
  "Taxmann Research gives you access to various tax information required daily. Taxmann's Ready Referencer includes Tax Rates, Depreciation Rates, TDS and TCS Rates, Interest on Small Savings Schemes, and the Cost Inflation Index. It also includes charts and tables relating to deductions, limitation periods, etc., and income tax procedures pertaining to income tax returns, TDS returns, TCS returns, etc. Lastly, it includes Small Saving Schemes (PPF, NCS, Senior Citizen Schemes, etc.) and Shares Quotations as of 01-04-1981, 01-04-2001, and 31-01-2018.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export class MetaUpdatesCommentary {
  static getMetaUpdatesCommentary(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.DTL:
        list = [
          {
            metaTitle: "Taxmann Research | Income Tax | 10+ Commentaries – In-Depth Detailed Write-Ups by Industry Experts",
            metaDescription:
              "Taxmann's Research Income-tax Commentaries are in-depth and detailed write-ups on various provisions of the Income-tax Act. Every commentary is bifurcated into multiple chapters, making it easier to find the relevant and related discussion in a structured way. Leading experts have authored the commentaries, updated after every Finance Act. This feature of the research platform gives you access to commentary on all important provisions, such as capital gains, tax audits, ICDS, Income-tax Rules, Finance Acts since 1993, etc.",
            metaKeywords: "ICDS, Tax Audit, Capital Gains, Finance Act, Income Tax Act, Income Tax Rules, Taxation of Trust, Taxation of Real Estate, Cash Transactions",
            ogTitle: "Taxmann Research | Income Tax | 10+ Commentaries – In-Depth Detailed Write-Ups by Industry Experts",
            ogDescription: "Taxmann's Research Income-tax Commentaries are in-depth and detailed write-ups on various provisions of the Income-tax Act. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored the commentaries, which are updated after every Finance Act. This feature of the research platform gives you access to commentary on all important provisions, such as capital gains, tax audit, ICDS, Income-tax Rules, Finance Acts since 1993, etc.",
          },
        ];
        break;
      case ResearchCategoryUrl.GST_NEW:
        list = [
          {
            metaTitle:
              "Taxmann's Research | GST | 8+ Commentaries | Guidance of the Experts",
            metaDescription:
              "Taxmann's Research GST Commentaries are in-depth and detailed write-ups on various provisions of the GST Act. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored the commentaries, which are updated after every Finance Act. This feature of the research platform gives you access to commentary on all the provisions of GST laws.",
            metaKeywords:
              "gst commentary, gst opinions, gst analysis, taxmann gst",
            ogTitle:
              "Taxmann's Research | GST | 8+ Commentaries | Guidance of the Experts",
            ogDescription:
              "Taxmann's Research GST Commentaries are in-depth and detailed write-ups on various provisions of the GST Act. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored the commentaries, which are updated after every Finance Act. This feature of the research platform gives you access to commentary on all the provisions of GST laws.",
          },
        ];
        break;
      case ResearchCategoryUrl.VAT:
        list = [
          {
            metaTitle: "Taxmann's Research | Excise | Service Tax | VAT | 6+ Commentaries | Guidance of Experts",
            metaDescription:
              "Taxmann's Research Commentaries are in-depth, detailed, and practical write-ups on Customs Laws, Service Tax, Central Excise Law, Central Sales Tax, Cenvat Laws, etc. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored 6+ commentaries, which are updated after every Finance Act. This feature of the research platform gives you access to commentary on all the provisions of Indirect-tax laws.",
            metaKeywords: "Indirect Tax commentaries, excise commentary, cst commentary, customs commentary, customs law",
            ogTitle: "Taxmann's Research | Excise | Service Tax | VAT | 6+ Commentaries | Guidance of Experts",
            ogDescription: "Taxmann's Research Commentaries are in-depth, detailed, and practical write-ups on Customs Laws, Service Tax, Central Excise Law, Central Sales Tax, Cenvat Laws, etc. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored 6+ commentaries, which are updated after every Finance Act. This feature of the research platform gives you access to commentary on all the provisions of Indirect-tax laws.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPANY_LAW:
        list = [
          {
            metaTitle: "Taxmann Research | Company Law | 40+ Commentaries",
            metaDescription: "A chapter-wise  guidance on company and all related provisions including FEMA, memorandum & articles of association is discussed here. Read now!",
            metaKeywords: "companies law, memorandum of association, company law notes, article of association",
            ogTitle: "Taxmann Research | Company Law | 40+ Commentaries",
            ogDescription: "A chapter-wise  guidance on company and all related provisions including FEMA, memorandum & articles of association is discussed here. Read now!",
          },
        ];
        break;
      case ResearchCategoryUrl.IBC:
        list = [
          {
            metaTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 30+ Topics",
            metaDescription:"Taxmann's Research Commentaries on the IBC are in-depth, detailed, and practical write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored 30+ commentaries. This feature of the research platform gives you access to commentary on all the provisions of the IBC Code.",
            metaKeywords: "chapters of IBC code, Corporate debtor under IBC 2016, IBC code 2016, IBC code ship, nclat, NCLAT e filing, NCLAT e filing login, IBC laws, IBC Code, IBC Code, IBC Code 2016, IBC law in India, IBC Laws articles, 2015 ibc commentary, 2018 ibc code commentary, ibc commentary",
            ogTitle: "Taxmann's Research | Insolvency and Bankruptcy Code (IBC) | 30+ Topics",
            ogDescription: "Taxmann's Research Commentaries on the IBC are in-depth, detailed, and practical write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way. Leading experts have authored 30+ commentaries. This feature of the research platform gives you access to commentary on all the provisions of the IBC Code.",
          },
        ];
        break;
      case ResearchCategoryUrl.FEMA:
        list = [
          {
            metaTitle: "Taxmann's Research | FEMA Banking & Insurance | 70+ Commentaries",
            metaDescription:"Taxmann's research commentaries offer in-depth analysis and detailed explanations of various provisions in foreign exchange management (FEMA) and banking laws. These commentaries are written by leading experts and updated after amendment in the law. They are structured into multiple chapters to make it easy for you to find the relevant information. With access to commentary on all FEMA and banking laws provisions, this feature of the research platform is a valuable resource for understanding these complex laws.",
            metaKeywords: "foreign exchange rates, foreign currency exchange rates, foreign exchange rates in India, foreign currency, FEMA 1999, mtss, LRS remittance, Liberalized Remittance Scheme (LRS), insurance law, insurance law in India, overseas direct investment,  overseas investment, overseas direct investment master circular 2015, overseas direct investment commentaries on FEMA, FEMA ready reckoner with commentary, FEMA commentary, treatise on FEMA",
            ogTitle: "Taxmann's Research | FEMA Banking & Insurance | 70+ Commentaries",
            ogDescription: "Taxmann's research commentaries offer in-depth analysis and detailed explanations of various provisions in foreign exchange management (FEMA) and banking laws. These commentaries are written by leading experts and updated after amendment in the law. They are structured into multiple chapters to make it easy for you to find the relevant information. With access to commentary on all FEMA and banking laws provisions, this feature of the research platform is a valuable resource for understanding these complex laws.",
          },
        ];
        break;
      case ResearchCategoryUrl.COMPETiTION_LAW:
        list = [
          {
            metaTitle: "Competition Law Commentary - Competition Act, 2002",
            metaDescription:
              "Get detailed commentary on Competition law, Competition act 2002, Competition Commission of India, Duties, Powers and Functions of Commission, Competition Advocacy, Duties of director general, Finance, accounts & audit, Competition Appellate tribunal.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.AAA:
        list = [
          {
            metaTitle: "Accounts and Audit-Commentaries - Taxmann",
            metaDescription: "Accounts and Audit Commentaries.",
            metaKeywords: "",
            ogTitle: "",
            ogDescription: "",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 50+ Commentaries",
            metaDescription:
              "Get access to 50+ exclusive commentaries on various topics of Transfer Pricing. These commentaries on Transfer Pricing provide in-depth and detailed write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way",
            metaKeywords: "transfer pricing, associated enterprises, arm length price, transfer pricing commentaries, Transfer Pricing Compliances, Cost Contribution Agreements, Benchmarking, Comparable, Comparable Uncontrollable Price (CUP), Profit Split Method (PSM)",
            ogTitle: "Taxmann's Research | Transfer Pricing | 50+ Commentaries",
            ogDescription: "Get access to 50+ exclusive commentaries on various topics of Transfer Pricing. These commentaries on Transfer Pricing provide in-depth and detailed write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export const MetaGstTarrif = {
  metaTitle:
    "Taxmann's Research | GST | Tariff for Goods and Services | Annotated, Authentic, Amended & Updated | Historical Rates",
  metaDescription:
    "Taxmann's GST Tariff on Goods and Services provides the HSN wise CGST, SGST/UTGST, and IGST rates. The unique tool 'Historical Tariff' enables you to check the GST rate applicable during different periods. For further convenience, check the GST rate applicable on any date by entering the date on the calendar.",
  metaKeywords:
    "tariff, gst tariff, gst tariff schedule, gst tariff for goods, taxmann gst",
  ogTitle:
    "Taxmann's Research | GST | Tariff for Goods and Services | Annotated, Authentic, Amended & Updated | Historical Rates",
  ogDescription:
    "Taxmann's GST Tariff on Goods and Services provides the HSN wise CGST, SGST/UTGST, and IGST rates. The unique tool 'Historical Tariff' enables you to check the GST rate applicable during different periods. For further convenience, check the GST rate applicable on any date by entering the date on the calendar.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export class MetaUpdatesAAAandCompany {
  static getMetaUpdatesAAAandCompany(categoryId, subcategory) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.COMPANY_LAW:
        switch (subcategory) {
          case AAAAccountingCategory.IND_AS:
            list = [
              {
                metaTitle: "Taxmann Research | Indian Accounting Standard (Ind AS) in Company & SEBI Laws",
                metaDescription: "Read a list and the role of Indian Accounting Standards in Company & SEBI laws. Subscribe to Taxmann research now and stay updated!",
                metaKeywords: "Ind AS, ind as 116, ind as 115, ind as 1, indian accounting standards",
                ogTitle: "Taxmann Research | Indian Accounting Standard (Ind AS) in Company & SEBI Laws",
                ogDescription: "Read a list and the role of Indian Accounting Standards in Company & SEBI laws. Subscribe to Taxmann research now and stay updated!",
              },
            ];
            break;
          case AAAAccountingCategory.AS:
            list = [
              {
                metaTitle: "Taxmann Research | Accounting Standard (AS) in Company & SEBI Laws",
                metaDescription: "Accounting Standards (AS 1~32) are issued by the Accounting Standards Board of ICAI, to establish uniform standards for preparation of financial statements, in accordance with Indian GAAP (Generally Accepted Accounting Practices), for better understanding of the users.Read more here!",
                metaKeywords: "Accounting Standards, accounting standards list, list of accounting standards in india. accounting standards 1",
                ogTitle: "Taxmann Research | Accounting Standard (AS) in Company & SEBI Laws",
                ogDescription: "Accounting Standards (AS 1~32) are issued by the Accounting Standards Board of ICAI, to establish uniform standards for preparation of financial statements, in accordance with Indian GAAP (Generally Accepted Accounting Practices), for better understanding of the users.Read more here!",
              },
            ];
            break;
          case AAAAccountingCategory.ICDS:
            list = [
              {
                metaTitle: "Taxmann Research | ICDS in Company & SEBI Laws",
                metaDescription: "ICDS were issued by the Government of India in exercise of powers conferred to it under section 145(2) of The Income Tax Act, 1961. Read this article to know all about Income Computation and Disclosure Standards (ICDS).",
                metaKeywords: "ICDS, icds online, income computation and disclosure standards",
                ogTitle: "Taxmann Research | ICDS in Company & SEBI Laws",
                ogDescription: "ICDS were issued by the Government of India in exercise of powers conferred to it under section 145(2) of The Income Tax Act, 1961. Read this article to know all about Income Computation and Disclosure Standards (ICDS).",
              },
            ];
            break;
          case AAAAccountingCategory.CAS:
            list = [
              {
                metaTitle: "Taxmann Research | Cost Accounting Standards in Company & SEBI Laws",
                metaDescription: "Get all details about the usage and role of Cost accounting standards in company and SEI laws in India only on Taxmann research.  Read now!",
                metaKeywords: "Cost Accounting Standards, cas, list of cost accounting standards",
                ogTitle: "Taxmann Research | Cost Accounting Standards in Company & SEBI Laws",
                ogDescription: "Get all details about the usage and role of Cost accounting standards in company and SEI laws in India only on Taxmann research.  Read now!"
              },
            ];
            break;
          case AAAAccountingCategory.IND_GAS:
            list = [
              {
                metaTitle: "Taxmann Research | IGAS in Company & SEBI Laws",
                metaDescription: "Check all about the IGAS 1, 2 and 3 and the rules stated in the Indian government accounting standards by subscribing to Taxmann research.",
                metaKeywords: "Indian GAS, igas, indian government accounting standards",
                ogTitle: "Taxmann Research | IGAS in Company & SEBI Laws",
                ogDescription: "Check all about the IGAS 1, 2 and 3 and the rules stated in the Indian government accounting standards by subscribing to Taxmann research.",
              },
            ];
            break;
          case AAAAuditingCategory.CAS:
            list = [
              {
                metaTitle: "Taxmann Research | Cost Auditing Standards in Company & SEBI Laws",
                metaDescription: "Read how cost accounting standards and caas  works in the context of company & SEBI at Taxmann research. Subscribe now!",
                metaKeywords: "Cost Auditing Standards, CAAS, icmai IAASB, CAASB, Companies Law, Accounting Auditing",
                ogTitle: "Taxmann Research | Cost Auditing Standards in Company & SEBI Laws",
                ogDescription: "Read how cost accounting standards and caas  works in the context of company & SEBI at Taxmann research. Subscribe now!",
              },
            ];
            break;
          case AAAAuditingCategory.SECRETARIAL_STANDARDS:
            list = [
              {
                metaTitle: "Taxmann Research | Secretarial Standards in Company & SEBI Laws",
                metaDescription: "Get the revised Secretarial Standard on Meetings of Board of Director, General Meetings, Dividend and Guidence note on Secretarial Standard.",
                metaKeywords: "Secretarial Standards, corporate social responsibility, Revised Secretarial Standards, guidance note on corporate social responsibility",
                ogTitle: "Taxmann Research | Secretarial Standards in Company & SEBI Laws",
                ogDescription: "Get the revised Secretarial Standard on Meetings of Board of Director, General Meetings, Dividend and Guidence note on Secretarial Standard.",
              },
            ];
            break;
          case AAAAuditingCategory.CS_AUDITING_STANDARD:
            list = [
              {
                metaTitle: "Taxmann Research | CS Auditing Standards in Company & SEBI Laws",
                metaDescription: "ICSI has issued four Auditing Standards for the members in practice in order to enable them to carry out the audit engagements more. Read the article to know more about CSAS.",
                metaKeywords: "CSAS 1,  CSAS 2, CSAS 3,  CSAS 4, CS Auditing Standards, ICSI Auditing Standards",
                ogTitle: "Taxmann Research | CS Auditing Standards in Company & SEBI Laws",
                ogDescription: "ICSI has issued four Auditing Standards for the members in practice in order to enable them to carry out the audit engagements more. Read the article to know more about CSAS.",
              },
            ];
            break;
          default:
            list = [
              {
                metaDescription: "",
                metaKeywords: "",
              },
            ];
            break;
        }
        break;
      case ResearchCategoryUrl.AAA:
        switch (subcategory) {
          case AAAAccountingCategory.IND_AS:
            list = [
              {
                metaTitle: "Applicability of IND AS - Accounting - Taxmann",
                metaDescription:
                  "The Ministry of Corporate Affairs (MCA), in 2015, had notified the Companies (Indian Accounting Standards (IND AS)) Rules 2015, which stipulated the adoption and applicability of IND AS in a phased manner beginning from the Accounting period 2016-17. The MCA has since issued three Amendment Rules, one each in year 2016, 2017, and 2018 to amend the 2015 rules.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAccountingCategory.AS:
            list = [
              {
                metaTitle: "List of Accounting Standards - Taxmann",
                metaDescription:
                  "Accounting Standards are issued by the Accounting Standards Board of ICAI, to establish uniform standards for preparation of financial statements, in accordance with Indian GAAP (Generally Accepted Accounting Practices), for better understanding of the users.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAccountingCategory.ICDS:
            list = [
              {
                metaTitle:
                  "ICDS: Income Computation & Disclosure Standards - Taxmann",
                metaDescription:
                  "ICDS were issued by the Government of India in exercise of powers conferred to it under section 145(2) of The Income Tax Act, 1961. Read this article to know all about Income Computation and Disclosure Standards.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAccountingCategory.CAS:
            list = [
              {
                metaTitle:
                  "Cost Accounting Standards - Accounts & Audit - Taxmann",
                metaDescription:
                  "Get details on Ind AS, Accounting standards, Income computation & disclosure standards, Secretarial standards, Cost accounting standards, Cost audit and assurance standards, Indian Government & Accounting standards.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAccountingCategory.IND_GAS:
            list = [
              {
                metaTitle:
                  "Ind GAS - Accounting Standards - Accounts & Audit - Taxmann",
                metaDescription: "Accounting - Indian GAS",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAuditingCategory.AUDITING_STANDARDS:
            list = [
              {
                metaTitle: "Auditing Standards - Taxmann",
                metaDescription: "Standards on Auditing",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAuditingCategory.CARO:
            list = [
              {
                metaTitle: "CARO (Companies Auditor's Report Order), 2020 ",
                metaDescription:
                  "CARO 2020 has prescribed the format for statutory audit reports for audits beginning the financial year 2019-20.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAuditingCategory.CAS:
            list = [
              {
                metaTitle:
                  "Cost Auditing Standards - Accounts & Audit - Taxmann",
                metaDescription:
                  "Get the details on Cost Auditing Standards under the Companies Act, 2013",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAuditingCategory.SECRETARIAL_STANDARDS:
            list = [
              {
                metaTitle: "Secretarial Standards - Taxmann",
                metaDescription: "Secretarial Standards",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAAuditingCategory.CS_AUDITING_STANDARD:
            list = [
              {
                metaTitle: "CS Auditing Standards - Taxmann",
                metaDescription:
                  "ICSI has issued four Auditing Standards for the members in practice in order to enable them to carry out the audit engagements more. ",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAFinancialsCategory.IND_AS_FINANCIALS:
            list = [
              {
                metaTitle: "Ind AS financials - Taxmann",
                metaDescription:
                  "Get illustrated AS financial statements to understand the presentation and disclosure requirements under balance sheet, statement of profit & loss, cash flow statement.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAFinancialsCategory.MODEL_FINANCIALS:
            list = [
              {
                metaTitle: "AS Model Financials - Taxmann - Accounts & Audit",
                metaDescription:
                  "Get Model Financial Corporation latest Consolidated Results, Financial Statements and State Model Financial Corporation detailed profit and loss accounts.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAAFinancialsCategory.STATUTORY_FINANACIALS:
            list = [
              {
                metaTitle:
                  "Financial accounting: Statutory Financial Statements",
                metaDescription:
                  "The Statutory Financial Statements are used by the management in order to know the financial situation as well as the results, growth evolution and the company’s performance in different areas of the company during the tax year.",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.AS_DISCLOSURES:
            list = [
              {
                metaTitle: "AS Disclosures -  Accounts & Audit - Taxmann",
                metaDescription:
                  "Get AS disclosure checklist and Ind AS disclosure checklist of disclosures required to be made in financial statements in accordance with AS and Ind AS",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.IND_AS_DISCLOSURE:
            list = [
              {
                metaTitle:
                  "Ind AS and AS Disclosures - Accounts & Audit - Taxmann",
                metaDescription:
                  "Get AS disclosure checklist and Ind AS disclosure checklist of disclosures required to be made in financial statements in accordance with AS and Ind AS",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case "ind-as-disclosure-faq":
            list = [
              {
                metaTitle: "FAQs: Ind AS Disclosures - Taxmann",
                metaDescription: "FAQ on Ind AS Disclosures",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.IND_AS_IMPACT:
            list = [
              {
                metaTitle:
                  "Ind AS impact on NBFCS - Accounts & Audit - Taxmann",
                metaDescription:
                  "Grab the latest information on Indian AS Impact on various indusrties in this articles. ",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.SCHEDULE_3_DISCLOSURES:
            list = [
              {
                metaTitle:
                  "Schedule III for financial statements as per AS - Taxmann",
                metaDescription: "Schedule III Disclosures AS",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.SCHEDULE_3_DISCLOSURES_INDAS:
            list = [
              {
                metaTitle:
                  "Schedule III for financial statements as per Ind AS - Taxmann",
                metaDescription: "Schedule III Disclosures Ind AS",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case AAADisclosuresCategory.SEBI_DISCLOSURES:
            list = [
              {
                metaTitle: "Disclosure & Regulations - SEBI | Taxmann",
                metaDescription: "SEBI Disclosures",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          case "icai-opinions":
            list = [
              {
                metaTitle: "ICAI Recent Opinions - Taxmann",
                metaDescription:
                  "Read the article about the recent ICAI opinions here. ",
                metaKeywords: "",
                ogTitle: "",
                ogDescription: "",
              },
            ];
            break;
          default:
            list = [
              {
                metaDescription: "",
                metaKeywords: "",
              },
            ];
            break;
        }
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export class MetaTaxTreatyCommentary {
  static getMetaTaxTreatyCommentary(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle: "Taxmann's Research | International Tax | 17+ Commentaries",
            metaDescription: "This section covers OECD and UN Model commentary on model tax treaties. It also covers a legal commentary published by Taxmann on model tax treaties. These commentaries provide in-depth and detailed write-ups on all Articles of the Model Tax Convention.",
            metaKeywords: "International tax treaty, OECD model, OECD model commentary",
            ogTitle: "Taxmann's Research | International Tax | 17+ Commentaries",
            ogDescription: "This section covers OECD and UN Model commentary on model tax treaties. It also covers a legal commentary published by Taxmann on model tax treaties. These commentaries provide in-depth and detailed write-ups on all Articles of the Model Tax Convention.",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle: "Taxmann's Research | Transfer Pricing | 50+ Commentaries",
            metaDescription:
              "Get access to 50+ exclusive commentaries on various topics of Transfer Pricing. These commentaries on Transfer Pricing provide in-depth and detailed write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way.",
            metaKeywords: "transfer pricing, associated enterprises, arm length price, transfer pricing commentaries, Transfer Pricing Compliances, Cost Contribution Agreements, Benchmarking, Comparable, Comparable Uncontrollable Price (CUP), Profit Split Method (PSM)",
            ogTitle: "Taxmann's Research | Transfer Pricing | 50+ Commentaries",
            ogDescription: "Get access to 50+ exclusive commentaries on various topics of Transfer Pricing. These commentaries on Transfer Pricing provide in-depth and detailed write-ups. Every commentary is bifurcated into multiple chapters making it easier to find the relevant and related discussion in a structured way.",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}
export const MetaIndianActBills = {
  metaTitle: "Bills & Ordinance - Taxmann - Indian Acts",
  metaDescription:
    "Get latest information on bills & ordinances like Finance Bill, Chit funds bill, 2018, Consumer protection bill, 2018, Fugitive economic offenders bill, 2018.",
  metaKeywords: "",
  ogTitle: "",
  ogDescription: "",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const MetaTools = {
  metaTitle:
    "Tax and Corporate Law Tools by Taxmann | Your Everyday Work Done Differently",
  metaDescription:
    "Taxmann’s Tools for Tax and Corporate Laws are unique and accurate tools, to perform various time-taking tasks efficiently. Use Taxmann’s Tools for different subject areas like Income Tax, International Tax, Transfer Pricing, GST, Accounts, Audit, and Bare Acts",
  metaKeywords:
    "Income Tax Calculator, Income Tax Calculator Excel, Income Tax Slabs, Personal Income Tax CalculatorDouble Taxation Avoidance Agreements, Tax Treaty, OECD Model Convention, OECD,International Tax Case Laws, Section 9 Income Tax Act, Transfer Pricing Case Laws,GST Rates, GST Rate Finder, HSN Code, SAC Code, GST Rates in India, E-Way Bill, GST E-Way Bill, Inter-State Movement, Intra-State Movement, GST Place of Supply, IGST, SGST, UTGST, CGST, Section 10, EPS, Earning Per Share, Diluted Earnings Per Share, AS 20, Accounting StandardIndian Accounting Standards, Ind AS, Ind AS ApplicabilityDeferred Tax Calculator, AS 22, Deferred Tax, Ind AS Deferred Tax, Ind AS Deferred Tax Calculator, Ind AS 12, Bare Acts, Bare Acts Book, Bare Acts UPSC, Bare Acts Indian Constitution, Indian Acts, Indian Laws",
  ogTitle:
    "Tax and Corporate Law Tools by Taxmann | Your Everyday Work Done Differently",
  ogDescription:
    "Taxmann’s Tools for Tax and Corporate Laws are unique and accurate tools, to perform various time-taking tasks efficiently. Use Taxmann’s Tools for different subject areas like Income Tax, International Tax, Transfer Pricing, GST, Accounts, Audit, and Bare Acts",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const IncomeTaxCalculator = {
  metaTitle:
    "Taxmann's Income Tax Calculator | F.Y. 2023-24 Tax Estimator",
  metaDescription:
    "Quickly calculate your taxable income and tax liability with Taxmann's Income Tax Calculator. It covers all types of assessees and income heads under the Income Tax Act.",
  metaKeywords:
    "Income Tax Calculator, Tax Calculation, Tax Calculator India, online income tax calculator, Tax Calculator, Tax Liability Calculator, Tax Estimator, Taxable Income Calculator, Tax Return Calculator, Income Tax Return Calculator, Tax Planning Calculator, Tax Saving Calculator",
  ogTitle:
    "Taxmann's Income Tax Calculator | F.Y. 2023-24 Tax Estimator",
  ogDescription:
    "Quickly calculate your taxable income and tax liability with Taxmann's Income Tax Calculator. It covers all types of assessees and income heads under the Income Tax Act.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const GSTRateFinder = {
  metaTitle:
    "Taxmann's Research | GST | Tools | Rate Finder for Goods and Services",
  metaDescription:
    "Taxmann's GST Rate Finder enables you quickly find out the category and the GST rate slab under which various products or goods have been classified. Also, get the HSN code for the goods or products required for invoicing purposes. Start your search with commonly used words for the product or select from the exhaustive list of categories. ",
  metaKeywords:
    "gst search, hsn code, hsn code list, gst rate, sac code, gst finder",
  ogTitle:
    "Taxmann's Research | GST | Tools | Rate Finder for Goods and Services",
  ogDescription:
    "Taxmann's GST Rate Finder enables you quickly find out the category and the GST rate slab under which various products or goods have been classified. Also, get the HSN code for the goods or products required for invoicing purposes. Start your search with commonly used words for the product or select from the exhaustive list of categories. ",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const EwayBill = {
  metaTitle:
    "Taxmann's Research | GST | Tools | E-Way Bill",
  metaDescription:
    "Assess the requirement for the generation of e-way bill for supply of goods involving the movement of goods. Also, get the relevant GST Notification No. and GST Rule No.",
  metaKeywords:
    "eway bill, eway bill generate, gst eway bill, eway bill limit, gst ewaybill",
  ogTitle:
    "Taxmann's Research | GST | Tools | E-Way Bill",
  ogDescription:
    "Assess the requirement for the generation of e-way bill for supply of goods involving the movement of goods. Also, get the relevant GST Notification No. and GST Rule No.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const PlaceOfSupply = {
  metaTitle:
    "Taxmann's Research | GST | Tools | Place of Supply",
  metaDescription:
    "Answer a few questions to quickly determine the place of supply under GST (including import and export of goods). Also, get the relevant Section No. of the respective GST Act.",
  metaKeywords:
    "place of supply in gst, place of supply under gst, place of supply",
  ogTitle:
    "Taxmann's Research | GST | Tools | Place of Supply",
  ogDescription:
    "Answer a few questions to quickly determine the place of supply under GST (including import and export of goods). Also, get the relevant Section No. of the respective GST Act.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const epsCalculator = {
  metaTitle:
    "Basic Earning Per Share (BEPS) and Diluted Earning Per Share (DEPS) Calculator as per AS 20 by Taxmann",
  metaDescription:
    "Taxmann’s Earnings Per Share or EPS calculator finds the value for the company's outstanding shares as per AS 20. Calculate EPS for Basic Earning Per Share (BEPS) or both BEPS and Diluted Earning Per Share (DEPS)",
  metaKeywords:
    "Earning Per Share, EPS, Diluted Earning Per Share, DEPS, EPF, Employee Pension Scheme, EPS Eligibility, Accounting Standards, Weighted Average Equity, Bonus Issue, Rights Issue, Bonus Issue, Share Warrants, Share Options, Preference Shares",
  ogTitle:
    "Basic Earning Per Share (BEPS) and Diluted Earning Per Share (DEPS) Calculator as per AS 20 by Taxmann",
  ogDescription:
    "Taxmann’s Earnings Per Share or EPS calculator finds the value for the company's outstanding shares as per AS 20. Calculate EPS for Basic Earning Per Share (BEPS) or both BEPS and Diluted Earning Per Share (DEPS)",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const IndAsApplicability = {
  metaTitle:
    "Ind AS Applicability Tool by Taxmann | Find out the mandatory applicability of Ind AS to any organisation",
  metaDescription:
    "Taxmann’s Ind AS Applicability Tool helps you find the mandatory applicability of Ind AS to any organisation, be it the Insurance/Banking Industry or Non-Banking Finance Company or others.",
  metaKeywords:
    "Indian Accounting Standards, Ind AS, Ind AS Applicability Tool, Accounting Standards, Listed Entity, Unlisted Entity, Net Worth Calculation, NBFC, Non-Banking Finance Company, Ind AS Applicability ICAI, Insurance Industry, Banking Industry, SEBI, Voluntary Adoption of Ind AS, ",
  ogTitle:
    "Ind AS Applicability Tool by Taxmann | Find out the mandatory applicability of Ind AS to any organisation",
  ogDescription:
    "Taxmann’s Ind AS Applicability Tool helps you find the mandatory applicability of Ind AS to any organisation, be it the Insurance/Banking Industry or Non-Banking Finance Company or others.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const AsDeferredCalculator = {
  metaTitle:
    "AS Deferred Tax Calculator by Taxmann for F.Y. 2021-22 | As per Accounting Standard 22",
  metaDescription:
    "Deferred tax refers to the tax which shall either be paid or has already been paid due to transient inconsistency between an organisation’s income statement and tax statement. Taxmann’s AS Deferred Tax Calculator computes the deferred tax asset (DTA) and deferred tax liability (DTL) as per accounting standard 22 based on the applicable tax rate.",
  metaKeywords:
    "Deferred Tax Rate, Deferred Tax Calculator, Deferred Tax Calculation, Deferred Tax Liability, Deferred Tax Asset, Financial Statements, Timing Difference, Temporary Difference, Permanent Difference, AS 22, Accounting Standard, Total Turnover, Gratuity, Leave Encashment, Bonus, Commission, Tax, Duty, Cess, Advance Payment of Tax, Tax Holiday, Bad Debts, MAT, Minimum Alternate Tax, TDS, Tax Deducted at Source",
  ogTitle:
    "AS Deferred Tax Calculator by Taxmann for F.Y. 2021-22 | As per Accounting Standard 22",
  ogDescription:
    "Deferred tax refers to the tax which shall either be paid or has already been paid due to transient inconsistency between an organisation’s income statement and tax statement. Taxmann’s AS Deferred Tax Calculator computes the deferred tax asset (DTA) and deferred tax liability (DTL) as per accounting standard 22 based on the applicable tax rate.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const IndAsDeferredCalculator = {
  metaTitle:
    "Ind AS Deferred Tax Calculator by Taxmann for F.Y. 2021-22 | As per Accounting Standard 12",
  metaDescription:
    "Deferred tax refers to the tax which shall either be paid or has already been paid due to transient inconsistency between an organisation’s income statement and tax statement. Taxmann’s Ind AS Deferred Tax Calculator computes the deferred tax asset (DTA) and deferred tax liability (DTL) per the Indian accounting standard 12 based on the applicable tax rate",
  metaKeywords:
    "Deferred Tax Rate, Deferred Tax Calculator, Deferred Tax Calculation, Deferred Tax Liability, Deferred Tax Asset, Financial Statements, Timing Difference, Temporary Difference, Permanent Difference, Ind AS 12, Indian Accounting Standard, Total Turnover, Gratuity, Leave Encashment, Bonus, Commission, Tax, Duty, Cess, Advance Payment of Tax, Tax Holiday, Bad Debts, MAT, Minimum Alternate Tax, TDS, Tax Deducted at Source, Section 111A, Tax Base, Accounting Base",
  ogTitle:
    "Ind AS Deferred Tax Calculator by Taxmann for F.Y. 2021-22 | As per Accounting Standard 12",
  ogDescription:
    "Deferred tax refers to the tax which shall either be paid or has already been paid due to transient inconsistency between an organisation’s income statement and tax statement. Taxmann’s Ind AS Deferred Tax Calculator computes the deferred tax asset (DTA) and deferred tax liability (DTL) per the Indian accounting standard 12 based on the applicable tax rate",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const TreatyComparator = {
  metaTitle:
    "Double Tax Treaties Comparison Tool by Taxmann | Compare Article of Double Taxation Avoidance Agreement",
  metaDescription:
    "Taxmann’s Double Tax Treaties Comparison Tool helps you compare the Article of any Double Taxation Avoidance Agreement (DTAA) that India has entered into with other/foreign countries. Compare multiple tax treaties based on Countries or Subject, and download the report in Word Format.",
  metaKeywords:
    "Tax Treaty, Double Tax Treaty Comparison, Double Taxation Avoidance Agreement, Compare Tax Treaties, Double Taxation Convention, BEPS, Multilateral Instruments, MLI, Synthesised Text, OECD Model, UN Model, US Model, DTAA Rates, Global Tax Treaty Information, Worldwide Taxation, International Taxation, Tax Treaties, Singapore India Tax Treaty, India US Tax Treaty, India Mauritius Tax Treaty",
  ogTitle:
    "Double Tax Treaties Comparison Tool by Taxmann | Compare Article of Double Taxation Avoidance Agreement",
  ogDescription:
    "Taxmann’s Double Tax Treaties Comparison Tool helps you compare the Article of any Double Taxation Avoidance Agreement (DTAA) that India has entered into with other/foreign countries. Compare multiple tax treaties based on Countries or Subject, and download the report in Word Format.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};

export class IltTpCaseFinder {
  static getMetaIltTpCaseFinder(categoryId) {
    let list;
    switch (categoryId) {
      case ResearchCategoryUrl.ILT:
        list = [
          {
            metaTitle:
              "Case Finder for International Taxation by Taxmann | Get Issue Specific Case Law Digest",
            metaDescription:
              "Taxmann’s Case Finder for International Taxation provides issue-specific digests from all case laws relating to International Taxation. Choose the Article Number (based on the OECD Model Convention) or Subject, and Taxmann’s Tool will provide the issue specific summary decided by Supreme Court, High Court, AAR and ITAT.",
            metaKeywords:
              "International Tax Case Law, ILT Cases, International Tax Cases, International Tax Case Law Finder, International Tax Judgement, Supreme Court, High Court, AAR, ITAT, Tax Treaties, OECD Model Convention, Business Profits, Fees for Technical Services, Other Income, Royalty, Permanent Establishment, Capital Gains, Interest, Residence, Income from Employment, Elimination of Double Taxation, Dividend, Independent Personal Services",
            ogTitle:
              "Case Finder for International Taxation by Taxmann | Get Issue Specific Case Law Digest",
            ogDescription:
              "Taxmann’s Case Finder for International Taxation provides issue-specific digests from all case laws relating to International Taxation. Choose the Article Number (based on the OECD Model Convention) or Subject, and Taxmann’s Tool will provide the issue specific summary decided by Supreme Court, High Court, AAR and ITAT.",
            ogUrl: "",
            ogImage: "",
            ogType: "",
            ogSite_Name: "",
          },
        ];
        break;
      case ResearchCategoryUrl.TP:
        list = [
          {
            metaTitle:
              "Case Finder for Transfer Pricing by Taxmann | Get Issue Specific Case Law Digest",
            metaDescription:
              "Taxmann’s Case Finder for Transfer Pricing provides issue-specific digests from all case laws relating to Transfer Pricing. Choose the Subject and Sub-subject, and Taxmann’s Tool will give the issue specific summary of all case laws decided by the Supreme Court, High Court, and ITAT",
            metaKeywords:
              "Transfer Pricing Case Finder, TP Case Laws, Transfer Pricing Cases, TP Rulings, Transfer Pricing Rulings, TP Landmark Judgement, Transfer Pricing Judgement, Supreme Court, High Court, ITAT, Section 92, Section 92A, Section 92B, Section 92BA, Section 92C, Section 92C(4), Section 92CA, Section 92CB, Section 92D, Section 92E, Section 144C, Section 271(1)(c), Comparables, ALP, Arm’s Length Price, Transfer Pricing Adjustments, Dispute Resolution Panel, Associated Enterprise",
            ogTitle:
              "Case Finder for Transfer Pricing by Taxmann | Get Issue Specific Case Law Digest",
            ogDescription:
              "Taxmann’s Case Finder for Transfer Pricing provides issue-specific digests from all case laws relating to Transfer Pricing. Choose the Subject and Sub-subject, and Taxmann’s Tool will give the issue specific summary of all case laws decided by the Supreme Court, High Court, and ITAT",
            ogUrl: "",
            ogImage: "",
            ogType: "",
            ogSite_Name: "",
          },
        ];
        break;
      default:
        break;
    }
    return list;
  }
}
export const ActRuleComparator = {
  metaTitle:
    "Act and Rule Comparator by Taxmann | Compare the Provisions of any Act or Rule",
  metaDescription:
    "Taxmann’s Act and Rule Comparator provides the option of comparing Sections and Rules of any Act and Rule. Select the Section(s) and Rule(s) from the drop-down menu, and Taxmann’s Tool will generate a comparison report which can be downloaded in word format. Choose from 330+ Acts & 900+ Rules.",
  metaKeywords:
    "Indian Laws, Bare Act, Act, Rule, Section, Regulation, India Code, Statutes, Constitution of India, Indian Penal Code, IPC, Criminal Procedure Code, CRPC, Civil Procedure Code, CPC",
  ogTitle:
    "Act and Rule Comparator by Taxmann | Compare the Provisions of any Act or Rule",
  ogDescription:
    "Taxmann’s Act and Rule Comparator provides the option of comparing Sections and Rules of any Act and Rule. Select the Section(s) and Rule(s) from the drop-down menu, and Taxmann’s Tool will generate a comparison report which can be downloaded in word format. Choose from 330+ Acts & 900+ Rules.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const ComplianceEtds = {
  metaTitle: "Taxmann e-TDS Compliance Software by Vinod K. Singhania",
  metaDescription:
    "Taxmann e-TDS software simplifies your TDS and TCS compliance by providing an automated solution. It allows you to easily import data from excel and comes equipped with features such as Excel Import/Export, Default Predictor Notice, Secured Database, quick upload of bulk entries, and PAN verification.",
  metaKeywords:
    "TDS Software, Form 16, Form 16A, Form 24Q, Form 26Q, Form 27Q, Form 27EQ, Section 194N, section 194n of income tax act, Section 206AB, interest on late payment of tds, TDS on Salary, Taxmann TDS, Section 194Q, Section 194C, Section 194J, Section 195, Section 192, TDS Challan, TDS Due Date, TDS Certificates, FVU Return, TDS Deduction, TDS Deposit, TDS Certificate Download, TDS Calculator, Revised TDS Return, TDS return, , tds on sale of property by nri, Vinod K. Singhania TDS, TDS Rates, TCS Rates, TDS on Higher Rate, TDS on Property, Form 15G, Form 15H, TDS on Royalty, TCS Return, how to calculate tds on salary",
  ogTitle: "Taxmann e-TDS Compliance Software by Vinod K. Singhania",
  ogDescription:
    "Taxmann e-TDS software simplifies your TDS and TCS compliance by providing an automated solution. It allows you to easily import data from excel and comes equipped with features such as Excel Import/Export, Default Predictor Notice, Secured Database, quick upload of bulk entries, and PAN verification.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};
export const ComplianceEtdsCalendar = {
  metaTitle:
    "TDS Due Dates Calendar by Taxmann | Month-Wise Due Dates | F.Y. 2023-24",
  metaDescription:
    "To ensure your compliance with TDS regulations, Taxmann's e-TDS Compliance Calendar provides a comprehensive list of updated and important due dates for activities such as Challan payments, Forms submissions, Tax Deduction deposits, and issuance of TDS certificates.",
  metaKeywords:
    "Income Tax Calendar, advance tax due dates, Tax Compliance Calendar, TCS Calendar, Challan 281, tds challan 281, Form 26QB, form 26qb download, Form 26QC, TDS Challan, tds challan status, Form 24G, Form 26QB, form 26qb download, Form 26Q, tds due date, tds online payment, Form 16, form 16 download, Form 16A, how to download form 16a from traces, how to pay tds online, itr filing last date, last date for filing itr",
  ogTitle:
    "TDS Due Dates Calendar by Taxmann | Month-Wise Due Dates | F.Y. 2023-24",
  ogDescription:
    "To ensure your compliance with TDS regulations, Taxmann's e-TDS Compliance Calendar provides a comprehensive list of updated and important due dates for activities such as Challan payments, Forms submissions, Tax Deduction deposits, and issuance of TDS certificates.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};

export const Webinar = {
  metaTitle:
    "Taxmann Webinar | Online Seminars by Professional Experts",
  metaDescription:
    "Stay informed with live updates on Taxmann's upcoming Webinars and Workshops. Engage with online experts, pose your questions, and receive prompt answers. Join the knowledge-sharing community today.",
  metaKeywords:
    "Taxmann Webinar, Taxmann Classes, Taxmann Workshop, Live Webinar, Online Seminars",
  ogTitle:
    "Taxmann Webinar- Online Seminars by Professional Experts",
  ogDescription:
    "Get live updates on upcoming and past webinars hosted by Taxmann. Meet the experts online and ask your questions to get quick answers.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
};

export const Legal_Return_Policy = {
  metaTitle:
    "Taxmann’s Cancellation, Return & Refund Policy",
  metaDescription:
    "Check out the criteria for cancellation, return and refund for all products & services available on the official Taxmann website.",
  metaKeywords:
    "Cancellation Policy, Return Policy, Refund Policy",
  ogTitle:
    "Taxmann’s Cancellation, Return & Refund Policy",
  ogDescription:
    "Check out the criteria for cancellation, return and refund for all products & services available on the official Taxmann website.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Legal_Payment_Terms = {
  metaTitle:
    "Taxmann’s Payment Terms",
  metaDescription:
    "The payment terms for all products & services listed on the official website of Taxmann are henceforth stated. You can make the payment via cheque, demand draft, etc.",
  metaKeywords:
    "Payment Terms, Taxmann’s Payment Terms, Terms of Payment",
  ogTitle:
    "Taxmann’s Payment Terms",
  ogDescription:
    "The payment terms for all products & services listed on the official website of Taxmann are henceforth stated. You can make the payment via cheque, demand draft, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Legal_EULA = {
  metaTitle:
    "Taxmann’s End User Licence Agreement | EULA",
  metaDescription:
    "Check out Taxmann’s EULA, which consists of the grant of a licence, registration, account security, the various restrictions and rights, disclaimer, etc.",
  metaKeywords:
    "User End Licence Agreement, Taxmann’s EULA, EULA",
  ogTitle:
    "Taxmann’s End User Licence Agreement | EULA",
  ogDescription:
    "Check out Taxmann’s EULA, which consists of the grant of a licence, registration, account security, the various restrictions and rights, disclaimer, etc.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Legal_Disclaimer = {
  metaTitle:
    "Taxmann’s Legal Disclaimer",
  metaDescription:
    "Taxmann is a digital platform providing various services and products. By using this website, you agree to certain conditions that are legally binding.",
  metaKeywords:
    "Legal Disclaimer, Taxmann, Legal Policies, Terms & Conditions",
  ogTitle:
    "Taxmann’s Legal Disclaimer",
  ogDescription:
    "Taxmann is a digital platform providing various services and products. By using this website, you agree to certain conditions that are legally binding.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Legal_Privacy_Policy = {
  metaTitle:
    "Taxmann’s Privacy Policy",
  metaDescription:
    "Taxmann’s Privacy Policy enlists the rules and regulations of the official website of Taxmann per the Information Technology Act, 2000 and Rules made there under as applicable.",
  metaKeywords:
    "Privacy Terms, Privacy Policy, Taxmann’s Privacy Policy",
  ogTitle:
    "Taxmann’s Privacy Policy",
  ogDescription:
    "Taxmann’s Privacy Policy enlists the rules and regulations of the official website of Taxmann per the Information Technology Act, 2000 and Rules made there under as applicable.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const DEALER_JOIN = {
  metaTitle:
    "Become a Taxmann Dealer | Sell With Us",
  metaDescription:
    "Embark on a rewarding journey by joining the Taxmann dealers team. Start selling books in your local area and become a Taxmann partner with ease. Explore the simple steps to kickstart your success today.",
  metaKeywords:
    "Taxmann Dealer, Taxmann Bookstore, Taxmann Books, Book Store Near Me, Taxmann Book Dealers, Book Shop Near Me, Book Distributors, Book Dealers, Best Bookstores in Delhi, Taxmann Dealers in India, Taxmann Books Dealers in Delhi, Taxmann Books Dealers in Mumbai, Taxmann Dealers in Bengaluru, Taxmann Book Dealers in Ahmedabad, Taxmann Book Dealers in Chennai, Taxmann Book Dealers in Bhopal, Taxmann Book Dealers in Indore, Taxmann Book Dealers in Punjab, Taxmann Book Dealers in Hyderabad, Taxmann Book",
  ogTitle:
    "Taxmann Dealers | Sell With Us",
  ogDescription:
    "Join the Taxmann dealers team and start selling books in your area. Become a Taxmann partner in easy steps.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const DEALER_SEARCH = {
  metaTitle:
    "Taxmann Dealer Near You | Taxmann Books",
  metaDescription:
    "Discover the closest Taxmann dealer near you for purchasing our latest books. Explore our comprehensive list of dealers and representatives, and easily access their contact details on your screen.",
  metaKeywords:
    "Taxmann Dealer, Taxmann Bookstore, Taxmann Books, Book Store Near Me, Taxmann Book Dealers, Book Shop Near Me, Book Distributors, Book Dealers, Best Bookstores in Delhi, Taxmann Books Dealers in Mumbai, Taxmann Dealers in Bengaluru, Taxmann Book Dealers in Ahmedabad, Taxmann Book Dealers in Chennai, Taxmann Book Dealers in Bhopal, Taxmann Book Dealers in Indore, Taxmann Book Dealers in Punjab, Taxmann Book Dealers in Hyderabad, Taxmann Book Dealers in Haryana, Taxmann Book Dealers in Noida, Taxmann Book Dealers in Kolkata, Taxmann Book Dealers in Ghaziabad",
  ogTitle:
    "Find a Taxmann Dealer Near You",
  ogDescription:
    "Search the nearest Taxmann Dealer in your area to buy the latest books. Check our dealers and representatives’ list and get their details.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const DOWNLOAD_AND_INSTALL_ONLINE = {
  metaTitle:
    "Taxmann Online Setup Download | e-TDS Return, Offline Research Platform for Income Tax",
  metaDescription:
    "Easy and hassle-free online setup download. Get e-TDS Setup,GST Excuse and updated income tax platform research platform from Taxmann.",
  metaKeywords:
    "e-TDS Setup Download, Offline Research Platform Setup Download, Direct Tax Laws Online Setup Download, DTL Setup Download",
  ogTitle:
    "Taxmann Online Setup Download | e-TDS Return, Offline Research Platform for Income Tax",
  ogDescription:
    "Easy and hassle-free online setup download. Get e-TDS Setup,GST Excuse and updated income tax platform research platform from Taxmann.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const SOFTWARE_UPDATES = {
  metaTitle:
    "Service Packs | Software Updates | Taxmann",
  metaDescription:
    "We update Taxmann products as often as possible to make them faster and more reliable for you. Update your Taxmann product now and get the latest features, performance improvements, and more.",
  metaKeywords:
    "e-TDS Service Pack,  Downloads",
  ogTitle:
    "Service Packs | Software Updates | Taxmann",
  ogDescription:
    "We update Taxmann products as often as possible to make them faster and more reliable for you. Update your Taxmann product now and get the latest features, performance improvements, and more.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const SOFTWARE_TOOLS = {
  metaTitle:
    "Setup Tools Download | Taxmann",
  metaDescription:
    "Instant assistance regarding all your Taxmann products, categorized for your ease.",
  metaKeywords:
    "e-TDS access runtime setup, E-Payment utility-32 bit, E-Payment utility-64 bit, SQL Setup, SQL Management Studio 32bit, SQL Management Studio 64bit, Team viewer, Frame work clean up tool, Windows clean up tool, Crystal Report, .net framework 2.0 64 bit, .net framework 2.0 32 bit SP 1, .net framework 3.5, .net framework 4.0, .net framework 4.5, .net framework 4.5.2, .net framework 4.6, Remote Support",
  ogTitle:
    "Setup Tools Download | Taxmann",
  ogDescription:
    "Instant assistance regarding all your Taxmann products, categorized for your ease.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Bookstore_Author = {
  metaTitle: "Author A – Z | Find by Authors | Taxmann Authors",
  metaDescription: "Get Taxmann authentic, amended & updated books authored by renowned tax & corporate law professionals.",
  metaKeywords: "Taxmann Authors, Author Books, Singhania Tax, Datey, Pankaj Garg Audit, GST Books, Account Books, Law Books",
  ogTitle:
    "Author A – Z | Find by Authors | Taxmann Authors",
  ogDescription:
    "Get Taxmann authentic, amended & updated books authored by renowned tax & corporate law professionals.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const Bookstore_Cart = {
  metaTitle: "Shopping Cart – Taxmann.com | Students",
  metaDescription: "Explore your Taxmann.com | Student's shopping cart – your gateway to a seamless checkout experience. Review, add, or remove items with ease. Your one-stop destination for managing your Taxmann purchases effortlessly.",
  metaKeywords: "Shopping Cart, Bookstore Cart, Shopping Details, View Cart, Wishlist",
  ogTitle:
    "Shopping Cart, Book Cart, Taxmann Cart",
  ogDescription:
    "Explore your Taxmann.com | Student's shopping cart – your gateway to a seamless checkout experience. Review, add, or remove items with ease. Your one-stop destination for managing your Taxmann purchases effortlessly.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const SITEMAP = {
  metaTitle:
    "Taxmann.com – Sitemap | Your Navigation Guide",
  metaDescription:
    "Explore the Taxmann.com Sitemap for easy navigation through a wide range of taxation and legal resources, including the latest laws, case laws, articles, and professional tools",
  metaKeywords:
    "Sitemap, Taxmann Structure, Taxmann Sitemap",
  ogTitle:
    "Taxmann.com Sitemap | Your Navigation Guide",
  ogDescription:
    "Your helping guide for Taxmann.com. Navigate easily within Taxmann.com.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const Podcast_MainPage = {
  metaTitle: "Taxmann Podcast | Hands-Free Update on Tax & Corporate Laws.",
  metaDescription: "Stay informed on the latest developments in the Tax and Corporate World with the Taxmann Podcast. Receive updates effortlessly in a hands-free format, ensuring you stay updated on crucial information. Tune in now for insightful content delivered straight to you.",
  metaKeywords: "Taxmann Podcast, Tax Podcast, Accounting Podcast, Tax Talk, Tax Professionals Podcast, Tax Adviser Podcasts, Podcast for Tax Professionals, Best Podcast, Best Podcasts to listen to",
  ogTitle: "Taxmann Podcast | Hands-Free Update on Tax & Corporate Laws.",
  ogDescription: "Get the latest updates on the Tax and Corporate World delivered to you in a Hands-Free format. Tune in to the Taxmann Podcast and stay updated!",
  ogUrl: "",
  ogImage: "https://d3lzbbhyvqc4k.cloudfront.net/images/img/icon.png",
  ogType: "",
  ogSite_Name: "",
}

export const TOOLS = {
  metaTitle:
    "Tax & Corporate Law Tools by Taxmann | Work Done Differently",
  metaDescription:
    "Taxmann.com provides a comprehensive list of reliable tax and corporate law tools that are free to make your taxation and accounting easy. Check out the list now!",
  metaKeywords:
    "Tax Calculator, Income Tax Calculator, GST Rate Finder, EWay Bill Generator, EPS Calculator, Deferred Tax Calculator, Tax Tools",
  ogTitle:
    "Tax & Corporate Law Tools by Taxmann | Work Done Differently",
  ogDescription:
    "Taxmann.com provides a comprehensive list of reliable tax and corporate law tools that are free to make your taxation and accounting easy. Check out the list now!",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const BLOGS = {
  metaTitle:
    "Tax & Corporate Law Blogs by Taxmann",
  metaDescription:
    "Bringing you the latest & the most authentic tax & corporate law stories and analysis. Read now to stay updated with the latest news & updates",
  metaKeywords:
    "Taxmann Blog, Taxmann Write Up, Taxmann Analysis",
  ogTitle:
    "Tax & Corporate Law Blogs by Taxmann",
  ogDescription:
    "Bringing you the latest & the most authentic tax & corporate law stories and analysis. Read now to stay updated with the latest news & updates",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const FAQS = {
  metaTitle:
    "Frequently Asked Questions | Taxmann",
  metaDescription:
    "Have doubt about Taxmann’s products & services? Refer to Taxmann’s FAQs and learn all about our products and services.",
  metaKeywords:
    "Taxmann FAQs, Frequently Asked Questions",
  ogTitle:
    "Frequently Asked Questions | Taxmann",
  ogDescription:
    "Have doubt about Taxmann’s products & services? Refer to Taxmann’s FAQs and learn all about our products and services.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const CAREERS = {
  metaTitle:
    "Career at Taxmann",
  metaDescription:
    "Explore career opportunities at Taxmann and apply for vacancies to join our experienced and professional team.",
  metaKeywords:
    "Jobs, Career Opportunities, vacancies",
  ogTitle:
    "Career at Taxmann",
  ogDescription:
    "Explore career opportunities at Taxmann and apply for vacancies to join our experienced and professional team.",
  ogUrl: "",
  ogImage: "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/finance-act-imgs.webp",
  ogType: "",
  ogSite_Name: "",
}

export const FINANCE_ACT = {
  metaTitle:
    "Your Essentials on the Finance Act 2023 by Taxmann",
  metaDescription:
    "Taxmann's In-Print & Virtual Books have evolved over six decades to provide readers with authentic, accurate, and updated content. Discover a vast range of reliable resources in both physical and digital formats, covering a diverse range of topics, and stay up-to-date with the latest information in your field.",
  metaKeywords:
    "Best Selling Books on Income Tax, Best Books on Direct Taxes, Direct Tax Books, Direct Tax 2023 Books, Income Tax Statutes, Direct Tax Statues, Finance Act 2023 Books, Finance Act 2023 Publications, Finance Act 2023 Publications on Income Tax",
  ogTitle:
    "Your Essentials on the Finance Act 2023 by Taxmann",
  ogDescription:
    "Taxmann's In-Print & Virtual Books have evolved over six decades to provide readers with authentic, accurate, and updated content. Discover a vast range of reliable resources in both physical and digital formats, covering a diverse range of topics, and stay up-to-date with the latest information in your field.",
  ogUrl: "",
  ogImage: "https://d3lzbbhyvqc4k.cloudfront.net/images-webp/finance-act-imgs.webp",
  ogType: "",
  ogSite_Name: "",
}

export const SIGN_IN_PAGE = {
  metaTitle:
    "Secure Login | Access Your Taxmann.com Account",
  metaDescription:
    "Welcome back to Taxmann.com. Log in to Taxmann.com for unrivalled reliability and expertise.",
  metaKeywords:
    "Taxmann, Taxmann Login, Taxmann Account, Taxmann sign in, Login Account",
  ogTitle:
    "Secure Login | Access Your Taxmann.com Account",
  ogDescription:
    "Welcome back to Taxmann.com. Log in to Taxmann.com for unrivalled reliability and expertise.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const SIGN_OUT_PAGE = {
  metaTitle:
    "Logout Successful from Taxmann.com Account",
  metaDescription:
    "Safely log out from your Taxmann.com account. Sign in again whenever you're ready to access your account.",
  metaKeywords:
    "Logout, Sign Out, Account Security, Secure Logout, Taxmann.com",
  ogTitle:
    "Logout Successful from Taxmann.com Account",
  ogDescription:
    "Safely log out from your Taxmann.com account. Sign in again whenever you're ready to access your account.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const FORGOT_PASSWORD_PAGE = {
  metaTitle:
    "Forgot Password – Taxmann.com",
  metaDescription:
    "Forgot your Password? Easily reset and regain access to your Taxmann.com account. Follow simple steps for secure account recovery and seamless login.",
  metaKeywords:
    "Forgot Password, Password Recovery, Reset Password, Taxmann.com Login",
  ogTitle:
    "Forgot Password – Taxmann.com",
  ogDescription:
    "Forgot your Password? Easily reset and regain access to your Taxmann.com account. Follow simple steps for secure account recovery and seamless login.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MOBILE_VERIFICATION_PAGE = {
  metaTitle:
    "Secure Mobile Verification – Verify Your Identity on Taxmann.com",
  metaDescription:
    "Ensure the security of your Taxmann.com account with mobile verification. Safeguard your identity and enjoy a protected online experience. Verify seamlessly for added account protection.",
  metaKeywords:
    "Mobile Verification, Taxmann Account Security, Identity Verification, Account Protection, Verify Mobile",
  ogTitle:
    "Secure Mobile Verification – Verify Your Identity on Taxmann.com",
  ogDescription:
    "Ensure the security of your Taxmann.com account with mobile verification. Safeguard your identity and enjoy a protected online experience. Verify seamlessly for added account protection.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const CUSTOMIZED_INTEREST_PAGE = {
  metaTitle:
    "Tailor Your Experience – Customise Your Interests on Taxmann.com",
  metaDescription:
    "Personalise your Taxmann.com journey by customising your interests. Explore tailored content and enhance your experience. Take control of your preferences for a more engaging and relevant user experience.",
  metaKeywords:
    "Customise Interests, Personalised Experience, Taxmann Preferences, User Preferences",
  ogTitle:
    "Tailor Your Experience – Customise Your Interests on Taxmann.com",
  ogDescription:
    "Personalise your Taxmann.com journey by customising your interests. Explore tailored content and enhance your experience. Take control of your preferences for a more engaging and relevant user experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const PERSONAL_INFORMATION_PAGE = {
  metaTitle:
    "Manage Your Personal Information Securely | Taxmann.com",
  metaDescription:
    "Take control of your personal information on Taxmann.com. Update, edit, and manage your account details securely. Your privacy matters; we ensure a user-friendly experience for maintaining your data.",
  metaKeywords:
    "Personal Information, Account Settings, Privacy Management, Account Details, Update Information, Taxmann Account",
  ogTitle:
    "Manage Your Personal Information Securely | Taxmann.com",
  ogDescription:
    "Take control of your personal information on Taxmann.com. Update, edit, and manage your account details securely. Your privacy matters; we ensure a user-friendly experience for maintaining your data.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MANAGE_ADDRESS_PAGE = {
  metaTitle:
    "Effortlessly Manage Your Addresses | Taxmann.com",
  metaDescription:
    "Simplify your experience on Taxmann.com by efficiently managing your addresses. Update, add, or remove addresses easily through our user-friendly address settings. Streamline your account for seamless transactions.",
  metaKeywords:
    "Manage Addresses, Address Settings, Update Address, Account Management, Taxmann Addresses",
  ogTitle:
    "Effortlessly Manage Your Addresses | Taxmann.com",
  ogDescription:
    "Simplify your experience on Taxmann.com by efficiently managing your addresses. Update, add, or remove addresses easily through our user-friendly address settings. Streamline your account for seamless transactions.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const GSTIN_DETAILS_PAGE = {
  metaTitle:
    "Update Your GSTIN Details Securely | Taxmann.com",
  metaDescription:
    "Ensure accuracy in your tax information. Easily update and manage your GSTIN details on Taxmann.com. We prioritise the security of your GST information for a seamless taxation experience.",
  metaKeywords:
    "GSTIN Details, Update GSTIN, Tax Information, Account Settings, GST Number, Secure GST Management",
  ogTitle:
    "Update Your GSTIN Details Securely | Taxmann.com",
  ogDescription:
    "Ensure accuracy in your tax information. Easily update and manage your GSTIN details on Taxmann.com. We prioritise the security of your GST information for a seamless taxation experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const MANAGE_INTEREST_PAGE = {
  metaTitle:
    "Tailor Your Experience – Manage Your Interests | Taxmann.com",
  metaDescription:
    "Customise your Taxmann.com experience by managing your interests. Update preferences effortlessly to receive personalised content. Take control of your account for a more tailored and relevant user experience.",
  metaKeywords:
    "Manage Interests, Account Preferences, Customise Experience, User Preferences, Taxmann Account",
  ogTitle:
    "Tailor Your Experience – Manage Your Interests | Taxmann.com",
  ogDescription:
    "Customise your Taxmann.com experience by managing your interests. Update preferences effortlessly to receive personalised content. Take control of your account for a more tailored and relevant user experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const CHANGE_PASSWORD_PAGE = {
  metaTitle:
    "Securely Update Your Password | Taxmann.com",
  metaDescription:
    "Enhance your account security with ease. Change your Taxmann.com password securely to strengthen your account protection. Prioritise your online safety with a simple password update.",
  metaKeywords:
    "Change Password, Password Update, Account Security, Secure Password, Taxmann Account",
  ogTitle:
    "Securely Update Your Password | Taxmann.com",
  ogDescription:
    "Enhance your account security with ease. Change your Taxmann.com password securely to strengthen your account protection. Prioritise your online safety with a simple password update.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const USER_SUBSCRIPTION_PAGE = {
  metaTitle:
    "Explore Your Subscriptions – Manage Services on Taxmann.com",
  metaDescription:
    "Stay in control of your subscriptions with Taxmann.com. Easily manage and customise your services. Explore a seamless platform for monitoring and updating your subscription.",
  metaKeywords:
    "Subscriptions, Subscription Control, Taxmann Subscribed Content",
  ogTitle:
    "Explore Your Subscriptions – Manage Services on Taxmann.com",
  ogDescription:
    "Stay in control of your subscriptions with Taxmann.com. Easily manage and customise your services. Explore a seamless platform for monitoring and updating your subscription.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const ORDER_DETAILS_PAGE = {
  metaTitle:
    "Track Your Orders | Taxmann.com",
  metaDescription:
    "Effortlessly monitor and manage your orders at Taxmann.com. Track delivery, view order history, and ensure a smooth buying experience: your satisfaction is our priority.",
  metaKeywords:
    "Order Tracking, Manage Purchases, Taxmann Orders, Order Status, Order History, Purchase Management",
  ogTitle:
    "Track Your Orders | Taxmann.com",
  ogDescription:
    "Effortlessly monitor and manage your orders at Taxmann.com. Track delivery, view order history, and ensure a smooth buying experience: your satisfaction is our priority.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const ORDER_TRACK_PAGE = {
  metaTitle:
    "Real-Time Order Tracking – Stay Informed with Your Taxmann Purchase",
  metaDescription:
    "Experience real-time tracking of your Taxmann.com order. Stay informed about the status and location of your purchase. Enjoy a transparent and hassle-free order tracking process.",
  metaKeywords:
    "Tracking Order, Real-Time Tracking, Order Status, Taxmann Purchase, Order Location",
  ogTitle:
    "Real-Time Order Tracking – Stay Informed with Your Taxmann Purchase",
  ogDescription:
    "Experience real-time tracking of your Taxmann.com order. Stay informed about the status and location of your purchase. Enjoy a transparent and hassle-free order tracking process.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MY_LIBRARY_PAGE = {
  metaTitle:
    "Explore Your Taxmann Library – Access Premium Resources",
  metaDescription:
    "Unlock a wealth of knowledge in your Taxmann.com Library—access premium resources, including books, articles, and study materials. Elevate your learning experience with our extensive library.",
  metaKeywords:
    "Taxmann Library, Premium Resources, Knowledge Access, Learning Experience, Extensive Library",
  ogTitle:
    "Explore Your Taxmann Library – Access Premium Resources",
  ogDescription:
    "Unlock a wealth of knowledge in your Taxmann.com Library—access premium resources, including books, articles, and study materials. Elevate your learning experience with our extensive library.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const MY_WISHLIST_PAGE = {
  metaTitle:
    "Build Your Taxmann.com Wishlist – Curate Your Desired Resources",
  metaDescription:
    "Create a personalised Wishlist on Taxmann.com. Curate the resources you desire and plan your future purchases. Keep track of your favourite items for a convenient and tailored shopping experience.",
  metaKeywords:
    "Wishlist, Taxmann Wishlist, Favorite Items, Shopping Experience, Personalized Wishlist",
  ogTitle:
    "Build Your Taxmann.com Wishlist – Curate Your Desired Resources",
  ogDescription:
    "Create a personalised Wishlist on Taxmann.com. Curate the resources you desire and plan your future purchases. Keep track of your favourite items for a convenient and tailored shopping experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const WALLET_PAGE = {
  metaTitle:
    "Manage Your Wallet at Taxmann.com",
  metaDescription:
    "Effortlessly see your Wallet at Taxmann.com. Monitor your balance transactions and enjoy seamless payments. Experience a convenient and secure financial hub.",
  metaKeywords:
    "Taxmann Wallet, Manage Finances, Seamless Payments, Convenient Finance",
  ogTitle:
    "Manage Your Wallet at Taxmann.com",
  ogDescription:
    "Effortlessly see your Wallet at Taxmann.com. Monitor your balance transactions and enjoy seamless payments. Experience a convenient and secure financial hub.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const REFER_AND_EARN_PAGE = {
  metaTitle:
    "Earn Rewards with Taxmann.com – Refer and Earn Program",
  metaDescription:
    "Join the Taxmann.com Refer and Earn program to unlock exciting rewards. Refer friends and colleagues to enjoy benefits. Explore a rewarding way to share the Taxmann.com experience.",
  metaKeywords:
    "Refer and Earn, Rewards Program, Taxmann Referral, Share and Earn, Referral Program",
  ogTitle:
    "Earn Rewards with Taxmann.com – Refer and Earn Program",
  ogDescription:
    "Join the Taxmann.com Refer and Earn program to unlock exciting rewards. Refer friends and colleagues to enjoy benefits. Explore a rewarding way to share the Taxmann.com experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MY_NEWSLETTER_PAGE = {
  metaTitle:
    "Stay Informed – Manage Your Taxmann.com Newsletter Preferences",
  metaDescription:
    "Customise your Taxmann.com newsletter experience. Stay informed on your terms by managing your newsletter preferences. Receive updates tailored to your interests for an enhanced information flow.",
  metaKeywords:
    "Manage Newsletter, Taxmann Newsletter Preferences, Personalised Newsletter",
  ogTitle:
    "Stay Informed – Manage Your Taxmann.com Newsletter Preferences",
  ogDescription:
    "Customise your Taxmann.com newsletter experience. Stay informed on your terms by managing your newsletter preferences. Receive updates tailored to your interests for an enhanced information flow.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const CHECKOUT_PAGE = {
  metaTitle:
    "Secure Checkout Experience – Complete Your Taxmann.com Purchase",
  metaDescription:
    "Enjoy a secure and seamless checkout process on Taxmann.com. Complete your purchase with confidence and explore hassle-free transactions. Your satisfaction is our top priority.",
  metaKeywords:
    "Checkout, Secure Checkout, Taxmann Purchase, Hassle Free Transactions, Complete Purchase",
  ogTitle:
    "Secure Checkout Experience – Complete Your Taxmann.com Purchase",
  ogDescription:
    "Enjoy a secure and seamless checkout process on Taxmann.com. Complete your purchase with confidence and explore hassle-free transactions. Your satisfaction is our top priority.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const PAYMENT_PAGE = {
  metaTitle:
    "Secure Payments – Explore Convenient Options on Taxmann",
  metaDescription:
    "Discover secure and convenient payment options on Taxmann.com—experience hassle-free transactions with various payment methods. Your financial security is our priority.",
  metaKeywords:
    "Payment Options, Secure Payments, Taxmann Payment Methods, Hassle Free Transactions, Convenient Payments, Secure Payment Options",
  ogTitle:
    "Secure Payments – Explore Convenient Options on Taxmann",
  ogDescription:
    "Discover secure and convenient payment options on Taxmann.com—experience hassle-free transactions with various payment methods. Your financial security is our priority.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const PAYMENT_SUCCESS_PAGE = {
  metaTitle:
    "Order Success – Taxmann Thanks You",
  metaDescription:
    "Congratulations on your successful order with Taxmann.com! Thank you for choosing us. Explore our premium products and enjoy a seamless shopping experience.",
  metaKeywords:
    "Order Success, Successful Purchase, Taxmann Order, Order Confirmation",
  ogTitle:
    "Order Success – Taxmann Thanks You",
  ogDescription:
    "Congratulations on your successful order with Taxmann.com! Thank you for choosing us. Explore our premium products and enjoy a seamless shopping experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}
export const PAYMENT_FAILED_PAGE = {
  metaTitle:
    "Order Unsuccessful – Taxmann.com",
  metaDescription:
    "Sorry to hear your order didn't go through. Explore alternatives or check your payment information on Taxmann. We're here to assist you with a seamless shopping experience.",
  metaKeywords:
    "Order Failed, Unsuccessful Purchase, Taxmann Order Update, Failed Transaction",
  ogTitle:
    "Order Unsuccessful – Taxmann.com",
  ogDescription:
    "Sorry to hear your order didn't go through. Explore alternatives or check your payment information on Taxmann. We're here to assist you with a seamless shopping experience.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetHome = {
  metaTitle: "Union Budget 2024 – Live Updates | Key Highlights",
  metaDescription: "Union Budget 2024-25 — Stay updated with live announcements and comprehensive coverage of the Union Budget 2024-25 on Taxmann.com. Get the latest insights on the Railway Budget, Income Tax Slab Rate, Budget Expectations, and more. Discover an in-depth analysis of the Budget speech by FM Nirmala Sitharaman, including key highlights, expert commentary, and more.",
  metaKeywords: "Budget 2024, India Budget 2024-25, Indian Budget, Budget Date, Rail Budget, Railway Budget, Income Tax Slabs, Budget Speech, FM Nirmala Sitharaman, Union Budget 2024, India Budget 2024-25 Live Announcements, budget tax expectations, post-budget markets performance, economy-budget, budget stock market expectations, Budget 2024 expectations, union budget 2024 expectations",
  ogTitle: "Union Budget 2024 – Live Updates | Key Highlights",
  ogDescription: "Union Budget 2024-25 — Stay updated with live announcements and comprehensive coverage of the Union Budget 2024-25 on Taxmann.com. Get the latest insights on the Railway Budget, Income Tax Slab Rate, Budget Expectations, and more. Discover an in-depth analysis of the Budget speech by FM Nirmala Sitharaman, including key highlights, expert commentary, and more.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetStoreisAll = {
  metaTitle: "Budget Stories – LIVE Stories on Union Budget | Taxmann",
  metaDescription: "Stay current on India Budget 2024 with all the news, updates, and expert opinions on special stories, exclusively on Taxmann",
  metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
  ogTitle: "Budget Stories – LIVE Stories on Union Budget | Taxmann",
  ogDescription: "Stay current on India Budget 2024 with all the news, updates, and expert opinions on special stories, exclusively on Taxmann",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetExpertSpeak = {
  metaTitle: "Union Budget 2024 – In-Depth Analysis by India's Leading Tax Experts and Firms",
  metaDescription: "Explore an in-depth analysis of the Union Budget 2024 from India's leading tax and corporate law experts—access expert insights on budget expectations, detailed post-budget reviews, and key highlights for informed corporate decisions.",
  metaKeywords: "Budget 2024, Union Budget, Live Budget, Budget news, Union Budget 2024, Budget Date, India Budget 2024",
  ogTitle: "Union Budget 2024 – In-Depth Analysis by India's Leading Tax Experts and Firms",
  ogDescription: "Explore an in-depth analysis of the Union Budget 2024 from India's leading tax and corporate law experts—access expert insights on budget expectations, detailed post-budget reviews, and key highlights for informed corporate decisions.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetDownloadDocument = {
  metaTitle: "Download Budget 2024-25 Highlights | Taxmann",
  metaDescription: "Get highlights of Union Budget 2024, including key takeaways, important points, summaries, and the latest news updates on budget allocations, new and old tax regimes, income tax slab changes, Section 80C, 80D, standard deduction, GDP growth, surcharge rates, and more.",
  metaKeywords: "Budget News, Budget 2024, Budget Date, Budget 2024 News, Budget 2024 Expectations, Union Budget 2024, Union Budget 2024 Date, Modi Government, Nirmala Sitharaman, Budget Headlines, Budget, Budget 2024 Predictions, Budget of India, Union Budget of India",
  ogTitle: "Download Budget 2024-25 Highlights | Taxmann",
  ogDescription: "Get highlights of Union Budget 2024, including key takeaways, important points, summaries, and the latest news updates on budget allocations, new and old tax regimes, income tax slab changes, Section 80C, 80D, standard deduction, GDP growth, surcharge rates, and more.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetArchives = {
  metaTitle: "Budget Archives – Budget Speech and Finance (No. 2) Bill 2024 | Taxmann",
  metaDescription: "Access the Budget Speech and Finance Bill of India for all years since 1947. Click to download past Budget speeches and Finance Bills on Taxmann.com.",
  metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
  ogTitle: "Budget Archives – Budget Speech and Finance (No. 2) Bill 2024 | Taxmann",
  ogDescription: "Access the Budget Speech and Finance Bill of India for all years since 1947. Click to download past Budget speeches and Finance Bills on Taxmann.com.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export const MetaBudgetSpeech = {
  metaTitle: "Union Budget 2024 – In-Depth Analysis by India's Leading Tax Experts and Firms",
  metaDescription: "Explore an in-depth analysis of the Union Budget 2024 from India's leading tax and corporate law experts—access expert insights on budget expectations, detailed post-budget reviews, and key highlights for informed corporate decisions.",
  metaKeywords: "Budget 2024, Union Budget, Live Budget, Budget news, Union Budget 2024, Budget Date, India Budget 2024",
  ogTitle: "Union Budget 2024 – In-Depth Analysis by India's Leading Tax Experts and Firms",
  ogDescription: "Explore an in-depth analysis of the Union Budget 2024 from India's leading tax and corporate law experts—access expert insights on budget expectations, detailed post-budget reviews, and key highlights for informed corporate decisions.",
  ogUrl: "",
  ogImage: "",
  ogType: "",
  ogSite_Name: "",
}

export class MetaBudgetStory {
  static getMetaBudgetStory(categoryId) {
    let list;
    switch (categoryId) {
      case '1':
        list = [
          {
            metaTitle: "Latest Finance (No. 2) Bill 2024 | Taxmann",
            metaDescription: "Download or read the Finance (No. 2) Bill 2024. Get detailed insights into new proposals, tax amendments, and their implications for individuals and businesses, covering changes to the Income-tax Act 1961, Goods and Services Tax, Customs, and more.",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Latest Finance (No. 2) Bill 2024 | Taxmann",
            ogDescription: "Download or read the Finance (No. 2) Bill 2024. Get detailed insights into new proposals, tax amendments, and their implications for individuals and businesses, covering changes to the Income-tax Act 1961, Goods and Services Tax, Customs, and more.",
          },
        ];
        break;
      case '2':
        list = [
          {
            metaTitle: "Get Budget Update and Analysis | Taxmann",
            metaDescription: "Explore in-depth budget analysis and key highlights, comparing the current budget approach with previous years. Get detailed insights and trends to understand the fiscal direction and impact on various sectors.",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Get Budget Update and Analysis | Taxmann",
            ogDescription: "Explore in-depth budget analysis and key highlights, comparing the current budget approach with previous years. Get detailed insights and trends to understand the fiscal direction and impact on various sectors.",
          },
        ];
        break;
      case '3':
        list = [
          {
            metaTitle: "Memorandum Explaining the Provisions in the Finance (No. 2) Bill 2024 | Taxmann",
            metaDescription: "Explore the Memorandum Explaining the Provisions in the Finance (No. 2) Bill 2024. Discover key features of the Budget, detailed explanations, and expert insights only on Taxmann.com",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Memorandum Explaining the Provisions in the Finance (No. 2) Bill 2024 | Taxmann",
            ogDescription: "Explore the Memorandum Explaining the Provisions in the Finance (No. 2) Bill 2024. Discover key features of the Budget, detailed explanations, and expert insights only on Taxmann.com",
          },
        ];
        break;
      case '4':
        list = [
          {
            metaTitle: "Budget 2024-25 Speech – FM Sitharaman's budget speech | Taxmann",
            metaDescription: "Access the complete Budget Speech of Finance Minister Nirmala Sitharaman for 2024-25. Click to download the Union Budget 2024-25 Speech only at Taxmann.com",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Budget 2024-25 Speech – FM Sitharaman's budget speech | Taxmann",
            ogDescription: "Access the complete Budget Speech of Finance Minister Nirmala Sitharaman for 2024-25. Click to download the Union Budget 2024-25 Speech only at Taxmann.com",
          },
        ];
        break;
      default:
        list = [
          {
            metaDescription: "",
            metaKeywords: "",
          },
        ];
        break;
    }
    return list;
  }
}

export class MetaBudgetHighlights {
  static getMetaBudgetHighlights(categoryId) {
    let list;
    switch (categoryId) {
      case 'indiv':
        list = [
          {
            metaTitle: "Union Budget 2024 – Highlights for Individuals",
            metaDescription: "Stay informed with Taxmann's insights on the Union Budget 2024-25. Learn about key changes impacting individuals, including updates on tax regimes for firms and LLPs, revised TDS rates, exemptions under Section 80-IAC, capital gains under Section 112A, and more.",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Union Budget 2024 – Highlights for Individuals",
            ogDescription: "Stay informed with Taxmann's insights on the Union Budget 2024-25. Learn about key changes impacting individuals, including updates on tax regimes for firms and LLPs, revised TDS rates, exemptions under Section 80-IAC, capital gains under Section 112A, and more.",
          },
        ];
        break;
      case 'corp':
        list = [
          {
            metaTitle: "Union Budget 2024 – Highlights for Corporates",
            metaDescription: "Discover key highlights of Budget 2024 for corporates. Get the latest updates on reforms and announcements and understand their impacts on various sectors with expert analysis. ",
            metaKeywords: "Budget 2024, Union Budget, Live Budget, Budget news, Union Budget 2024, Budget Date, India Budget 2024",
            ogTitle: "Union Budget 2024 – Highlights for Corporates",
            ogDescription: "Discover key highlights of Budget 2024 for corporates. Get the latest updates on reforms and announcements and understand their impacts on various sectors with expert analysis. ",
          },
        ];
        break;
      case 'trust':
        list = [
          {
            metaTitle: "Union Budget 2024 – Expectations for Trusts",
            metaDescription: "Stay informed on the Union Budget 2024-25 impact on Trusts, including potential changes in Section 10(23C) sub-clauses (iiiad) & (iiiae), LTCG for business trusts, SPV definitions in Income Tax Act, and SEBI regulations, loss pass-through for business and securitization trusts, and more.",
            metaKeywords: "Budget 2024, Union Budget, Live Budget, Budget news, Union Budget 2024, Budget Date, India Budget 2024",
            ogTitle: "Union Budget 2024 – Expectations for Trusts",
            ogDescription: "Stay informed on the Union Budget 2024-25 impact on Trusts, including potential changes in Section 10(23C) sub-clauses (iiiad) & (iiiae), LTCG for business trusts, SPV definitions in Income Tax Act, and SEBI regulations, loss pass-through for business and securitization trusts, and more.",
          },
        ];
        break;
      default:
        list = [
          {
            metaTitle: "Budget 2024 Highlights – Key Changes for Individuals | Corporates | Trusts",
            metaDescription: "Discover comprehensive budget highlights focusing on individuals, corporates, and trusts. Get expert analysis and insights on the latest updates impacting these sectors.",
            metaKeywords: "Budget 2024, India Budget 2024-25, Union Budget 2024 expectations, Union Budget 2024, India Budget 2024-25 Live Announcements, Budget Speech, FM Nirmala Sitharaman",
            ogTitle: "Budget 2024 Highlights – Key Changes for Individuals | Corporates | Trusts",
            ogDescription: "Discover comprehensive budget highlights focusing on individuals, corporates, and trusts. Get expert analysis and insights on the latest updates impacting these sectors.",
          },
        ];
        break;
    }
    return list;
  
}
}
