import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MANAGE_NEWSLETTER_API, TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { APIResponse } from 'src/app/models/interfaces/api-response';
import { HttpHelperService } from 'src/app/service/http-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { BookstoreUrl } from 'src/app/api-url/bookstore-url.enum';

@Injectable()
export class UserService {

  userData = new BehaviorSubject<any>(null);
  userAction = new BehaviorSubject<any>(null);
  fetchUserInfo = new Subject<any>();

  constructor(private _httpHelperService: HttpHelperService, private globalUtility: GlobalUtility) { }

  getUserInfo(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.USER_INFO);
    return this._httpHelperService.get(url, {}, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getUserInfoWithToken(token): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.USER_INFO);
    return this._httpHelperService.getWithToken(url, {}, token)
     // .pipe(map(this.globalUtility.filterResponse));
  }

  addPhoto(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.ADD_PHOTO);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  editUserName(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.EDIT_USER_NAME);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  generateOTP(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GENERATE_OTP);
    return this._httpHelperService.postMethodWithoutErrorHandling(url, data, true)
      // .pipe(map(this.globalUtility.filterResponse));
  }

  verifyOTP(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.VERIFY_OTP);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  updatePersonalInfo(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.UPDATE_PERSONAL_INFO);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  deleteGstin(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.DELETE_GSTIN);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  addUpdateGstin(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_GSTIN_DETAIL);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getEntityTypes(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.All_ENTITY_TYPES);
    return this._httpHelperService.get(url, {}, true)
      .pipe(map(this.globalUtility.filterResponse));
  }


  changePassword(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.CHANGE_PASSWORD);
    return this._httpHelperService.put(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  deleteAddress(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.DELETE_ADDRESS);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  putStateProfessionAoiData(data): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.PUT_STATE_PROFESSION_AOI);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getStateList(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GET_INDIAN_STATE);
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  addGSTINFile(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.ADD_GSTIN_FILE);
    return this._httpHelperService.postFile(url, data, true, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

  addMyAccountGstin(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_GST_INFO);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getCountrySTDCodes(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GET_COUNTRY_CODES);
    return this._httpHelperService.get(url, {}, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  addMyGstinNotAvailableData(data: any): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_GST_INFO_NOT_AVAILABLE);
    return this._httpHelperService.post(url, data, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getRefereeDetails(): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.REFEREE_DEATAILS);
    return this._httpHelperService.get(url, {}, true)
      .pipe(map(this.globalUtility.filterResponse));
  }


  getNewsletterList(){
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.NEWSLETTER);
    return this._httpHelperService.get(url , {} , true)

  }
  getNewsLetterSettings(data: any){
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.NEWSLETTER_SETTING);
    return this._httpHelperService.get(url , data , true)
  }

  postNewsLettersToggle(data:any){
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.NEWSLETTERTOGGLE);
    return this._httpHelperService.post(url , data , true)
  }
  
  postNewsLetterSettings(data:any){
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.NEWSLETTER_SETTING);
    return this._httpHelperService.post(url , data , true)
  }
  NewsletterDefaultsetting(data: any): Observable<any> {
		let url = this.globalUtility.makeQaRedisUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_DEFAULT_SETTING)
		return this._httpHelperService.post(url, data, true)
	}

	getNewsLetterConsentFlag(): Observable<any> {
		let url = this.globalUtility.makeQaRedisUrl(MANAGE_NEWSLETTER_API.NEWLETTER_CONSENT_FLAG)
		return this._httpHelperService.get(url, {}, true)
	}
	
	postNewsletterOptout(){
		let url = this.globalUtility.makeQaRedisUrl(MANAGE_NEWSLETTER_API.NEWSLETTER_OPT_OUT)
		return this._httpHelperService.post(url, {}, true)
	}

  postAnalyticalDigestToggle(data:any){
    let url = this.globalUtility.makeEndmileAPItrackUrl(TaxmannUrl.ANALYTICAL_DIGEST_TOGGLE);
    return this._httpHelperService.post(url , data , false)
  }

  postAnalyticalDigestToggleToDB(data:any){
    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.ANALYTICAL_DIGEST_TOGGLE_DB);
    return this._httpHelperService.post(url , data , true)
  }

  getPersonalInfo(): Observable<APIResponse> {
    let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.PERSONAL_INFO);
    return this._httpHelperService.get(url, {}, true)
      .pipe(map(this.globalUtility.filterResponse));
  }

  getPersonalInfoWithToken(token): Observable<APIResponse> {
    let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.PERSONAL_INFO);
    return this._httpHelperService.getWithToken(url, {}, token)
     // .pipe(map(this.globalUtility.filterResponse));
  }

  getUserAddress(): Observable<APIResponse> {
    let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.USER_ADDRESS);
    return this._httpHelperService.get(url, {}, true)
  }

  getUserAddressWithToken(token): Observable<APIResponse> {
    let url = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.USER_ADDRESS);
    return this._httpHelperService.getWithToken(url, {}, token)
  }

  getLinkeddevicesApi(params:any){

    let url = this.globalUtility.makeRedisUrl(TaxmannUrl.LINKED_DEVICES_SESSION + (params ? params : ''));
		return this._httpHelperService.get(url, {}, true)
		
  }

  getStateProfessionAOIData(endpoint?: string): Observable<APIResponse> {
    let url = this.globalUtility.makeUrl(TaxmannUrl.GET_STATE_PROFESSION_AOI);
    if (endpoint) {
      url = `${url}/${endpoint}`
    }
    return this._httpHelperService.get(url, {}, false)
      .pipe(map(this.globalUtility.filterResponse));
  }

}

