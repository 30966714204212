import { ModuleWithProviders, Injectable } from "@angular/core";
import {
	PreloadingStrategy,
	Route,
	RouterModule,
	Routes,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthGuard } from "./taxmann-guard/auth.guard";
import { ThemeGuard } from "./taxmann-guard/theme.guard";
import { TitleGuard } from "./taxmann-guard/title.guard";
import { BrowserAllowedGuard } from "./taxmann-guard/browserAllow.guard";
import { BrowserGuardComponent } from "./browser-guard/browser-guard.component";
import { ReturnPolicyComponent } from "./taxmann-legal/component/return-policy/return-policy.component";
import { PrivacyPolicyComponent } from "./taxmann-legal/component/privacy-policy/privacy-policy.component";
import { PaymentTermsComponent } from "./taxmann-legal/component/payment-terms/payment-terms.component";
import { EULAComponent } from "./taxmann-legal/component/eula/eula.component";
import { DisclamierComponent } from "./taxmann-legal/component/disclamier/disclamier.compnent";
import { RestrictAuthGuard } from "./taxmann-guard/restrict-auth.guard";
import { AditComponent } from "./bookstore/adit/adit.component";

@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
	preload(route: Route, load: Function): Observable<any> {
		return route.data && route.data.preload ? load() : of(null);
	}
}

const routes: Routes = [
	// {
	// 	path: "",
	// 	loadChildren: () =>
	// 		import("./modules/taxmann-home/taxmann-home.module").then(
	// 			(m) => m.TaxmannHomeModule
	// 		),
	// 	canActivate: [ThemeGuard, ResearchUnsignedGuard],
	// },
	// {
	// 	path: "",
	// 	redirectTo: "/budget",
	// 	pathMatch: "full",
	// },	
		{
		path: "home",
		loadChildren: () =>
			import("./v2/homepage/homepage.module").then(
				(m) => m.HomepageModule
			),
		//canActivate: [ThemeGuard, RestrictAuthGuard],
	},
	 {
	 	path: "ppc/research",
	 	loadChildren: () =>
	 		import("./v2/homepage/research-homepage-ppc/research-homepage-ppc.module").then(
	 			(m) => m.ResearchHomepagePPCModule
	 		),
			 data: { theme: "research" },
			 canActivate: [ThemeGuard],	 
	 },
	 {
	 	path: "ppc/research/thank-you",
	 	loadChildren: () =>
	 		import("./v2/homepage/research-homepage-ppc-thank-you/research-homepage-ppc-thank-you.module").then(
	 			(m) => m.ResearchHomepagePPCThankYOuModule
	 		),
			 data: { theme: "research" },
			 canActivate: [ThemeGuard],	 
	 },
	// {
	// 	path: "ppc/practice",
	// 	loadChildren: () =>
	// 		import("./tax-practice/components/tax-practice-home-page-ppc/tax-practice-home-page-ppc.module").then(
	// 			(m) => m.TaxPracticeHomePagePPCModule
	// 		),
	// 		data: { theme: "practice" },
	// 	canActivate: [ThemeGuard],
	// },
	{
		path: "ppc/practice",
		loadChildren: () =>
			import("./tax-practice/ppc-home/ppc-home.module").then(
				(m) => m.PpcHomeModule
			),
			data: { theme: "practice" },
		canActivate: [ThemeGuard],
	},
	{
		path: "ppc/practice/thank-you",
		loadChildren: () =>
			import("./tax-practice/ppc-home/ppc-home.module").then(
				(m) => m.PpcHomeModule
			),
			data: { theme: "practice" },
		canActivate: [ThemeGuard],
	},
	// {
	// 	path: "ppc/practice/thank-you",
	// 	loadChildren: () =>
	// 		import("./tax-practice/components/tax-practice-home-thankyou-page-ppc/tax-practice-home-thankyou-page-ppc.module").then(
	// 			(m) => m.TaxPracticeHomeThankYouPagePPCModule
	// 		),
	// 		data: { theme: "practice" },
	// 		canActivate: [ThemeGuard],	
	// },
	{
		path : "",
		loadChildren: () =>
		import("./v2/homepage/homepage.module").then(
			(m) => m.HomepageModule
		),
		canActivate: [ RestrictAuthGuard],
	},
	{
		path : "",
		redirectTo: "/home",
		pathMatch : 'full'
	},
	// {
	// 	path : "finance-act",
	// 	redirectTo: "/bookstore/product/1/0/professional-books/finance%20act%202023?searchtext=finance%20act%202023&subject=Direct%2520Tax%2520Laws&yearOfPublication=March%25202023-%257C-February%25202023-%257C-January%25202023-%257C-December%25202022",
	// 	pathMatch : 'full'
	// },
	// {
	// 	path: "auth",
	// 	loadChildren: () =>
	// 		import("./auth/auth.module").then((m) => m.AuthModule),
	// 	canActivate: [ThemeGuard],
	// },
	// {
	// 	path: "user",
	// 	canActivate: [AuthGuard, ThemeGuard],
	// 	loadChildren: () =>
	// 		import("./modules/user/user.module").then((m) => m.UserModule),
	// },
	// {
	// 	path: "bookstore",
	// 	loadChildren: () =>
	// 		import("./bookstore/bookstore.module").then(
	// 			(m) => m.BookstoreModule
	// 		),
	// 	data: { preload: true, theme: "bookstore", title: "Taxmann Bookstore" },
	// 	canActivate: [ThemeGuard, TitleGuard],
	// },
	{
		path: "webinar",
		redirectTo: "webinars",
	},
	{
		path: "webinars",
		loadChildren: () =>
			import(
				"./modules/webinar/webinar-latest/webinar-latest.module"
			).then((m) => m.WebinarLatestModule),
		data: { theme: "bookstore", title: "" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "compliance-etds",
		loadChildren: () =>
			import("./modules/etdshome/etdshome.module").then(
				(m) => m.ETDSHomeModule
			),
		data: { theme: "etds", title: "" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "compliance-etds-calendar",
		loadChildren: () =>
			import("./modules/etds-schedule/etds-schedule.module").then(
				(m) => m.ETDSScheduleModule
			),
		data: { theme: "etds", title: "" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	// {
	// 	path: "newlogin",
	// 	loadChildren: () =>
	// 		import("./modules/newlogin/newlogin.module").then(
	// 			(m) => m.NewloginModule
	// 		),
	// 	data: { theme: "bookstore", title: "" },
	// 	canActivate: [ThemeGuard, TitleGuard],
	// },
	{
		path: "bookstore/product-catalogues",
		loadChildren: () =>
			import(
				"./modules/product-catalogues/product-catalogues.module"
			).then((m) => m.ProductCataloguesModule),
		data: { theme: "bookstore", title: "Taxmann Bookstore" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "bookstore/dealer-catalogues",
		loadChildren: () =>
			import("./modules/dealer-catalogues/dealer-catalogues.module").then(
				(m) => m.DealerCataloguesModule
			),
		data: { theme: "bookstore", title: "Taxmann Bookstore" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "dealer",
		loadChildren: () =>
			import("./dealer/dealer.module").then((m) => m.DealerModule),
	},
	{
		path: "contactus",
		loadChildren: () =>
			import("./modules/contactus/contactus.module").then(
				(m) => m.ContactusModule
			),
		data: { theme: "bookstore", title: "Contact Us" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "faqs",
		loadChildren: () =>
			import("./modules/faqs/faqs.module").then((m) => m.FaqsModule),
		data: { theme: "bookstore", title: "FAQ- Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "research",
		loadChildren: () =>
			import("./research/research.module").then((m) => m.ResearchModule),
		data: { preload: true, theme: "research" },
		canActivate: [ThemeGuard],
	},
	{
		path: "preview-document",
		loadChildren: () =>
			import("./modules/preview-url/preview-url.module").then(
				(m) => m.PreviewUrlModule
			),
		data: { theme: "research" },
		canActivate: [ThemeGuard],
	},
	{
		path: "api-preview-document",
		loadChildren: () =>
			import("./api-preview-document-url/api-preview-document-url.module").then(
				(m) => m.ApiPreviewDocumentUrlModule
			),
		data: { theme: "research" },
		canActivate: [ThemeGuard],
	},
	{
		path: "tools",
		loadChildren: () =>
			import("./tools/tools.module").then((m) => m.ToolsModule),
		data: { theme: "bookstore", title: "Tools- Taxmann " },
		canActivate: [ThemeGuard, TitleGuard],
	},
	// {
	// 	path: "legal",
	// 	loadChildren: () =>
	// 		import("./taxmann-legal/taxmann-legal.module").then(
	// 			(m) => m.TaxmannLegalModule
	// 		),
	// },
	{
		path: "rewards",
		loadChildren: () =>
			import("./modules/taxmann-rewards/taxmann-rewards.module").then(
				(m) => m.TaxmannRewardsModule
			),
		data: { heme: "bookstore", title: "Rewards- Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "postagereceipt",
		loadChildren: () =>
			import("./modules/postagereceipt/postagereceipt.module").then(
				(m) => m.PostagereceiptModule
			),
		data: { theme: "bookstore" },
		canActivate: [ThemeGuard],
	},
	{
		path: "review-bulletin",
		loadChildren: () =>
			import(
				"./cms-forms/modules/review-bulletin-n/review-bulletin-n.module"
			).then((m) => m.ReviewBulletinNModule),
		data: { theme: "bookstore" },
		canActivate: [ThemeGuard],
	},
	{
		path: "review-bulletin/:category",
		loadChildren: () =>
			import(
				"./cms-forms/modules/review-bulletin-n/review-bulletin-n.module"
			).then((m) => m.ReviewBulletinNModule),
		data: { theme: "bookstore" },
		canActivate: [ThemeGuard],
	},
	{
		path: "aboutus",
		loadChildren: () =>
			import("./modules/newaboutus/newaboutus.module").then(
				(m) => m.NewaboutusModule
			),
		data: { theme: "bookstore", title: "" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "careers",
		loadChildren: () =>
			import("./modules/career/career.module").then(
				(m) => m.CareerModule
			),
		data: { theme: "bookstore" },
		canActivate: [ThemeGuard],
	},
	{
		path: "downloads",
		loadChildren: () =>
			import("./modules/downloads/downloads.module").then(
				(m) => m.DownloadsModule
			),
		data: { theme: "bookstore", title: "Downloads" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "cdkey",
		loadChildren: () =>
			import("./modules/cd-key/cd-key.module").then((m) => m.CDKeyModule),
		data: { theme: "bookstore", title: "CDkey - Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: 'virtualbooks/flipbook',
		loadChildren: () => import('src/app/advance-reader-flipbook/advance-reader-flipbook.module').then(m => m.AdvanceReaderFlipbookModule)
	},
	{
		path: "book-view/:isbn",
		loadChildren: () =>
			import("./modules/flipbook-view/flipbook-view.module").then(
				(m) => m.FlipbookViewModule
			),
		data: { preload: true, theme: "reader" },
		canActivate: [ThemeGuard, BrowserAllowedGuard],
	},
	{
		path: "book-view/cacs/:isbn",
		loadChildren: () =>
			import("./modules/flipbook-view/flipbook-view.module").then(
				(m) => m.FlipbookViewModule
			),
		data: { preload: true, theme: "reader" },
		canActivate: [ThemeGuard, BrowserAllowedGuard],
	},
	{
		path: "book-view/virtualjournals/:isbn",
		loadChildren: () =>
			import("./modules/flipbook-view/flipbook-view.module").then(
				(m) => m.FlipbookViewModule
			),
		data: { preload: true, theme: "reader" },
		canActivate: [ThemeGuard, BrowserAllowedGuard],
	},
	{
		path: "browser-not-supported",
		component: BrowserGuardComponent,
	},
	{
		path: "taxmannsurvey",
		loadChildren: () =>
			import("./cms-forms/modules/daily-survey/daily-survey.module").then(
				(m) => m.DailySurveyModule
			),
		data: { theme: "bookstore", title: `Taxmann's Survey` },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "daily-e-newsletter",
		loadChildren: () =>
			import(
				"./cms-forms/modules/daily-e-newsletter/daily-e-newsletter.module"
			).then((m) => m.DailyENewsLetterModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "offliner-search-lead",
		loadChildren: () =>
			import(
				"./cms-forms/modules/offliner-search-lead/offliner-search-lead.module"
			).then((m) => m.OfflinerSearchLeadModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "icsi-iip-memebers",
		loadChildren: () =>
			import(
				"./cms-forms/modules/icsi-iip-members/icsi-iip-members.module"
			).then((m) => m.ICSIIIPMembersModule),
		data: { theme: "bookstore", title: "eTDS Demo Registration" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "taxmann-product-catelogues",
		loadChildren: () =>
			import(
				"./cms-forms/modules/taxmann-product-catelogues/taxmann-product-catelogues.module"
			).then((m) => m.TaxmannProductCateloguesModule),
		data: { theme: "bookstore", title: "Taxmann - Direct Tax Laws" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "taxmann-research-demo-request",
		loadChildren: () =>
			import(
				"./cms-forms/modules/taxmann-research-demo-request/taxmann-research-demo-request.module"
			).then((m) => m.TaxmannResearchDemoRequestModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "one-month-free-online-research",
		loadChildren: () =>
			import(
				"./cms-forms/modules/one-month-free-online-research/one-month-free-online-research.module"
			).then((m) => m.OneMonthFreeOnlineResearchModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "pin-confirmation",
		loadChildren: () =>
			import(
				"./cms-forms/modules/pin-confirmation/pin-confirmation.module"
			).then((m) => m.PinConfirmationModule),
		data: { theme: "bookstore", title: "Taxmann - Direct Tax Laws" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "questionnaire",
		loadChildren: () =>
			import(
				"./cms-forms/modules/questionnaire/questionnaire.module"
			).then((m) => m.QuestionnaireModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "crm/CaseFeedback",
		loadChildren: () =>
			import(
				"./cms-forms/modules/case-closure-feedback/case-closure-feedback.module"
			).then((m) => m.CaseClosureFeedbackModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard, AuthGuard],
	},
	{
		path: "practice",
		loadChildren: () =>
			import("./tax-practice/tax-practice.module").then(
				(m) => m.TaxPracticeModule
			),
		data: { preload: true, theme: "practice" },
		canActivate: [ThemeGuard],
	},
	{
		path: "practice-pricing",
		loadChildren: () =>
			import(
				"./pricing/components/general-pricing/practice-pricing/practice-pricing.module"
			).then((m) => m.PracticePricingModule),
		data: { preload: true, theme: "practice" },
		canActivate: [ThemeGuard],
	},
	{
		path: "setting",
		loadChildren: () =>
			import("./modules/user-settings/user-settings.module").then(
				(m) => m.UsersettingModule
			),
		data: { title: "My Settings" },
		canActivate: [AuthGuard, TitleGuard],
	},
	{
		path: "SubscriptionComplaint",
		loadChildren: () =>
			import(
				"./modules/subscriptioncomplaint/subscriptioncomplaint.module"
			).then((m) => m.SubscriptioncomplaintModule),
		data: { theme: "bookstore", title: "Subscription Complaint" },
		canActivate: [TitleGuard],
	},
	// {
	// 	path: "budget-complimentary-offerings",
	// 	loadChildren: () => import('./modules/homebudget2022-landing/homebudget2022-landing.module').then(m => m.Homebudget2022LandingModule),
	// 	data: { theme: 'bookstore', title: 'Taxmann' },
	// 	canActivate: [ThemeGuard, TitleGuard]
	// },
	// {
	// 	path: "budget-complimentary-products",
	// 	loadChildren: () => import('./modules/homebudget2022/homebudget2022.module').then(m => m.Homebudget2022Module),
	// 	data: { theme: 'bookstore', title: 'Taxmann' },
	// 	canActivate: [AuthGuard, ThemeGuard, TitleGuard]
	// },
	{
		path: "under-maintenance",
		loadChildren: () =>
			import("./modules/under-maintenance/under-maintenance.module").then(
				(m) => m.UnderMaintenanceModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "paymentallied",
		loadChildren: () =>
			import(
				"./modules/get-payment-allied/get-payment-allied.module"
			).then((m) => m.GetPaymentAlliedModule),
		data: { theme: "bookstore", title: "Payment" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "journalsbooks",
		loadChildren: () =>
			import("./modules/journals-books/journals-books.module").then(
				(m) => m.JournalsBooksModule
			),
		data: { theme: "bookstore", title: "Journals" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "sitemap",
		loadChildren: () =>
			import("./modules/sitemap/sitemap.module").then(
				(m) => m.SitemapModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "DigitalFootPrint/:subjectId",
		loadChildren: () =>
			import(
				"./modules/digital-footprint-contact/digital-footprint-contact.module"
			).then((m) => m.DigitalfootprintModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "dropbox-signin-success",
		loadChildren: () =>
			import(
				"./modules/dropbox-success-signin/dropbox-success-signin.module"
			).then((m) => m.DropboxSuccessSigninModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [AuthGuard, ThemeGuard, TitleGuard],
	},
	{
		path: "dropbox-sync",
		loadChildren: () =>
			import("./modules/dropbox-sync/dropbox-sync.module").then(
				(m) => m.DropboxsyncModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [AuthGuard, ThemeGuard, TitleGuard],
	},
	{
		path: "secure-document",
		loadChildren: () =>
			import("./modules/secure-document/secure-document.module").then(
				(m) => m.SecuredocumentModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [AuthGuard, ThemeGuard, TitleGuard],
	},
	{
		path: "research-pricing/combo-plans",
		loadChildren: () =>
			import(
				"./pricing/components/general-pricing/combo-plan-pricing/combo-plan-pricing.module"
			).then((m) => m.ComboPlanPricingModule),
		data: { theme: "research", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "research-pricing/individual-products",
		loadChildren: () =>
			import(
				"./pricing/components/general-pricing/individual-plans-pricing/individual-plans-pricing.module"
			).then((m) => m.IndividualPlanPricingModule),
		data: { theme: "research", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "research-pricing/newsletter",
		loadChildren: () =>
			import(
				"./pricing/components/general-pricing/e-services-pricing/e-services-pricing.module"
			).then((m) => m.EServicesPricingModule),
		data: { theme: "research", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "pricing-combo-plans",
		redirectTo: "research-pricing/combo-plans",
	},
	{
		path: "pricing-individual-products",
		redirectTo: "research-pricing/individual-products",
	},
	{
		path: "pricing-e-services",
		redirectTo: "research-pricing/e-services",
	},
	{
		path: 'research-pricing/e-services',
		redirectTo: "research-pricing/newsletter",
	},
	{
		path: "budget",
		loadChildren: () =>
			import("./budget/budget.module").then((m) => m.BudgetModule),
		data: { theme: "bookstore", title: "Union Budget" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "budget-2021-22",
		redirectTo: "budget",
	},
	{
		path: "trackrequest",
		loadChildren: () =>
			import("./modules/trackrequest/trackrequest.module").then(
				(m) => m.TrackrequestModule
			),
		data: { theme: "bookstore", title: "Redirecting" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "customersurvey",
		loadChildren: () =>
			import(
				"./cms-forms/modules/customer-survey/customer-survey.module"
			).then((m) => m.CustomerSurveyModule),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "order/pay-online/:orderNo",
		loadChildren: () =>
			import(
				"./modules/get-payment-allied/get-payment-allied.module"
			).then((m) => m.GetPaymentAlliedModule),
		data: { theme: "bookstore", title: "Payment" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: "user/track-return-cancel-order/:orderNo",
		loadChildren: () =>
			import(
				"./modules/orders/student-orders/modules/track-return-cancel/track-return-cancel.module"
			).then((m) => m.TrackReturnCancelModule),
			data: { theme: "bookstore", title: "Track Order" },
			canActivate: [ThemeGuard, TitleGuard],	
	},
	{
		path: "user/track-order/:orderNo",
		loadChildren: () =>
			import(
				"./modules/orders/student-orders/modules/track-order/track-order.module"
			).then((m) => m.TrackOrderModule),
			data: { theme: "bookstore", title: "Track Order" },
			canActivate: [ThemeGuard, TitleGuard],	
	},
	{
		path: "",
		redirectTo: "",
		pathMatch: "full",
	},
	{
		path: "index.aspx",
		redirectTo: "/",
	},
	{
		path: "freetrial.aspx",
		redirectTo: "/gp/auth/register?src=trialrequestfromsite",
	},
	{
		path: "bookstore/viewcart.aspx",
		redirectTo: "bookstore/cart",
	},
	{
		path: "bookstore/adit.aspx",
		redirectTo: "bookstore/adit",
	},
	{
		path: "contactus.aspx",
		redirectTo: "contactus",
	},
	{
		path: "dealers.aspx",
		redirectTo: "dealer/search",
	},
	{
		path: "bookstore/readyreckoners.aspx",
		redirectTo:
			"/bookstore/product/1/0/professional/reckoner?searchtext=reckoner&subject=GST",
	},
	{
		path: "bookstore/tax-compliance-cd/e-tds-returns-single-user-f.y.-2020-21.aspx",
		redirectTo:
			"/bookstore/product/98/6000/eproducts/compliance?subject=Compliance&utm_source=Software&utm_medium=Email&utm_campaign=etdsoftware",
	},
	{
		path: "bookstore/daily-tax-and-corporate-laws-digest-and-taxmann-daily.aspx",
		redirectTo: "research-pricing/newsletter"
	},
	{
		path: "product-catalogue-for-subscription",
		redirectTo: "research-pricing/combo-plans",
	},
	{
		path: "journals-books.aspx",
		redirectTo: "journalsbooks",
	},
	{
		path: "pinconfirmation.aspx",
		redirectTo: "pin-confirmation",
	},
	{
		path: "readyreckoner.aspx",
		redirectTo: "research/direct-tax-laws/ready-reckoner",
	},
	{
		path: "itr.aspx",
		redirectTo: "research/direct-tax-laws/forms/returns",
	},
	{
		path: "tools/tax-calculator",
		redirectTo: "tools/income-tax-calculator",
	},
	{
		path: "taxmann-research-demo-request.aspx",
		redirectTo: "taxmann-research-demo-request",
	},
	{
		path: "tools/income-tax-calculator/income-and-tax-calculator",
		redirectTo: "tools/income-tax-calculator",
	},
	{
		path: "tools/comparator/act",
		redirectTo: "tools/act-and-rule-comparator",
	},
	{
		path: "tools/comparator/rule",
		redirectTo: "tools/act-and-rule-comparator",
	},
	{
		path: "tools/eway-bill",
		redirectTo: "tools/gst-eway-bill",
	},
	{
		path: "tools/place-of-supply",
		redirectTo: "tools/gst-place-of-supply",
	},
	{
		path: "tools/eps-calculator",
		redirectTo: "tools/eps-deps-calculator",
	},
	{
		path: "tools/indas-applicability",
		redirectTo: "tools/ind-as-applicability",
	},
	{
		path: "tools/as-deferred-calculator",
		redirectTo: "tools/as-deferred-tax-calculator",
	},
	{
		path: "tools/indas-deferred-calculator",
		redirectTo: "tools/ind-as-deferred-tax-calculator",
	},
	{
		path: "tools/treaty-comparator",
		redirectTo: "tools/tax-treaties-comparison",
	},
  	// {
	// 	path:'store',
	// 	loadChildren: () => import('src/app/taxmann-store/taxmann-store.module').then((m) =>m.TaxmannStoreModule)
	// },
	{
		path: "unsubscribe/:token",
		loadChildren: () =>
			import("./modules/unsubscribe/unsubscribe.module").then(
				(t) => t.UnsubscribeModule
			),
	},
	{
		path: "budget/marathon",
		redirectTo: "budget",
	},
	{
		path: "paymenttppl",
		loadChildren: () => import('./modules/payment-tppl/payment-tppl.module').then(m => m.PaymentTpplModule)
	},
	{
		path: "return-policy",
		component: ReturnPolicyComponent
	},
	{
		path: "legal/return-policy",
		redirectTo: "return-policy",
	},
	{
		path: "privacy-policy",
		component: PrivacyPolicyComponent
	},
	{
		path: "legal/privacy-policy",
		redirectTo: "privacy-policy",
	},
	{
		path: "payment-terms",
		component: PaymentTermsComponent
	},
	{
		path: "legal/payment-terms",
		redirectTo: "payment-terms",
	},
	{
		path: "eula",
		component: EULAComponent
	},
	{
		path: "legal/end-user-licence-agreement",
		redirectTo: "eula",
	},
	{
		path: "legal-disclaimer",
		component: DisclamierComponent
	},
	{
		path: "legal/legal-disclaimer",
		redirectTo: "legal-disclaimer",
	},
	{
		path: "BookStore/customersurvey.aspx",
		redirectTo:
			"customersurvey",
	},
	{
		path:'bookstore/newrelease',
		loadChildren:() => import('../app/bookstore-new-release/bookstore-new-release.module').then(m => m.BookstoreNewReleaseModule)
	},
	{
		path: "crm/CaseFeedback.aspx",
		redirectTo: "crm/CaseFeedback",
	},
	{
		path: "finance-act",
		loadChildren: () =>
			import("../app/finance-act/finance-act.module").then(
				(m) => m.FinanceActModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},
	{
		path: 'adit',
		component: AditComponent,
		data: { theme: 'bookstore', title: 'Subscriptions' },
		canActivate: [TitleGuard]
	},
	{
		path: 'adit/pricing',
		loadChildren: () => import('./pricing/pricing.module').then(m => m.PricingModule),
		data: { theme: 'bookstore', title: 'Subscriptions' },
		canActivate: [TitleGuard]
	},
	{
		path:"podcast",
		loadChildren:()=>import("src/app/bookstore/podcasts/podcasts.module").then(m=>m.PodcastsModule)
	},
	{
		path: "post/blog/finance-act",
		redirectTo: "/post/finance-act/",
	},
	{
		path: "research/ibc/ibc-faqs",
		redirectTo: "/research/ibc/faqs",
	},


	// {
	// 	path: "nalsar",
	// 	loadChildren: () =>
	// 		import("./nalsar/nalsar.module").then(
	// 			(m) => m.NalsarModule
	// 		),
	// 		data: { theme: "practice" },
	// 	canActivate: [ThemeGuard],
	// },
	{
		path: "**",
		loadChildren: () =>
			import("./modules/page-not-found/page-not-found.module").then(
				(m) => m.PagenotfoundModule
			),
		data: { theme: "bookstore", title: "Taxmann" },
		canActivate: [ThemeGuard, TitleGuard],
	},




];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
	preloadingStrategy: SelectivePreloadingStrategy,
	scrollPositionRestoration: "top",
});
