import { Component, Input } from '@angular/core';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { ArchiveSharedService } from '../service/archive-shared.service';
import { GlobalUtility } from '../utility/global-utility';
import { FooterListModel } from './classes/list-model';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    @Input() toggleCssClass;
    @Input() hasSideBar: boolean;

    paymentAcceptArray = FooterListModel.paymentAcceptArray;
    socialShareIconArray = FooterListModel.socialShareIconArray;
    productArray = FooterListModel.productArray;
    aboutCompanyArray = FooterListModel.aboutCompanyArray;
    //catalogueData = FooterListModel.cataloguedData;
    catalogueData;
    businessAndSupportArray = FooterListModel.businessAndSupportArray;
    bookstoreSupportArray = FooterListModel.bookstoreSupportArray;
    legalArray = FooterListModel.legalArray;
    useDomain: string;
    userIp: string;
    isIPUser: boolean;
    isProxyUser: boolean;
    imageUrl = this.globalUtility.makeImageUrl();

    constructor(private archiveSharedService: ArchiveSharedService,
      private globalUtility: GlobalUtility,private router: Router,
      public sanitizer: DomSanitizer     ) {
    }

    ngOnInit() {
      this.setIPInfo(2000);
      this.setIPInfo(5000);
      this.setIPInfo(10000);
      this.setIPInfo(20000);
      this.setIPInfo(2500);
      this.setIPInfo(30000);
      this.setIPInfo(35000);
      this.setIPInfo(40000);
      this.setIPInfo(45000);
      this.setIPInfo(50000);
this.getTaxmannProductCatalogue();
    }

    setIPInfo(time){
      setTimeout(() => {
      let domain = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_DOMAIN)
      this.useDomain = domain ? domain : window.location.hostname;
      this.userIp = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
      let iPUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.ISIPUSER);
      let proxyUser = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER);
      this.isIPUser = iPUser === 'true' ? true : false
      this.isProxyUser = proxyUser === 'true' ? true : false;
      console.log("is ip user", this.isIPUser);
    }, time);
    }
    getTaxmannProductCatalogue(){
        let list : any[]=[];
        this.archiveSharedService.getProductCatalogue().subscribe((res)=>{
          if(res && res.Data){
        list = res.Data;
        let modifieldList = list.map( (elem) => {
         return {
           name: elem.CatalogueName,
           url: elem.url,
           externalLink: true
         }
        });
        this.catalogueData = modifieldList;
          }
        }, (error)=>{
        }
        );
      }

      routeToProduct(url,queryParams){
        if(queryParams){
        if(url.includes('advisory') || url.includes('academy')){
          this.router.navigate([url], {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        })
        setTimeout(() => {
          window.location.reload();
        }, 200);

        } else {
          this.router.navigate([url], {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        })
        }
      }
      else {
        if(url.includes('advisory') || url.includes('academy')){
          this.router.navigate([url])
        setTimeout(() => {
          window.location.reload();
        }, 200);

        } else {
          this.router.navigate([url])
        }
      }
      }

    scroll() {
        window.scroll(0, 0);
    }
    @gaEventTrack('Footer', '', 'Click',-1,0)
    eventFooterItem(labelIndex){

    }
}
