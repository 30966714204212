import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HomeService } from 'src/app/service/bookstore-service/home.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { KeyCodes } from 'src/enums/enum';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { GlobalUtility } from 'src/app/utility/global-utility';
import { CommonAlertService } from 'src/app/service/common-alert.service';

@Component({
  selector: 'app-research-search',
  templateUrl: './research-search.component.html',
  styleUrls: ['./research-search.component.scss']
})
export class ResearchSearchComponent implements OnInit {

  isExpanded: boolean;
  queryField: FormControl = new FormControl('');
  alive = true;
  arrowkeyLocation = 0;
  suggestionList: any[];
  deactivateSearch: boolean;
	showCrossIcon : boolean = false;
	mobileView:boolean = false;

  @Output() onEmptySearch = new EventEmitter();
  searchTextLimitLength: number = 200;
  showMinimumThreeCharactersMsg: boolean = false;
  constructor(
    private _homeService: HomeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalUtility : GlobalUtility,
    public commonAlertService: CommonAlertService
  ) { }

  ngOnInit() {
    this.isMobileView();
    let params = this.activatedRoute.snapshot.queryParams;
    this.paramsHandling(params);
    this.initEvents();
  }

  initEvents() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let params = this.activatedRoute.snapshot.queryParams;
        this.paramsHandling(params);
      }
    });

    this.queryField.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(searchText => {
      if (searchText && searchText.trim() && searchText.trim().length > 2) {
        this.getAutoCompleteContent(searchText.trim());
      } else {
        this.isExpanded = false;
      }

    });
  }

  private paramsHandling(params) {
    if (params.searchData) {
      this.deactivateSearch = true;
      this.queryField.setValue(params.searchData);
    }
    else {
      this.queryField.reset();
    }
  }

  keyDown(event: KeyboardEvent) {
    this.showCrossIcon = true;
    if (this.queryField.value && this.queryField.value.trim()) {
      this.showMinimumThreeCharactersMsg = false;
      this.showCrossIcon = true;
      let searchText = this.queryField.value.trim()
      switch (event.keyCode) {
        case KeyCodes.UP_ARROW:
          if (this.arrowkeyLocation > 0) {
            this.arrowkeyLocation--;
            this.foucsOnActiveIndex();
          }

          break;
        case KeyCodes.DOWN_ARROW:
          if (this.suggestionList && this.suggestionList.length >= this.arrowkeyLocation + 1)
            this.arrowkeyLocation++;
          this.foucsOnActiveIndex();
          break;
        case KeyCodes.ENTER:
          let activeSelection = document.getElementsByClassName("filter-select-list active");
          if (this.arrowkeyLocation > 0 && activeSelection && activeSelection[0]) {
            (activeSelection[0].children[0] as HTMLElement).click();
            return;
          }
          if(searchText && searchText && searchText.trim() && searchText.trim().length > 2){
          const data = {
            ActionCode: 0,
            SuggestionText: searchText.trim()
          }
          this.goToSearchPage(data)
        }
          break;

      }
    }

  }

  private foucsOnActiveIndex() {
    let activeSelection = document.getElementsByClassName("filter-select-list active");
    console.log("arrowkeyLocation", this.arrowkeyLocation);
    if (this.arrowkeyLocation > 0 && activeSelection && activeSelection[0]) {
      console.log("active selection element", activeSelection);
      (activeSelection[0] as HTMLElement).focus();
    }
  }

  getAutoCompleteContent(searchText) {
    this.arrowkeyLocation = 0;
    const data = {
      QueryText: searchText
    };
    this._homeService.getResearchAutocomplete(data).subscribe((res) => {
      if (this.deactivateSearch) {
        this.isExpanded = false;
        this.deactivateSearch = false
      } else {
        this.isExpanded = true;
      }
      if (res.Data) {
        this.suggestionList = res.Data;
      } else {
        this.suggestionList = [];
      }
    }, error => {
      if (this.deactivateSearch) {
        this.isExpanded = false;
        this.deactivateSearch = false
      } else {
        this.isExpanded = true;
      }
      this.suggestionList = [];
    });
  }

  onClickOutside(event: Object) {
    if (event && event['value'] === true) {
      this.isExpanded = false;
    } else {
      this.isExpanded = true;
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }


  goToSearchPage(searchText) {
    if (!searchText.ActionCode) {
      let  stext = searchText.SuggestionText.trim();
      if(searchText.SuggestionText.trim().length > this.searchTextLimitLength ){
 stext = searchText.SuggestionText.substring(0, this.searchTextLimitLength);
stext = stext.includes(" ") ? stext.substring(0, stext.trim().lastIndexOf(' ')) : stext; 
this.commonAlertService.successToast('Search query parameters will be executed on the first 200 characters of search query including space');
}
      this.router.navigate(['/research/search'], { queryParams: { searchData: searchText.SuggestionText.trim() } });
    } else {
      if(this.queryField && this.queryField.value && this.queryField.value.trim() && this.queryField.value.trim().length > 2){
          this.router.navigate(['/bookstore/product/search', this.queryField.value], { queryParams: { searchtext: this.queryField.value.trim() } });
      }
        }
    this.isExpanded = false;
  }

  onFocus() {
    let ipElem = document.getElementById('research-global-search');
    if (ipElem) {
      (ipElem as HTMLInputElement).select();
    }

    let searchText = this.queryField.value;
    this.arrowkeyLocation = 0;
    this.deactivateSearch = false;
    console.log(searchText)
    if (searchText && searchText.trim()) {
      this.showCrossIcon = true;
      this.getAutoCompleteContent(searchText.trim());
    }
  }

  @gaEventTrack('Header_Research>', 'Search', 'Click')
  searchOnClick() {

    let searchText = this.queryField.value;
    if (searchText && searchText.trim()) {
      this.showCrossIcon = true;
      const data = {
        ActionCode: 0,
        SuggestionText: searchText.trim()
      }
      this.goToSearchPage(data);
    }
    if (!searchText) {
      this.onEmptySearch.emit(true)
    }
  }
  @gaEventTrack('Header_Research', 'Search', 'Click')
  onEnterKey() {
    let searchText = this.queryField.value;
    searchText = searchText.trim();
    if(searchText && searchText.length > 2){
    if (!searchText) {
      this.onEmptySearch.emit(true)
    }
  }else{
    this.showMinimumThreeCharactersMsg = true;
    return;
  }
  }

  cancelSearch(){
    this.isExpanded = false;
		if(window.location.pathname.indexOf('research/search') !== -1){
			this.router.navigate(['research']);
		}else{
			this.queryField.setValue('');
		}
  }

  focusedOut(){
		console.log("input is focused out");
this.showCrossIcon = false;
this.cancelSearch();
	}

  isMobileView() {
		if (this.globalUtility.isMobileView()) {
			this.mobileView = true;
		}
	}

  hide(event){
    this.showMinimumThreeCharactersMsg = false;
  }
}
