import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalUtility } from '../utility/global-utility';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { LocalStorageDataModel } from '../../constants/local-storage-data-model';
import { CommonAlertService } from './common-alert.service';
import { APIResponse } from '../models/interfaces/api-response';
import { ApiStatus, DeviceType } from 'src/constants/constant';
import { TaxmannUrl } from '../api-url/taxmann-url.enum';
import { MetaService } from './meta.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
	providedIn: 'root'
})
export class HttpHelperService {
	HOMEPAGE_PATH = {
		'bookstore': '/bookstore',
		'research': '/research',
		'virtualbooks': '/virtualbooks/library',
		'webinars': '/webinars',
		'practice': '/practice',
		'home': '/home',
	}
	fileId = '';
	payloadBody;
	constructor(
		private http: HttpClient,
		private globalUtility: GlobalUtility,
		private _commonAlertService: CommonAlertService,
		private metaService: MetaService,
		private deviceService: DeviceDetectorService
	) { }

	get(url: string, query: Object, requiresAuth = false, header?: HttpHeaders): Observable<APIResponse> {
		let headers = this.addCustomHeaders(false, requiresAuth, header);
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = [];
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`);
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = '';
		return this.http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	getWithToken(url: string, query: Object, token): Observable<APIResponse> {
		let headers = new HttpHeaders()
		headers = headers.append(
			LocalStorageDataModel.AUTH_TOKEN,
			token
		);
		headers = headers.append(
			LocalStorageDataModel.REFRESH_AUTH_TOKEN,
		    sessionStorage.getItem('refreshToken') || ''

		);
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = [];
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`);
			})
			url += `?${arr.join('&')}`
		}

		this.payloadBody = '';
		return this.http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	getRefresh(url: string, query: Object, requiresAuth = false, header?: HttpHeaders): Observable<APIResponse> {
		let headers = this.addRefreshHeaders(false, requiresAuth, header);
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = [];
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`);
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = '';
		return this.http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	getData(url: string, query: Object, requiresAuth = false, header?: HttpHeaders): Observable<APIResponse> {
		let headers = this.addCustomHeaders(false, requiresAuth, header);
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = [];
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`);
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = '';
		return this.http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map(res => res.body),
			catchError(this.errorHandler.bind(this))
		);
	}

	getWithoutErrorHandling(url: string, query: Object, requiresAuth = false, header?: HttpHeaders): Observable<APIResponse> {
		let headers = this.addCustomHeaders(false, requiresAuth, header);
		let paramsArray = Object.keys(query)
		if (paramsArray.length) {
			const arr = [];
			paramsArray.forEach((key) => {
				arr.push(`${key}=${query[key]}`);
			})
			url += `?${arr.join('&')}`
		}
		this.payloadBody = '';
		return this.http.get<APIResponse>(url, { headers, observe: 'response', ...query }).pipe(
			map(res => this.checkResponse(res)),
		);
	}

	post(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	postRefresh(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addRefreshHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}
    
	CartpostRefresh(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.CartRefreshHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}
	postwithoutCheckResponse(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => res.body),
			catchError(this.errorHandler.bind(this))
		);
	}

	postwithoutCheckResponseAndErrorHandler(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(response => {
				response.body.Data = response.body?.Data || response.body.Data || response.body?.data;;
				if (response.body && response.body.Data && response.body.Data.login_token) {
					this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token, 43200);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token);

					this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response?.body?.data?.login_token, 43200);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response?.body?.data?.login_token);

				}

				if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]) {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response?.body?.data[LocalStorageDataModel.CONTENT_TOKEN]);
				}

				if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.I_READER_TOKEN]) {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response.body.Data[LocalStorageDataModel.I_READER_TOKEN]);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response?.body?.data[LocalStorageDataModel.I_READER_TOKEN]);
				}

				return response.body
			})
		);
	}

	postDownload(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any) {
		let headers: HttpHeaders = new HttpHeaders();
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (requiresAuth) {
			if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			} else {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			}
		}
		this.payloadBody = body;

		return this.http.post(url, body, { headers, responseType: 'blob' });
	}

	postMethodWithoutErrorHandling(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {

		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' })
			.pipe(
				map(response => {

					response.body.Data = response.body?.Data || response.body.Data || response.body?.data;;
					if (response.body && response.body.Data && response.body.Data.login_token) {
						this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token, 43200);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token);

						this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response?.body?.data?.login_token, 43200);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response?.body?.data?.login_token);

					}

					if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]) {
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response?.body?.data[LocalStorageDataModel.CONTENT_TOKEN]);
					}

					if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.I_READER_TOKEN]) {
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response.body.Data[LocalStorageDataModel.I_READER_TOKEN]);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response?.body?.data[LocalStorageDataModel.I_READER_TOKEN]);
					}

					return response.body
				})
			);
	}

	postFile(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers: HttpHeaders;
		if (isUrlEncoded) {
			headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
		} else {
			headers = new HttpHeaders({});
		}
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (requiresAuth) {
			if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			} else {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			}
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}


	put(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		this.payloadBody = body;
		return this.http.put<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	delete(url: string, query: Object, isUrlEncoded = false, requiresAuth = false, header?: any): Observable<APIResponse> {
		let headers = this.addCustomHeaders(isUrlEncoded, requiresAuth, header);
		let searchParams = this.addSearchParams(query);
		this.payloadBody = '';
		return this.http.delete<APIResponse>(url, { headers, observe: 'response', params: searchParams }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	private addCustomHeaders(isUrlEncoded = false, requiresAuth = false, customHeaders?: any): HttpHeaders {
		let headers: HttpHeaders;
		if (isUrlEncoded) {
			headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'appid': '2020',
				refreshtoken: sessionStorage.getItem('refreshToken') || ''
			});
		}
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (requiresAuth) {
			if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			} else {
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			}
		}
		if (customHeaders) Object.keys(customHeaders).forEach(key => headers = headers.append(key, customHeaders[key]));
		return headers;
	}

	private addRefreshHeaders(isUrlEncoded = false, requiresAuth = false, customHeaders?: any): HttpHeaders {
		let headers: HttpHeaders;
		if (isUrlEncoded) {
			headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'appid': '2020',
			});
		}
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (requiresAuth) {
			if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(LocalStorageDataModel.REFRESH_AUTH_TOKEN,  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			} else {
				headers = headers.append(LocalStorageDataModel.REFRESH_AUTH_TOKEN,  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)); 
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			}
		}
		if (customHeaders) Object.keys(customHeaders).forEach(key => headers = headers.append(key, customHeaders[key]));
		return headers;
	}

	private CartRefreshHeaders(isUrlEncoded = false, requiresAuth = false, customHeaders?: any): HttpHeaders {
		let headers: HttpHeaders;
		if (isUrlEncoded) {
			headers = new HttpHeaders({
				'Content-Type': 'application/x-www-form-urlencoded'
			});
		} else {
			headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'appid': '2020',
				"isCartPage": 'Y'
			});
		}
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (requiresAuth) {
			if (this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.IS_PROXY_USER) === '1') {
				headers = headers.append(LocalStorageDataModel.REFRESH_AUTH_TOKEN,  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			} else {
				headers = headers.append(LocalStorageDataModel.REFRESH_AUTH_TOKEN,  this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN)); 
				headers = headers.append(LocalStorageDataModel.AUTH_TOKEN, this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.AUTH_TOKEN));
			}
		}
		if (customHeaders) Object.keys(customHeaders).forEach(key => headers = headers.append(key, customHeaders[key]));
		return headers;
	}

	private addSearchParams(customParam: Object) {
		let search: HttpParams = new HttpParams();
		for (const key in customParam) search = search.append(key, customParam[key]);
		return search;
	}

	private checkResponse(response): APIResponse {
		if (response.body && response.body.data && response.body.data.login_token) {
			this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response.body.data.login_token, 43200);
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response.body.data.login_token);
		}

		if (response.body && response.body.data && response.body.data[LocalStorageDataModel.CONTENT_TOKEN]) {
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response.body.data[LocalStorageDataModel.CONTENT_TOKEN]);
		}

		if (response.body && response.body.data && response.body.data[LocalStorageDataModel.I_READER_TOKEN]) {
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response.body.data[LocalStorageDataModel.I_READER_TOKEN]);
		}

		// if (response.body.ResponseType == ApiStatus.ERROR || response.body.ResponseType === ApiStatus.WARNING) {
		if (response.body.ResponseType == ApiStatus.USER_NOT_FOUND) {
			this._commonAlertService.errorToast('This account does not exist. <a href="/gp/auth/register">Create a new account<a>');
		} else if (response.body.ResponseType == ApiStatus.INCORRECT_PASSWORD) {
			this._commonAlertService.errorToast('Wrong password. Try again or <a href="/gp/auth/forgotPassword">Reset it now</a>');
		} else if (response.body.ResponseType == ApiStatus.ERROR || response.body.ResponseType === ApiStatus.WARNING || response.body.ResponseType === ApiStatus.INVALID_LOGIN) {
			this._commonAlertService.errorToast(response.body.StatusMsg);
		}
		// else if(response.body.ResponseType == 'SOCIAL_LOGIN_NOT_FOUND'){
		//   this._commonAlertService.errorToast('This account does not exist. <a href="/auth/register">Create a new account<a>');
		// }else if(response.body.ResponseType == 'OTP_NOT_VERIFIED'){
		//   this._commonAlertService.errorToast(response.body.StatusMsg);
		// }
		// }
		return response.body;
	}

	private errorHandler(error: HttpErrorResponse) {
		console.log("error in http haldler", error)
		if (error.status == 301 || error.status == 404) {
			let path = location.pathname;
			if(path.includes('taxmann-s-live-webinar-how-does-the-rainbow-judgment-impacts-resolution-plans')){
                this.createRedirectUrlForSlug();
			}else if(path.includes('understanding-impact-of-rainbow-papers-judgement-on-resolution-plans-under-ibc')){

			}else{
			this.createRedirectUrl()
			}
			return
		}
		let errorMsg = '';
		if (error.error instanceof ErrorEvent) errorMsg = 'There is a problem with the service. We are notified & working.Please try again later.';
		else if (error.error && error.error.StatusMsg && error.error.ResponseType === ApiStatus.API_CALLING_LIMIT_EXCEED) {
			errorMsg = 'You have reached at max limit of API Hit';
		} else {
			errorMsg = 'There is some network issue. Please try again later.';
		}
		if (error.status == 400) {
			let url = this.globalUtility.makeUrl(TaxmannUrl.SAVE_ERROR_LOG);
			let date = new Date();
			let data = {
				"errortext": typeof (error.error.StatusMsg) === "object" ? JSON.stringify(error.error.StatusMsg) : error.error.StatusMsg,
				"fileid": this.fileId,
				"apiname": error.url,
				"currenturl": location.href,
				"apiresponse": JSON.stringify(error.error),
				"payloadbody": JSON.stringify(this.payloadBody),
				"apirequesttime": date.toString()
			}
			this.post(url, data, false).subscribe();
			if( error.error.StatusMsg.toLowerCase() == ApiStatus.INVALID_CHARACTER ){
				errorMsg = ApiStatus.INVALID_CHARACTER_ERROR_MESSAGE;
			}
		}
		this._commonAlertService.errorToast(errorMsg);
		throw new Error(error.message);
	}

	createRedirectUrlForSlug(){
		let hostOrigin = location.origin;
		let searchParams = location.search;
	    let bookPath = '/webinars/understanding-impact-of-rainbow-papers-judgement-on-resolution-plans-under-ibc';
		let URL = hostOrigin + bookPath + searchParams;
		this.metaService.createRedirectUrl(URL)
	}

	createRedirectUrl() {
		let hostOrigin = location.origin;
		let bookPath = this.HOMEPAGE_PATH.bookstore;
		let mainUri = location.pathname.split('/')[1]
		if (this.HOMEPAGE_PATH.hasOwnProperty(mainUri)) {
			bookPath = this.HOMEPAGE_PATH[mainUri]
		} else {
			bookPath = '/';
		}
		let URL = hostOrigin + bookPath;
		this.metaService.createRedirectUrl(URL)
	}

	loginItatUser(data): Observable<APIResponse> {
		let deviceType = this.deviceService.isDesktop() ? DeviceType.DESKTOP : this.deviceService.isTablet() ? DeviceType.TABLET : this.deviceService.isMobile() ? DeviceType.MOBILE : '';
		data['ipAddress'] = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
		data['userAgent'] = this.deviceService.getDeviceInfo()['userAgent'];
		data['device'] = this.deviceService.device;
		data['os'] = this.deviceService.os;
		data['osVersion'] = this.deviceService.os_version;
		data['browser'] = this.deviceService.browser;
		data['browserVersion'] = this.deviceService.browser_version;
		data['deviceType'] = deviceType;
		let url = this.globalUtility.makeDashboardUrl(TaxmannUrl.LOGIN);
		return this.post(url, data, false, false)
			.pipe(map(this.globalUtility.filterResponse))

	}

	logItatUser(email, token) {
		let url = this.globalUtility.makeVirtualUrl('externallink/saveitatuser');
		let data = {
			"emailid": email,
			"token": token,
			"browsername": this.deviceService.getDeviceInfo()['userAgent']
		}
		this.post(url, data).subscribe();
	}

	postPdfDownload(url: string, body: any, requiresAuth = false, isUrlEncoded = false, header?: any): Observable<APIResponse> {
		let headers = new HttpHeaders()
		headers = headers.append(
			'Authorization',
			'44633bea-ba42-44aa-8776-9be561f632e3'
		);
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		this.payloadBody = body

		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map((res) => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		)
	}

	postWithToken(url: string, body: any, token): Observable<APIResponse> {
		let headers = new HttpHeaders()
		headers = headers.append(
			LocalStorageDataModel.AUTH_TOKEN,
			token
		);
		headers = headers.append(
			LocalStorageDataModel.REFRESH_AUTH_TOKEN,
		    sessionStorage.getItem('refreshToken') || ''

		);
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' }).pipe(
			map(res => this.checkResponse(res)),
			catchError(this.errorHandler.bind(this))
		);
	}

	postMethodWithoutErrorHandlingToken(url: string, body: any, token?: any): Observable<APIResponse> {
		let headers = new HttpHeaders()
		headers = headers.append(
			LocalStorageDataModel.AUTH_TOKEN,
			token
		);
		let machineId = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.MACHINE_ID);
		if(machineId){}else{
			// generate machibe id and save ib local storage
          let uuid = uuidv4();
		  uuid = uuid.replace(/-/g,"");
		  machineId = uuid;
		  this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.MACHINE_ID, uuid);
		}
		headers = headers.append(
			LocalStorageDataModel.MACHINE_ID,
			machineId
		)
		if (body['fileId']) {
			this.fileId = body['fileId'];
		} else {
			this.fileId = '';
		}
		this.payloadBody = body;
		return this.http.post<APIResponse>(url, body, { headers, observe: 'response' })
			.pipe(
				map(response => {
					if (response.body && response.body.Data && response.body.Data.login_token) {
						this.globalUtility.setCookie(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token, 43200);
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.AUTH_TOKEN, response.body.Data.login_token);
					}

					if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]) {
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.CONTENT_TOKEN, response.body.Data[LocalStorageDataModel.CONTENT_TOKEN]);
					}

					if (response.body && response.body.Data && response.body.Data[LocalStorageDataModel.I_READER_TOKEN]) {
						this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.I_READER_TOKEN, response.body.Data[LocalStorageDataModel.I_READER_TOKEN]);
					}

					return response.body
				})
			);
	}

}
