import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { constants, RegisterPlatformType, UTMCamapignResearchUrl, UTMSourceResearchUrl } from 'src/constants/constant';
import { CommonAlertService } from 'src/app/service/common-alert.service';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { gaEventTrack } from 'src/app/utility/google-analytics-event.decorator'
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/service/bookstore-service/home.service';
declare const gtag: Function
@Component({
	selector: 'app-taxmann-banner',
	templateUrl: './taxmann-banner.component.html',
	styleUrls: ['./taxmann-banner.component.scss']
})
export class TaxmannBannerComponent implements OnInit {

	@Input() heading: string;
	@Input() content: string;
	@Input() category;
	utmSourceName: string;
	utmCampaignName: string;
	utm_campaign: string;
	utm_medium: string;
	utm_source: string;
	utm_cid:string;
	utm_statsid:string;

	emailCtrl = new FormControl('', [Validators.required, Validators.pattern(constants.emailPattern)])
	imageUrl = this.globalUtility.makeWebpImageUrl();
	registerPlatformType = RegisterPlatformType;

	constructor(private router: Router, private commonAlertService: CommonAlertService,
		private globalUtility: GlobalUtility, private activatedRoute: ActivatedRoute, private homeService: HomeService) { }

	ngOnInit() {
		this.getCategoryName();
		this.getCampaignData();
	}

	getCampaignData() {
		this.activatedRoute.queryParams.subscribe((res) => {
			this.utm_campaign = res.utm_campaign;
			this.utm_medium = res.utm_medium;
			this.utm_source = res.utm_source;
			this.utm_cid = res.utm_cid;
			this.utm_statsid = res.utm_statsid;

		});
	}

	getCategoryName() {
		switch (this.category) {
			case 'home': this.utmCampaignName = UTMCamapignResearchUrl['home']
				this.utmSourceName = UTMSourceResearchUrl['home'];
				break;
			case 'research-home': this.utmCampaignName = UTMCamapignResearchUrl['research-home'];
				this.utmSourceName = UTMSourceResearchUrl['research-home'];
				break;
		}
	}

	private _checkForUserREGISTEREDOnNot() {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TRIAL_EMAIL, this.emailCtrl.value);
		this.globalUtility.setDataOnLocalStorageWithExpiry(LocalStorageDataModel.RESEARCH_FREE_TRIAL_ACTION, 'true', 1800000);//half an hour
		this.homeService.isRegisteredUser(this.emailCtrl.value).subscribe(response => {
			this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.TRIAL_EMAIL, this.emailCtrl.value);
			if (response.Status == "USER_ALREADY_REGISTERED" && response.ResponseType == "SUCCESS") {
				this.globalUtility.redirectToReactLogin()
			} else {
				let referrer = this.registerPlatformType.HOME;
				this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.INTERNAL_REFRERRER_URL, referrer);
				let field = 'utm_source';
				let url = window.location.href;
				if (url.indexOf('?' + field + '=') != -1) {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_SORUCE, this.utm_source);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_CAMPAIGN, this.utm_campaign);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_MEDIUM, this.utm_medium);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_CID,this.utm_cid);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_STATSID,this.utm_statsid);

					window.open(`/gp/auth/register?src=trialrequestfromsite&utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_campaign=${this.utm_campaign}`, "_self");

					// this.router.navigate(['/gp/auth/register'], {
					// 	queryParams: {
					// 		src: 'trialrequestfromsite',
					// 		// email: this.emailCtrl.value,
					// 		utm_source: this.utm_source,
					// 		utm_medium: this.utm_medium,
					// 		utm_campaign: this.utm_campaign
					// 	}
					// })
				}
				else {
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_SORUCE, this.utmSourceName);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_CAMPAIGN, this.utmCampaignName);
					this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.UTM_MEDIUM, 'SIGNUP-BUTTON');
					window.open(`/gp/auth/register?src=trialrequestfromsite&utm_source=${this.utmSourceName}&utm_medium=SIGNUP-BUTTON&utm_campaign=${this.utmCampaignName}`, "_self");

					// this.router.navigate(['/gp/auth/register'], {
					// 	queryParams: {
					// 		src: 'trialrequestfromsite',
					// 		// email: this.emailCtrl.value,
					// 		utm_source: this.utmSourceName,
					// 		utm_medium: 'SIGNUP-BUTTON',
					// 		utm_campaign: this.utmCampaignName
					// 	}
					//
					// })
				}
			}
		});
	}

	public checkAndProvideResearchFreeTrial() {
		let obj = {
			"source": "trialrequestfromsite",
			"utmsource": this.utm_source,
			"utm_campaign": this.utm_campaign,
			"utm_medium": this.utm_medium
		};

		this.homeService.getActiveModules().subscribe((result) => {
			if (!result.data) {
				this.homeService.getFreeTrialAccess(obj).subscribe(response => {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.RESEARCH_FREE_TRIAL_ACTION);
					if (response.ResponseType == "SUCCESS") {
						this.commonAlertService.successToast('Thank you! Your 7 day Free Trial for Taxmann Research platform is now activated.');
						gtag('event', 'conversion', {
							'send_to': 'AW-974329740/th5LCMur7YAYEIyvzNAD'
						});
						const data = {
							userEmail: this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL),
							appURL: window.location.href,
						}
						gtag('event', 'research_seven_days_free_trail', data);
						this.router.navigate(['/research']);
					} else if (response?.ResponseType === 'TRIAL_ALREADY_TAKEN') {
						this.globalUtility.showLoader = false;
						this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.RESEARCH_FREE_TRIAL_ACTION);
						this.commonAlertService.errorToast('Our system shows that this mobile number has already been used for a free trial, and free trials can only be activated once per number. If you need assistance, please contact our support team.')
						return;
					}
				}, error => {
					this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.RESEARCH_FREE_TRIAL_ACTION);
					this.commonAlertService.errorToast('Error in getting free trial access.')
				});
			} else {
				this.commonAlertService.errorToast('You have already purchased/availed the Free Trial for Taxmann Research platform.');
			}
		})

	}

	@gaEventTrack('', 'Free-Trial', 'Click', 0, -1)
	submitEmail(categorylabel) {
		let login = this.globalUtility.isLoggedIn();
		if (!login) {
			if (this.emailCtrl.valid) {
				this._checkForUserREGISTEREDOnNot();
			} else {
				this.commonAlertService.errorToast('Enter Valid Email')
			}
		} else {
			this.checkAndProvideResearchFreeTrial();
		}
	}

	get categorylabel() {
		if (window.location.pathname == '/') { return 'Umberella-Page' } else if (window.location.pathname == '/research/home') {
			return 'Research-Home_Unsigned'
		}

	}


}
