export const NETCORE_EVENT_LIST = {
    PRODUCT_VIEWED : 'product_viewed',
    ADD_TO_CART : 'product_addtocart_clicked',
    BUY_NOW : 'product_buynow_clicked',
    ADD_TO_WISHLIST : 'product_wishlisticon_clicked',
    PROCEED_TO_CHECKOUT : 'cart_proceedtocheckout_clicked',
    PROCEED_TO_PAYMENT : 'checkout_proceedtopay_clicked',
    PAYMENT_INITIATED : 'payment_initiated',
    PAYMENT_SUCCESSFUL : 'payment_successful',
    PAYMENT_FAILED : 'payment_failed',
    ORDER_CONFIRMED : 'order_confirmed',
    PAGE_BROWSE : 'page_visited',
    SIGN_UP_INITIATE : 'user_signup_initiated',
    SIGN_UP_CONFIRMED : 'user_signup_confirmed',
    SIGNED_IN : 'user_signedin',
    DOCUMNET_RESEARCH_FREE_TRIAL_CLICKED:'document_get_research_freetrial_clicked',
    PRICING_RESEARCH_FREE_TRIAL_CLICKED :'pricing_get_research_freetrial_clicked',
    HOME_RESEARCH_FREE_TRIAL_CLICKED: 'home_get_research_freetrial_clicked',
    HOME_RESEARCH_BLOCK_FREE_TRIAL_CLICKED: 'home_research_block_get_research_freetrial_clicked',
    RESEARCH_FREE_TRIAL_VERIFED: 'research_freetrial_email_verified',
    RESEARCH_FREE_TRIAL_ACTIVATED: 'research_freetrial_activated',
    DOCUMNET_PRACTICE_FREE_TRIAL_CLICKED: 'document_get_practice_freetrial_clicked',
    PRICING_PRACTICE_FREE_TRIAL_CLICKED: 'pricing_get_practice_freetrial_clicked',
    HOME_BLOCK_PRACTICE_FREE_TRIAL_CLICKED: 'home_practice_block_get_practice_freetrial_clicked',
    PRACTICE_FREE_TRIAL_VERIFIED: 'practice_freetrial_email_verified',
    PRACTICE_FREE_TRIAL_ACTIVATED: 'practice_freetrial_activated'
}

export const NETCORE_API_FIELD_ID_MAPPING_LIST = {
    FIRST_NAME:'FIRST_NAME',
    LAST_NAME:'LAST_NAME',
    PROFESSION:'PROFESSION',
    DESIGNATION:'DESIGNATION',
    MOBILE_NO:'MOBILE_NO',
    AREA_OF_SPECIALISATION:'AREA_OF_SPECIALISATION',
    CITY:'CITY',
    STATE:'STATE',
    COUNTRY:'COUNTRY',
    PRODUCT_CATEGORY_SUBJECT:'PRODUCT_CATEGORY_SUBJECT',
    PRODUCT_TYPE:'PRODUCT_TYPE',
    COMPANY_TYPE:'COMPANY_TYPE',
    SUBSCRIPTION_TYPE:'SUBSCRIPTION_TYPE',
}