import { Injectable, Inject, RendererFactory2, ViewEncapsulation, } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GlobalUtility } from '../utility/global-utility';

import { DOCUMENT } from '@angular/common';
@Injectable({
	providedIn: 'root'
})
export class MetaService {
	imageUrl = this.globalUtility.makeImageUrl();

	constructor(private titleService: Title, private meta: Meta, @Inject(DOCUMENT) private dom,
		private globalUtility: GlobalUtility, private rendererFactory: RendererFactory2) { }

	updateMetaInfoInCurrentPage(title: string, description: string, url: string, image: string, metaDescription: string, metakeywords: string, metaTitle: string, card?: string, metaRobots?: string) {

		if (metaTitle) {
			this.titleService.setTitle(metaTitle);
		} else {
			// metaTitle = this.titleService.getTitle();
		}
		if (metakeywords === null) {
			metakeywords = "";
		}

		if (image)
			image = image && image.indexOf('?') > 0 ? image + '&w=1200' : image + '?w=1200';
		this.meta.updateTag({ name: 'keywords', content: metakeywords || '' });
		this.meta.updateTag({ name: 'description', content: metaDescription || '' });
		
		this.meta.updateTag({ property: 'og:title', content: title || metaTitle || '' });
		this.meta.updateTag({ property: 'og:type', content: 'website' });
		this.meta.updateTag({ property: 'og:url', content: url || window.location.href });
		this.meta.updateTag({ property: 'og:description', content: description || metaDescription || '' });
		this.meta.updateTag({ property: 'og:image', content: image || (this.imageUrl + 'taxmann225x225.jpg') });
		this.updateRobots(metaRobots);
		this.meta.updateTag({ name: 'twitter:card', content: card || "summary" });
		this.meta.updateTag({ name: 'twitter:site', content: '@taxmann' });
		this.meta.updateTag({ name: 'twitter:title', content: title || metaTitle || '' });
		this.meta.updateTag({ name: 'twitter:description', content: description || metaDescription || '' });
		this.meta.updateTag({ name: 'twitter:image', content: image || (this.imageUrl + 'taxmann225x225.jpg') });

		//this.meta.updateTag({ name: "Robots", content: "noindex,nofollow" });
	}

	updateSiteName(siteName: string) {
		this.meta.updateTag({ property: 'og:site_name', content: siteName });
	}

	updateFacebookDomainVerification(name, content) {
		this.meta.updateTag({ name, content });
	}


	updateStaus404() {
		this.meta.updateTag({ name: 'render:status_code', content: "404" });
	}
	updateStaus200() {
		this.meta.updateTag({ name: 'render:status_code', content: "200" });
	}

	createCanonicalURL(url: string) {
		if (url) {
			let ele = this.dom.querySelector(`link[rel='canonical']`) || null;
			if (ele) {
				let href = ele.href;
				if (href.localeCompare(url) == 0) {
					return;
				}
			}
			let link: HTMLLinkElement = this.dom.createElement('link');
			link.setAttribute('rel', 'canonical');
			this.dom.head.appendChild(link);
			link.setAttribute('href', url);
		}

	}
	removeTag(attrSelector: string) {
		if (attrSelector) {
			try {
				const renderer = this.rendererFactory.createRenderer(this.dom, {
					id: '-1',
					encapsulation: ViewEncapsulation.None,
					styles: [],
					data: {}
				});
				const head = this.dom.head;
				if (head === null) {
					throw new Error('<head> not found within DOCUMENT.');
				}
				const linkTags = this.dom.querySelectorAll('link[' + attrSelector + ']');
				for (const link of linkTags) {
					renderer.removeChild(head, link);
				}
			} catch (e) {
				console.log('Error while removing tag ' + e.message);
			}
		}
	}
	createRedirectUrl(url) {
		if (url) {
			let ele = this.dom.querySelector(`meta[http-equiv='refresh']`) || null;
			if (ele) {
				let href = ele.content;
				let urlRef = href.slice(6).replaceAll("'", '');
				if (urlRef.localeCompare(url) == 0) {
					return;
				}
			}
			let meta: HTMLLinkElement = this.dom.createElement('meta');
			meta.setAttribute('http-equiv', 'refresh');
			this.dom.head.appendChild(meta);
			meta.setAttribute('content', `0;URL='${url}'`);
		}

		
	}

	updateRobots(metaRobots=''){
        if(window.location.href.indexOf('www.') === -1){
            this.meta.updateTag({ name: 'robots', content: "noindex, nofollow"  })
			
        } else {
			this.meta.updateTag({ name: 'robots', content: metaRobots || '' });
		}
		if(this.globalUtility.isLoggedIn()) {
			this.meta.updateTag({ name: 'CCBot', content: "nofollow"  })
		}
		if(window.location.href.indexOf('nalsar') > -1){
			this.meta.updateTag({ name: 'robots', content: "noindex, nofollow"  })
		}           
    }

}