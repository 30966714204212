import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponse } from '../../models/interfaces/api-response';
import { HttpHelperService } from '../http-helper.service';
import { GlobalUtility } from 'src/app/utility/global-utility';
import { BookstoreUrl } from 'src/app/api-url/bookstore-url.enum';
import { map } from 'rxjs/operators';
import { TaxmannUrl } from 'src/app/api-url/taxmann-url.enum';
import { AddressType } from 'src/enums/enum';
import { ResearchAPiUrl } from 'src/app/api-url/research-api-url.constant';
import { TaxPracticeAPiUrl } from 'src/app/api-url/tax-practice-url.enum';
import { LocalStorageDataModel } from 'src/constants/local-storage-data-model';
import { Router } from '@angular/router';
import {HOMAPGE_API} from "../../v2/homepage/constant/api.constant";
import { UserDeviceTrackingService } from '../taxmann-service/userDeviceTrack.service';
import { NETCORE_API_FIELD_ID_MAPPING_LIST } from 'src/constants/netcore.eventList';

@Injectable({
	providedIn: 'root'
})
export class HomeService {
	netCorePayload = {};
	constructor(
		private router: Router,
		private _httpHelperService: HttpHelperService,
		private globalUtility: GlobalUtility,
		private userDeviceTrackingService: UserDeviceTrackingService
	) { }

	getAditFaqList(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADIT_FAQS);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getAditFBooksList(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADIT_FBOOKS);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getKitFileData(fileModel: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_KIT_FILE_ADIT);
		return this._httpHelperService.post(url, fileModel, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getADITCallBack(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_ADIT_CALLBACK);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getLeftMenuData(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.LEFT_MENU_DATA);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getAllCategoriesBook(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ALL_CATEGORIES_BOOK);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getRecommendedBook(noOfBook: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.RECOMMENDED_BOOK + noOfBook);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getRecommendedBookNew(noOfBook: number): Observable<APIResponse> {
		let url = this.globalUtility.makeRedisUrl(BookstoreUrl.RECOMMENDED_BOOK_NEW + '?count=' + noOfBook);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postRecommendedBookNew(data: Object, isLoggedIn: boolean): Observable<APIResponse> {
		let url = this.globalUtility.makeRedisUrl(BookstoreUrl.RECOMMENDED_BOOK_NEW_POST);
		return this._httpHelperService.post(url, data, isLoggedIn ? true : false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getProductDetail(productId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.PRODUCT_DETAIL + productId);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}


	addWishlist(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_WISHLIST);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	removeWishList(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(TaxmannUrl.REMOVE_WISHLIST);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAllSpecificProduct(specificId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SPECIFIC_PRODUCT + specificId + '/200');
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	checkDelivery(pincode: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CHECK_DELIVERY + pincode);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	notifyMe(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.NOTIFY_ME);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getCartItems(checkoutType: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ALL_CART_ITEMS + '/' + checkoutType);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getCartDetails(data) {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_CART_DETAILS_NEW);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getStudentsCartDetails(data) {
		let url = this.globalUtility.makeCACSUrl(BookstoreUrl.GET_STUDENTS_CART_DETAILS_NEW);
		return this._httpHelperService.CartpostRefresh(url, data, true)
			.pipe(map(this.globalUtility.CACSCartfilterResponse));
	}

	deleteCartItem(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.DELETE_CART_ITEMS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addUpdateCartItem(data: any): Observable<APIResponse> {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.WEBSITE_REFERER, '');
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_UPDATE_CART_ITEMS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addUpdateStudentsCartItem(data: any): Observable<APIResponse> {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.WEBSITE_REFERER, '');
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		let url = this.globalUtility.makeCACSUrl(BookstoreUrl.ADD_ITEM_STUDENT_CART);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	buyNowADITCartItem(data: any): Observable<APIResponse> {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.WEBSITE_REFERER, '');
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		let url = this.globalUtility.makeNodeDashboardUrl(BookstoreUrl.ADD_UPDATE_REACT_CART_ITEMS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}
	addUpdateReactCartItem(data: any): Observable<APIResponse> {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.WEBSITE_REFERER, '');
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		let url = this.globalUtility.makeNodeDashboardUrl(BookstoreUrl.ADD_UPDATE_REACT_CART_ITEMS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}
	getShippingAddress(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SHIPPING_ADDRESS);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getBillingAddress(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.BILLING_ADDRESS);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	deleteAddress(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.DELETE_ADDRESS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addUpdateAddress(data: any, addressType: number): Observable<APIResponse> {
		let tempUrl = ''
		if (addressType === AddressType.BILLING_ADRESS) {
			tempUrl = BookstoreUrl.ADD_UPDATE_BILLING_ADDRESS;
		} else {
			tempUrl = BookstoreUrl.ADD_UPDATE_SHIPPING_ADDRESS;
		}
		let url = this.globalUtility.makeUrl(tempUrl);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAuthorList(alpha: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.AUTHOR_LIST + alpha);
		return this._httpHelperService.get(url, {}, false)
	}

	getAuthorDetail(authorId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.AUTHOR_DETAIL + authorId);
		return this._httpHelperService.get(url, {}, false)
	}

	getGstinDetail(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.VIEW_GSTIN_DETAIL);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addUpdateGstin(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_GSTIN_DETAIL);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getEntityTypes(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.All_ENTITY_TYPES);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getRefferedByFirstList(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.REFFERED_ORDER_SOURCE);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	deleteGstin(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.DELETE_GSTIN);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	saveTempOrder(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SAVE_TEMP_ORDER);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	saveRecurringTempOrder(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SAVE_TEMP_ORDER_RECURRING);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postRecurringTempOrder(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.POST_ORDER_RECURRING);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	saveShopseTempOrder(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SAVE_SHOPSE_TEMP_ORDER);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postProductOrder(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.POST_PRODUCT_ORDER);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addDataInToOriginal(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SAVE_DDCHECK_ORDER);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getInvoiceData(orderId: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.INVOICE + orderId);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getStudentsInvoiceData(orderId: string): Observable<APIResponse> {
		let url = this.globalUtility.makeCACSUrl(BookstoreUrl.STUDENTS_INVOICE + `?orderNo=${orderId}`);
		return this._httpHelperService.getRefresh(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAllCategoryProductFromIndex(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CATEGORIZED_PRODUCT_FROM_INDEX);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getSubjectListFromIndex(catId: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SUBJECT_INDEX_LIST + catId);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getSubjectCategoryName(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SUBJECT_CATEGORY_NAME);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAutoCompleteContent(queryText: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.BOOKSTORE_SUGGESTION + queryText);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	buySubscription(data: any, env ?: string): Observable<APIResponse> {
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.WEBSITE_REFERER, '');
		this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.PREVIOUS_URL, this.router.url)
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_MULTIPLE_PRODUCTS);
		if(env == 'node') {
			url = this.globalUtility.makeNodeDashboardUrl(BookstoreUrl.ADD_UPDATE_REACT_CART_ITEMS)
		}
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getProductSearchFromIndex(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.PRODUCT_SEARCH_FROM_INDEX);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getTrackOrder(orderId: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.BOOKSTORE_TRACK_ORDER + orderId);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getTrackOrderInTransit(trackTransitInfo: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.TRACK_SHIPMENT);
		// return this._httpHelperService.post(url, trackTransitInfo, true)
		return this._httpHelperService.get(url, trackTransitInfo, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	shareProductOnEmail(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SHARE_PRODUCT_VIA_EMAIL);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getCatalogues(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CATALOGUES);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getResearchAutocomplete(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(ResearchAPiUrl.GLOBAL_RESEARCH_SUGGESTIONS);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getUserBasicDetails(): Observable<APIResponse> {
		let url = this.globalUtility.makeNodeDashboardUrl(ResearchAPiUrl.USER_BASIC_DETAILS);
		return this._httpHelperService.get(url, {}, true);
	}

	fillfeedbackforOLDsite(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(ResearchAPiUrl.FEEDBACK_OLD_SITE);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	checkCodPinCode(pincode: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CHECK_COD_PINCODE + pincode);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getLeftMenuVirtualBook(): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.LEFT_MENU_VIRTUAL_BOOK);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAllCategoriesVirtualBook(): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.ALL_CATEGORIES_VIRTUAL_BOOK);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getRecommendedVirtualBook(noOfBook: number): Observable<APIResponse> {
		let url = this.globalUtility.makeRedisUrl(BookstoreUrl.RECOMMENDED_VIRTUAL_BOOK + '?key=virtual_book&count=' + noOfBook);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAllSpecificVirtualProduct(specificId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.SPECIFIC_VIRTUAL_PRODUCT + specificId + '/200');
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualProductDetail(productId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.VIRTUAL_PRODUCT_DETAIL + productId);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualSubjectListFromIndex(catId: any): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.VIRTUAL_SUBJECT_INDEX_LIST + catId);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualSubjectCategoryName(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.VIRTUAL_SUBJECT_CATEGORY_NAME);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponseWithNullData));
	}

	getAllCategoryVirtualProductFromIndex(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.VIRTUAL_CATEGORIZED_PRODUCT_FROM_INDEX);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activateVirtualBookByKey(obj: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ACTIVATE_VIRTUAL_BOOK_BY_KEY);
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getBookList(count: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_READER_BOOK_LIST);
		url += `/${count}`
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getUserDetails(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.USER_ACCOUNT_INFO);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activateFreemiumService(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ACTIVATE_FREE_TRIAL);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getAllCategoriesVirtualJournal(count: number): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.ALL_CATEGORIES_VIRTUAL_JOURNAL);
		url = url + count
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualJournalProductDetail(productId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.VIRTUAL_JOURNAL_PRODUCT_DETAIL + productId);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activateFreemiumServiceForVirtualJournal(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.ACTIVATE_VIRTUAL_JOURNAL_FREE_TRIAL);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualJournalList(count: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_VIRTUAL_JOURNAL_READER_BOOK_LIST);
		url += count;
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualJournalSubscribedProductDetail(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.GET_VIRTUAL_JOURNAL_ISSUES_LIST);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualBookAutoCompleteContent(queryText: string): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.VIRTUAL_BOOK_SUGGESTION + queryText);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	getVirtualJournalIssues(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.GET_VIRTUAL_JOURNAL_ISSUES_LIST);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualJournalPreviousIssues(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeVirtualUrl(BookstoreUrl.VIRTUAL_JOURNAL_PREVIOUS_ISSUES);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getVirtualProductSearchFromIndex(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.VIRTUAL_PRODUCT_SEARCH_FROM_INDEX);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}
	getRazorpaySecretKey(env: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.RAZORPAY_SECRET_KEY + env);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getTpplRazorPaySecretKey(env: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.PUBLICATION_RAZORPAY_SECRET_KEY + env);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getProductCatalogues(endpoint: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_PRODUCT_CATALOGUE) + endpoint;
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	addMultipleCartItems(data: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ADD_MULTIPLE_CART_ITEMS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	expireVirtualBookFreeTrial(productId: number): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(TaxmannUrl.DEACTIVATE_VIRTUAL_BOOK_FREE_TRIAL);
		return this._httpHelperService.post(url, { bookid: productId }, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getBookListOffers(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_PRODUCT_OFFERS);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getICAIUserDetails(): Observable<APIResponse> {
		this.globalUtility.removeFromLocalStorage(LocalStorageDataModel.WEBSITE_REFERER);
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_ICAI_USER_DETAILS);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	validateICAIUser(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.POST_ICAI_MEMBER_DETAILS);
		return this._httpHelperService.post(url, data, true)
		// .pipe(map(this.globalUtility.filterResponse));
	}

	getDealerProductCatalogues(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.GET_DEALER_PRODUCT_CATALOGUE);
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	encodeDealerId(endpoint: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ENCODE_DEALER_ID) + endpoint;
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	checkHyperLocalDelivery(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CHECK_HYPER_DELIVERY);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postWebinarDetails(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.WEBINAR_DETIALS);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postWebinarDataAfterwards(data: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.WEBINAR_DATA_AFTERWARDS);
		return this._httpHelperService.post(url, data, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activateVirtualJournalByKey(obj: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ACTIVATE_VIRTUAL_JORNAL_BY_KEY);
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	public getInPracticeGlobalTopicCountFollowedByUser(): Observable<APIResponse> {
		const followGlobalTopicCountURL: string = this.globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.GET_GLOBAL_TOPIC_COUNT_FOLLOW_BY_USER);
		return this._httpHelperService.get(followGlobalTopicCountURL, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	postSearchLogs(obj: any, search: string): Observable<APIResponse> {
		let url = this.globalUtility.makeRedisUrl(TaxmannUrl.SEARCH_LOG);
		let searchTextNew = search.replace(/[`'\\]/gi, '');
		searchTextNew = encodeURIComponent(searchTextNew);
		obj['searchKeyword'] = searchTextNew;
		if (!this.globalUtility.isLoggedIn()) {
			obj["verifyToken"] = "NO";
		}
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getPopularProducts(endpoint: string): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.POPULAR_PRODUCTS) + endpoint;
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	getPricingFAQ(payload: Object): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(TaxmannUrl.PRICING_FAQ);
		return this._httpHelperService.post(url, payload, false)
			.pipe(map(this.globalUtility.filterResponse));
	}


	submitShopSeObject(obj) {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SHOPSE_ORDER_SAVE);
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this.globalUtility.filterResponse));
	}
	public isRegisteredUser(email) {
		const verifiedUserurl: string = this.globalUtility.makeUrl(TaxmannUrl.IS_REGISTERED_USER);
		return this._httpHelperService.post(verifiedUserurl, { emailid: email }, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	public getFreeTrialAccess(obj) {
		const getFreeTrailURL: string = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.FREE_TRAIL_AFTER_REGISTRATION);
		return this._httpHelperService.postwithoutCheckResponse(getFreeTrailURL, obj, true)  ;
	}

	public getFreeTrialAccessWithToken(data, token) {
		const getFreeTrailURL: string = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.FREE_TRAIL_AFTER_REGISTRATION);
		return this._httpHelperService.postWithToken(getFreeTrailURL, data, token)
	}

	public getActiveModules() {
		const getFreeTrailURL: string = this.globalUtility.makeNodeDashboardUrl(TaxmannUrl.CHECK_ACTIVE_RESEARCH);
		return this._httpHelperService.get(getFreeTrailURL, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}
	saveUtmDetails(data): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(TaxmannUrl.SAVE_UTM_DETAILS);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	saveBudgetQrUsers(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.SAVE_BUDGET_QR_USER);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activate30DaysPracticeQR(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ACTIVATE_30_DAYS_PRACTICE_QR);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	activateBudgetBookByQRKey(obj: any): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.ACTIVATE_BUDGET_VIRTUAL_BOOK_BY_QR_KEY);
		return this._httpHelperService.post(url, obj, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	checkQRCouponReedemed(): Observable<APIResponse> {
		let url = this.globalUtility.makeUrl(BookstoreUrl.CHECK_QR_COUPON_REEDEMED);
		return this._httpHelperService.get(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	showBudgetMarathonTv() {
		let url = this.globalUtility.makeUrl(ResearchAPiUrl.BUDGET_MARATHON_TV);
		return this._httpHelperService.get(url, {}, false).pipe(map(response => response));
	}

	pushUtmSourceInformation(description: string, deleteCampaignAfterPush: boolean = false): void {
		let campaignName = this.globalUtility.getDataFromLocalStorage('activeCampaign');
		if (!campaignName) {
			return
		}
		let userIp = this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.USER_IP);
		let campaignUrl = this.globalUtility.getDataFromLocalStorage('activeCampaignUrl');
		let payload = {
			IpAddress: userIp,
			Action: description,
			CampaignName: campaignName,
			UtmDetails: this.globalUtility.getDataFromLocalStorage(campaignName) ? JSON.parse(this.globalUtility.getDataFromLocalStorage(campaignName)) : {},
			ComponentUrl: this.router.url,
			CampaignUrl: campaignUrl,
			uuid:this.globalUtility.getDataFromLocalStorage('utm_campaign_uuid')
		}
		let isLoggedIn = this.globalUtility.isLoggedIn();
		let url = isLoggedIn ? 'tracking/userActionTracking' : 'tracking/userActionTracking?verifyToken=NO';
		this._httpHelperService.postMethodWithoutErrorHandling(this.globalUtility.makeMarketingUrl(url), payload, isLoggedIn).subscribe({
			next: () => {
				if (deleteCampaignAfterPush) {
					this.globalUtility.removeFromLocalStorage('activeCampaign');
					this.globalUtility.removeFromLocalStorage(campaignName);
					this.globalUtility.removeFromLocalStorage('utm_campaign_uuid')
				}
			}
		});
	}

	public getPracticeFreeTrialAccess(data) {
		const getFreeTrailURL: string = this.globalUtility.makeNodeDashboardUrl(TaxPracticeAPiUrl.FREE_TRAIL_AFTER_REGISTRATION);
		return this._httpHelperService.post(getFreeTrailURL, data, true)
	}

	public postEndmileAPIEventTrack(data) {
		let url: string = this.globalUtility.makeEndmileAPItrackUrl(TaxmannUrl.ENDMILE_EVENT_TRACK);
		return this._httpHelperService.postwithoutCheckResponseAndErrorHandler(url, data, false)
	}

	public subscribeTopostEndmileAPIEventTrack(payload:any){
		// if (window.location.hostname.indexOf('www.taxmann.com') !== -1) {
		this.postEndmileAPIEventTrack(payload).subscribe();
		// }
		}

	public registerUserByPPC(data) {
		const getFreeTrailURL: string = this.globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.REGISTER_PPC_USER);
		return this._httpHelperService.post(getFreeTrailURL, data, false)
	}

	public postAddressPPC(data) {
		const getFreeTrailURL: string = this.globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.POST_ADDRESS_PPC);
		return this._httpHelperService.post(getFreeTrailURL, data, true)
	}

	public postAddressPPCWithToken(data, token) {
		const getFreeTrailURL: string = this.globalUtility.createTaxPracticeAPIUrl(TaxPracticeAPiUrl.POST_ADDRESS_PPC);
		return this._httpHelperService.postWithToken(getFreeTrailURL, data, token)
	}

	freeTrialNetcoreEventTrack(eventName:string,email?:string){
		this.netCorePayload = {
		  [NETCORE_API_FIELD_ID_MAPPING_LIST.FIRST_NAME] : this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.FIRST_NAME),
		  [NETCORE_API_FIELD_ID_MAPPING_LIST.LAST_NAME] : this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.LAST_NAME),
		  email : this.globalUtility.getDataFromLocalStorage(LocalStorageDataModel.EMAIL) || email,
		  pageUrl: window.location.href
		}
		let utmObj = this.globalUtility.createUtmObj();
		this.netCorePayload = { ...this.netCorePayload, ...utmObj }
		if(this.globalUtility.isLoggedIn()){
		this.globalUtility.netCoreEventTrack(eventName,this.netCorePayload, '', email);
		}else{
			if(email){
				this.globalUtility.netCoreEventTrack(eventName,this.netCorePayload, '', email);	
			}else{
			 this.netCorePayload['eventName'] = eventName;
			 this.globalUtility.setDataOnLocalStorage(LocalStorageDataModel.NETCORE_EVENT_TRACK,JSON.stringify(this.netCorePayload));
			}
		}
	  }

	  getCartCount(data) {
		let url = this.globalUtility.makeNodeDashboardUrl(BookstoreUrl.GET_TOTAL_CART_ITEMS);
		return this._httpHelperService.getWithoutErrorHandling(url, {}, true)
		.pipe(map(this.globalUtility.filterResponse));
	  }
	  public getRenewalAlert(): Observable<APIResponse> {
		const url: string = this.globalUtility.makeNodeBookstoreUrl(ResearchAPiUrl.RENEWAL_ALERT);
		return this._httpHelperService.getWithoutErrorHandling(url, {}, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	public getNewADITBooks<APIResponse>() {
		let url = this.globalUtility.makeNodeBookstoreUrl(HOMAPGE_API.NEW_BOOKS_BY_COLLECTION_TAG_ID + '/8');
		return this._httpHelperService.get(url, {}, false)
			.pipe(map(this.globalUtility.filterResponse));
	}

	public postRenewalAlert(data:any): Observable<APIResponse> {
		const url: string = this.globalUtility.makeNodeBookstoreUrl(ResearchAPiUrl.RENEWAL_ALERT);
		return this._httpHelperService.post(url, data, true)
			.pipe(map(this.globalUtility.filterResponse));
	}

	sendUserDeviceTracking(fileId?:string, printDownloadURL?:string){
		let payload = {};
		let data = this.userDeviceTrackingService.getUserSiteTrackingData(fileId);
		if(printDownloadURL){
 		 data.componentURL = `${window.location.href.split(`/${fileId}`) ? window.location.href.split(`/${fileId}`)[0] : ''}/${fileId}${printDownloadURL}`
		}
		payload['TrackingList'] = [data];
		if(this.globalUtility.isLoggedIn()){}else{
			payload['verifyToken'] = 'NO';
		}
		this.userDeviceTrackingService.postUserSiteTracking(payload).subscribe();
	}

	getStudentCartCount(data) {
		let url = this.globalUtility.makeCACSUrl(BookstoreUrl.GET_TOTAL_CART_ITEMS);
		return this._httpHelperService.getWithoutErrorHandling(url, {}, true)
		.pipe(map(this.globalUtility.filterResponse));
	  }

}
