export const ResearchAPiUrl = {
    /** sidebar url */
    GET_SIDEBAR_ITEMS: 'research/getLeftmenu',

    /** acts  url */
    GET_ACTS_LAWS: 'research/getActsLaws',
    GET_ACTS_LIST: 'research/getActsList',
    GET_SECTION_LIST: 'research/getSectionList',
    GET_ACTS_CHAPTER_SECTION_LIST: 'research/getActChapterSectionsList',
    PRINT_CHAPTER_LIST: 'research/printActChapterSectionsList',
    PRINT_CONTENT_DATA: 'research/getprintContentList',


    /**file content url */
    GET_FILE_CONTENT: 'research/getFileText',
    GET_FILE_CONTENT_FOOT_NOTE: 'research/getFileContentFootNote',
    GET_TOP_STORY_FILE_TEXT: 'research/getTopStoryFileText',
    GET_TOP_STORY_FREE_DETAILS: 'research/getResearchFreeText',
    GET_ACT_AMMENDMENT: 'research/getActAmendment',
    GET_FILE_TEXT_COMPARISON: 'research/getFileTextComparision',
    GET_FULL_CITATION: 'research/getFullCitation',
    GET_CASES_ON_CIRNOT: 'research/CaseListOfCirnot',
    GET_FILE_HTML_RTF: 'research/getFilehtmlrtf',
    SAVE_EEROR_LOG: 'research/saveerrorlog',
    GET_MORE_WORD_PHRASE: 'research/getCaseLawsMoreWordPhrase',

    /** rules url*/
    GET_RULES_LAWS: 'research/getRulesLaws',
    GET_RULE_COMBO_LIST: 'research/getRuleList',
    GET_RULE_LIST: 'research/getRuleNoList',
    GET_SUBJECT_RULE_LIST: 'research/getSubjectRuleNoList',
    PRINT_SUBJECT_LIST: '/research/printRuleSubjectList',

    /**forms url */
    GET_FORMS_LAWS: 'research/getFormsLaws',
    GET_FORMS_LIST: 'research/getFormsList',
    GET_FORM_NO_LIST: 'research/getFormNoList',
    GET_FORMS_YEARS: 'research/getFormsYears',
    GET_FORMS_LANGUAGES: 'research/getFormsLanguage',

    /**caselaws url */

    GET_CASE_LAWS: 'research/getCaseLaws',
    GET_MORE_FILTERS: 'research/getCaseLawsMore',
    GET_CASE_LAWS_HEADNOTE: 'research/getCaseLawsHeadnote',

    /** cirnot url */
    GET_CIRNOT: 'research/getcirnot',

    /** articles url */
    GET_ARTICLES: 'research/getArticles',
    GET_MORE_ARTICLES: 'research/getArticlesMore',

    /** commentary */
    GET_COMMENTARY_ON_FILTER: 'research/getCommentaryon',
    GET_COMMENTARY: 'research/getCommentaryOnDetailFirstLevel',
    GET_COMMENTARY_NEXT_LEVEL: 'research/getCommentaryOnDetailNextLevel',
    PRINT_COMMENTARY_DATA: 'research/PrintCommentaryData',
    GET_COMMENTRY_UPDATE_LABELS: 'research/getCommentaryLabels',

    /** reports url */
    GET_REPORTS: 'research/getReports',
    GET_REPORTS_NEXT_LEVEL: 'research/getReportsNextLevel',
    GET_INFORMAL_REPORT: 'research/getListingInformalReports',
    PRINT_REPORTS_DATA: 'research/printReport',
    PRINT_INFORMAL_REPORT: 'research/printListingInformalReports',

    /** REDIS */

    SAVE_REDIS_USER_DATA: 'save_user_data',
    GET_REDIS_USER_DATA: 'get_user_data',

    /** faq url */
    GET_FAQ: 'research/getFaqData',
    GET_FAQ_NEXT_LEVEL: 'research/getFaqNextLevel',
    GET_FAQ_ON_FILTER: 'research/getfaqs',
    PRINT_FAQ_DATA: 'research/PrintFaqData',

    /** gst tarrif */
    GET_TARRIF_LAWS: 'research/getGstTarrif',
    GET_TARRIF_DATA: 'research/getGstTarrifData',

    /** cbdt url */
    GET_CBDT_TYPE: 'research/getCbdtType',
    GET_CBDT_DATA: 'research/getCbdtDetailFirstLevel',
    GET_CBDT_NEXT_LEVEL: 'research/getCbdtNextLevel',
    GET_CBDT_SECTION_LIST: 'research/getCbdtSectionList',
    PRINT_CBDT_DATA: 'research/PrintCbdtData',

    /** dtc  url*/
    GET_DTC_TYPE: 'research/getDtcType',
    GET_DTC_DATA: 'research/getDtcData',
    GET_DTC_CHAPTER_LIST: 'research/getDtcChapterSectionList',
    PRINT_DTC_CHAPTER_SECTION_LIST: 'research/printDtcChapterSectionsList',

    /** dtl/gst practice url */
    GET_TAX_PRACTICE_TYPE: 'research/getTaxPracticeType',
    GET_TAX_PRACTICE_SECOND_TYPE: 'research/getTaxPracticeSecondType',
    GET_TAX_PRACTICE_DATA: 'research/getTaxPracticeFirstLevel',
    GET_TAX_PRACTICE_NEXT_LEVEL: 'research/getTaxPracticeNextLevel',
    PRINT_TAX_PRACTICE_DATA: 'research/PrintTaxPracticeData',

    /** dtaa/treaty url */
    GET_DTAA: 'research/getDta',
    GET_DTAA_NEXT_LEVEL: 'research/getDtaNextLevel',
    PRINT_DTAA_DATA: 'research/PrintDtaData',

    /** ttc url */
    GET_TTC: 'research/getIltCommentary',
    GET_TTC_NEXT_LEVEL: 'research/getIltCommentaryNextLevel',
    PRINT_TTC_DATA: 'research/PrintIltCommentaryData',

    /** all url */
    GET_ALL_SECTION: 'research/getAllabout',
    GET_ALL_ABOUT_DATA: 'research/getAllAboutData',
    GET_ALL_ABOUT_NEXT_LEVEL_DATA: 'research/getAllAboutNextLevelData',

    /** all about aaa url */
    GET_ALL_SECTION_AAA: 'research/getAllaboutAAA',
    GET_ALL_ABOUT_AAA_DATA_IND_AS: 'research/getAllAboutDataAAAIndAs',
    GET_ALL_ABOUT_AAA_NEXT_LEVEL_DATA_IND_AS: 'research/getAllAboutNextLevelDataAAAIndAs',
    GET_ALL_ABOUT_AAA_DATA_AS: 'research/getAllAboutDataAAAAs',
    GET_ALL_ABOUT_AAA_NEXT_LEVEL_DATA_AS: 'research/getAllAboutNextLevelDataAAAAs',
    GET_ALL_ABOUT_AAA_DATA_SA: 'research/getAllAboutDataAAASa',
    GET_ALL_ABOUT_AAA_NEXT_LEVEL_DATA_SA: 'research/getAllAboutNextLevelDataAAASa',

    /** all ilt url */
    GET_ALL_ILT_ARTICLES: 'research/getAllaboutIlt',
    GET_ALL_ABOUT_ILT_DATA: 'research/getAllAboutDataIlt',
    GET_ALL_ABOUT_NEXT_LEVEL_DATA_ILT: 'research/getAllAboutNextLevelDataIlt',

    /** accounting url */

    GET_ACCOUTNING_IND_AS_DATA: 'research/getAAAAccountingIndAsData',
    GET_ACCOUTNING_AS_DATA: 'research/getAAAAccountingAsData',
    GET_ACCOUTNING_ICDS_DATA: 'research/getAAAAccountingIcdsData',
    GET_ACCOUTNING_CAS_DATA: 'research/getAAAAccountingCasData',
    GET_ACCOUTNING_IND_GAS_DATA: 'research/getAAAAccountingIndGasData',
    GET_ACCOUTNING_CASE_STUDIES_DATA: 'research/caseStudiesParentData',
    GET_ACCOUTNING_CASE_STUDIES_CHILD_DATA: 'research/caseStudiesChildData',

    /** auditing url */

    GET_AUDITING_AS_DATA: 'research/getAAAAuditingASData',
    GET_AUDITING_CAS_DATA: 'research/getAAAAuditingCASData',
    GET_AUDITING_SS_DATA: 'research/getAAAAuditingSSData',
    GET_AUDITING_CSAS_DATA: 'research/getAAAAuditingCSASData',
    GET_AUDITING_CARO_DATA: 'research/getAAAAuditingCAROData',
    GET_AUDITING_CARO_DATA_NEXT_LEVEL: 'research/getAAAAuditingCARONextData',
     
    /*NFRA url */

    GET_NFRA_IND_AS_OBSERVATION_DATA:'research/nfraIndAsObservationData',
    GET_NFRA_IND_AS_INTERPRETATION_DATA:'research/nfraIndAsInterpretationsData',
    GET_NFRA_AS_OBSERVATION_DATA:'research/nfraAsObservationData',
    GET_NFRA_AS_INTERPRETATION_DATA:'research/nfraAsInterpretationsData',
    GET_NFRA_SA_OBSERVATION_DATA:'research/nfraSaObservationData',
    GET_NFRA_SA_INTERPRETATION_DATA:'research/nfraSaInterpretationsData',
    GET_NFRA_PARENT_DATA:'research/nfraParentData',
    GET_NFRA_CHILD_DATA:'research/nfraChildData',

    /** financials url */

    GET_FINANCIALS_IND_AS_DATA: 'research/getAAAFinancialIndAsData',
    GET_FINANCIALS_IND_AS_NEXT_LEVEL_DATA: 'research/getAAAFinancialIndAsNextLevelData',
    GET_FINANCIALS_AS_DATA: 'research/getAAAFinancialAsData',
    GET_FINANCIALS_AS_NEXT_LEVEL_DATA: 'research/getAAAFinancialAsNextLevelData',
    GET_FINANCIALS_MODEL_FINANCIALS: 'research/getAAAFinancialModelFinancialsData',
    GET_STATUTORY_FINALCIALS_DATA: 'research/getAAAFinancialStatutoryFormatsData',
    GET_ILLUSTRATIVE_FINANCIALS_DATA_AS_INDAS: 'research/AAAIllustrativeFinancialAsIndAs',
    GET_ILLUSTRATIVE_FINANCIALS_DATA: 'research/AAAIllustrativeFinancial',

    /** disclosures url */
    GET_DISCLOSURES_IND_AS_DATA: 'research/getAAADisclosuresIndAsData',
    GET_DISCLOSURES_FAQ_IND_AS_DATA: 'research/getAAAFAQsOnIndAsDisclosuresData',
    GET_DISCLOSURES_AS_DATA: 'research/getAAAAsDisclosuresData',
    GET_DISCLOSURES_SCHEDULE_3_DATA: 'research/getAAAScheduleiiiChecklistDisclosuresData',
    GET_DISCLOSURES_SCHEDULE_3_DATA_INDAS: 'research/getAAAScheduleiiiIndAsChecklistDisclosuresData',
    GET_DISCLOSURE_SEBI_DATA: 'research/getAAASEBIDisclosuresData',
    GET_DISCLOSURE_IMPACT_DATA: 'research/getAAAIndAsImpactDisclosuresData',

    /** industry guidance */
    GET_ICAI_INDUSTRY: 'research/getICAIIndustry',
    GET_ICAI_INDUSTRY_DATA: 'research/getICAIIndustryData',


    /** research box flow */

    GET_ALL_FOLDERS: 'folders',
    FOLDER_HANDLER: 'document/folder',
    GET_DOC_DETAILS: 'document/',
    ADD_FAV_DOC: 'document/favourite',
    STICKY_NOTE_HANDLER: 'document/sticky_note',
    BOOKMARK_HANDLER: 'document/bookmark',
    DELETE_ALL_BOOKMARK: 'bookmarks',
    DOCUMENT_CATEGORIES: 'categories',
    DOCUMENT_SUB_CATEGORIES: 'subCategories',
    GET_ALL_DOCUMENTS: 'documents',
    GET_FAVOURITE_DOCUMENT: 'favourite/documents',
    GET_STICKY_DOCUMENT: 'sticky/documents',
    GET_BOOKMARK_DOCUMENT: 'bookmarked/documents',
    CRUD_FOLDER: 'folder',
    GET_VIEW_CATEGORIES: 'view_categories',
    GET_VIEW_SUB_CATEGORIES: 'view_subCategories',
    GET_LAST_ADDED_DOCUMENT: 'lastAddedDocuments',
    COPY_DOCUMENT: 'copyDocument',
    MOVE_DOCUMENT: 'moveDocument',
    CLEAR_FOLDER: 'folder/documents',

    /** finance bills */
    GET_FINANCE_BILL_DATA: 'research/getFinanceBillData',
    GET_FINANCE_BILL_NEXT_LEVEL_DATA: 'research/getFinanceBillNextLevel',

    /** finance acts */
    GET_FINANCE_ACTS_AND_YEAR_LIST: 'research/getFinanceActYearAndActList',
    GET_FINACE_SECTION_WISE_LIST: 'research/getFinanceActSectionWiseList',
    GET_FINANACE_ACT_CHILD_DATA: 'research/getFinanceActChildData',
    GET_FINANCE_IT_ACT_SECTION_LIST: 'research/getFinanceActSectionList',
    GET_FINANCE_IT_ACT_DATA: 'research/getFinanceActSectionListData',
    PRINT_FINANCE__ACT_CHAPTER_LIST: 'research/printFinanceActChapter',
    PRINT_SECTION_WISE_DATA: 'research/printSectionWiseData',

    /** indian acts */
    GET_PARLIAMENT_ACT_LAWS: 'research/getParliamentActsLaws',
    GET_INDIAN_ACT_BILLS_YEAR_LIST: 'research/getIndianActBillYearList',
    GET_INDIAN_ACT_BILLS_LIST: 'research/getIndianActBillList',
    GET_INDIAN_ACT_BILLS_LIST_DATA: 'research/getIndianActBillListData',
    GET_INDIAN_ACT_ORDINANCE_YEAR_LIST: 'research/getIndianActOrdinanceYearList',
    GET_INDIAN_ACT_ORDINANCE_DATA: 'research/getIndianActOrdinanceData',

    /** research views api */
    GET_ALL_VIEWS: 'views',
    VIEW_HANDLER: 'view',

    /** see more api */

    GET_CASE_LAWS_SEE_MORE_CASE_REFFERED: 'research/getCaseLawsSeeMoreCaseReferred',
    GET_CASE_LAWS_SEE_MORE_FORWARD_CASE_REFFERED: 'research/getCaseLawsSeeMoreForwordCaseReferred',
    GET_CASE_LAWS_SEE_MORE_CASES_ON_THIS_SECTION: 'research/getCaseLawsSeeMoreCasesOnthisSection',
    GET_CASE_LAWS_SEE_MORE_SECTION_INVOLVED: 'research/getCaseLawsSeeMoreSectionInvolved',
    GET_CASE_LAWS_SEE_MORE_ARTICLES_ON_THIS_CASE: 'research/getCaseLawsSeeMoreArticleOnThisCase',
    GET_CASE_LAWS_SEE_MORE_RELATED_CASE_LAWS: 'centax/getFileRelevantStories',
    GET_ACTS_SEE_MORE_CASES_ON_THIS_SECTION: 'research/getActsSeeMoreCasesOnSection',
    GET_ACTS_SEE_MORE_NOTIFICATIONS_ON_THIS_SECTION: 'research/getActsSeeMoreNotificationOnThisSection',
    GET_ACTS_SEE_MORE_CIRCULAR_ON_THIS_SECTION: 'research/getActsSeeMoreCircularOnThisSection',
    GET_ACTS_SEE_MORE_ARTICLES_ON_THIS_SECTION: 'research/getActsSeeMoreArticleOnThisSection',
    GET_ACTS_SEE_MORE_RULES_ON_THIS_SECTION: 'research/getActsSeeMoreRulesOnThisSection',

    GET_RULES_SEE_MORE_CASES_ON_THIS_RULE: 'research/getRuleSeeMoreCasesOnthisRule',
    GET_RULES_SEE_MORE_RELEVANT_ARTICLE: 'research/getRuleSeeMoreRelevantArticle',
    GET_RULES_SEE_MORE_RELEVANT_SECTION: 'research/getRuleSeeMoreRelevantSection',
    GET_RULES_SEE_MORE_RELEVANT_FORM: 'research/getRuleSeeMoreRelevantForm',
    GET_RULES_SEE_MORE_RELEVANT_COMMENTARY: 'research/getRuleSeeMoreRelevantCommentary',

    GET_CIRNOT_SEE_MORE_RELEVENT_CASES: 'research/getCirNotSeeMoreRelevantCases',
    GET_CIRNOT_SEE_MORE_RELEVENT_SECTION: 'research/getCirNotSeeMoreRelevantSection',
    GET_CIRNOT_SEE_MORE_RELEVENT_ARTICLE: 'research/getCirNotSeeMoreRelevantArticle',

    GET_ARTICLE_SEE_MORE_SECTION_INVOLVED: 'research/getArticleSeeMoreSectionInvolved',
    GET_ARTICLE_SEE_MORE_CASE_REFERRED_TO: 'research/getArticleSeeMoreCasesReferredto',
    GET_ARTICLE_SEE_MORE_RULE_INVOLVED: 'research/getArticleSeeMoreRuleInvolved',
    GET_ARTICLE_SEE_MORE_NOTIFICATION_REFFERED: '/research/getArticleSeeMoreCircularorNotificationreferredto',

    GET_ILT_CASE_LAWS_SEE_MORE_SIMILAR_CASES_SUBJECT_LIST: 'research/getCaseLawsSeeMoreSimilarCasesSubjectList',
    GET_ILT_CASE_LAWS_SEE_MORE_SIMILAR_CASES: 'research/getCaseLawsSeeMoreSimilarCases',
    GET_ILT_CASE_LAWS_SEE_MORE_RELEVENT_DTAA: 'research/getCaseLawsSeeMoreRelevantArticleofDTAA',

    GET_ILT_TREATY_SEE_MORE_CASES_ON_THIS_ARTICLE: 'research/getCasesOnThisArticle',

    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_MODEL_TREATY: 'research/getArticleOfModelTreaty',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_OECD_MODEL_TREATY: 'research/getArticleOfOECDModelTreaty',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_UN_MODEL_TREATY: 'research/getArticleOfUNModelTreaty',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_US_MODEL_TREATY: 'research/getArticleOfUSModelTreaty',

    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_MODEL_COMMENTARY: 'research/getArticleOfModelCommentary',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_OECD_MODEL_COMMENTARY: 'research/getArticleOfOECDModelCommentaryonthisArticle',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_UN_MODEL_COMMENTARY: 'research/getArticleOfUNModelCommentaryonthisArticle',
    GET_ILT_TREATY_SEE_MORE_ARTICLE_OF_LEGAL_COMMENTARY: 'research/getArticleOfLegalCommentaryonthisArticle',
    GET_ILT_TREATY_SEE_MORE_OPINIONS_ON_THIS_ARTICLE: 'research/getArticleOpinionsonthisArticle',


    /**tools */

    GET_POS_NEXT_QUESTION: 'placeOfSupply_data',
    GET_POS_OUTPUT: 'placeOfSupply',
    CALCULATE_IND_AS_APPLICABILITY: 'ind_as_applicability',
    GET_EWAY_STATE_LIST: 'states',
    GET_EWAY_NEXT_QUESTION: 'eWay_bill_list',
    GET_EWAY_RESULT: 'eWay_bill',
    CALCULATE_LEASE: 'lease',


    /** beps */

    BEPS_EQUITY_SHARE_YEAR_BEGINNING: 'equitySharesYearBeginning',
    BEPS_EQUITY_SHARE_ACQUISITION: 'equitySharesAcquisition',
    BEPS_BONUS_ISSUE: 'bonus_issue',
    BEPS_RIGHT_ISSUE: 'right_issue',
    BEPS_OTHER_ISSUE: 'other_issue',
    BEPS_SHARE_WARRANT: 'shareWarrant',
    BEPS_SHARE_OPTIONS: 'shareOption',
    BEPS_OTHER_SHARE: 'other_share',
    BEPS_BASIC_EPS: 'basic_EPS',
    BEPS_DILUTED_EPS: 'diluted_EPS',

    /** top stories */

    GET_RESEARCH_NEWS_UPDATES: 'research/getResearchNewsUpdates',
    GET_RESEARCH_EXPERT_OPINIONS: 'research/getResearchExpertOpinions',
    GET_RESEARCH_MOST_VIEWS_NEWS: 'research/getResearchMostViews',
    GET_RESEARCH_EPFS_NEWS: 'research/getEPFSUpdates',
    GET_RESEARCH_BREAKING_NEWS: 'breakingNews/getLatestBreakingNews',

    /** archives */
    GET_RESEARCH_ARCHIVES: 'research/getResearchArchive',
    GET_RESEARCH_ARCHIVES_MORE_FILTER: 'research/getResearchArchiveMoreFilter',

    /**search api */
    GET_SEARCH_RESULT: 'research/getSearchResult',

    /** check viability api */
    GET_VIABLITY_GRAPH: 'research/getViability',
    GET_VIABLITY_DATA_LIST: 'research/getViabilityList',
    SEARCH_ANOTHER_CITATION: 'research/getSearchAnotherCitation',
    GET_JOURNAL: 'research/getJournals',

    /** experts api */
    GET_QUERY_HOME_BANNERS: 'query/getQueryHomeBanners/',
    GET_QUERY_CATEGORIES: 'query/getQueryCategories',
    GET_QUERY_LISTING: 'query/getQueryListing',
    GET_QUERY_RESPONSE: 'query/getQueryResponse',
    UPDATE_RATING: 'query/UpdateRating',
    UPDATE_COMMENTS: 'query/UpdateComments',
    GET_QUERY_DROPDOWNS: 'query/getQueryPostPageData',
    POST_QUERY: 'query/postQuerydata',

    /** treaty comparator */
    GET_TREATY_SUBJECT_COUNTRY_LIST: 'research/getIltComparisionMasterList',
    GET_ILT_COMPARISON_FILE_LIST: 'research/getIltComparisionFileList',

    /** gst rate finder */
    GET_GST_RATE_CORRECT_WORD: 'gstrateservice/getCorrectWord',
    GET_GST_RATE_SEARCH_RESULT: 'gstrateservice/getSearchResult',
    GET_GST_FAQS: 'tools/getgstratefinderfaqs',


    /**case finder */
    GET_CASE_FINDER: 'research/getCasefinder',
    GET_CASE_FINDER_MORE_FILTER: 'research/getCasefinderMore',

    /** act rule comparator */

    GET_ACT_RULE_COMPARE_FILE: 'research/getActRuleCompareFileText',

    /**aaa model reports */
    GET_MODEL_REPORT_FILTERS: 'research/getModelReport',
    GET_MODEL_REPORT_TEXT_DATA: 'research/getModelReportFileText',

    /**suggest  */

    GET_FILTER_SUGGESTIONS: 'research/getsuggestion',

    /** resesrch subscription api */

    CHECK_FOR_SPECIFIC_SUBSCRIPTION: 'research/IsSubscribedUser',

    /** global search research */
    GLOBAL_RESEARCH_SUGGESTIONS: 'research/GetResearchSuggestion',

    /** advertisement api */
    GET_ADVERTISEMENTS: 'banners/getAdvertisements',
    // dropbox sync urls
    DROPBOX_AUTH_URL: 'dropboxAuthUrl',
    SYNC_RESEARCHbOX: 'syncResearchBox',
    // old site move api's
    USER_BASIC_DETAILS: 'userAccount/getPersonalInfo',
    FEEDBACK_OLD_SITE: 'myAccount/fillfeedbackforOLDsite',

    GET_BUDGET_FILE_CONTENT: 'budget/getFileText',
    DROPBOX: 'dropBox',
    READY_RECONER: 'readyreconer/getreadyreconerdata/',
    HTML_TO_RTF: 'taxpractice/getfilehtmlrtf',
    HTML_TO_PDF: 'research/getFilehtmlTopdf',
    HTML_TO_DOCX: 'Research/getfilehtmldocx',
    BUDGET_MARATHON_TV: 'budget/GetMarathonStramDetails',

    // Research Renewal Notification Alert
    RENEWAL_ALERT: '/notify/showRenewAlert',
 // all SEBI
 GET_ALL_ABOUT_SEBI: 'research/getAllaboutSebi',
    GET_ALL_ABOUT_DATA_SEBI: 'research/getAllAboutDataSebi',
    GET_ALL_ABOUT_NEXT_LEVEL_DATA_SEBI: 'research/getAllAboutNextLevelDataSebi',

    // CTC Aticle
    GET_CTC_ARTICLE: 'research/getArticlesCTC',
}

export const MissingDocumentPublishUrl = {
    REPORT_MISSING_CASE : 'reportmissing/case'
}
